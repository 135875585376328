import antdEn from 'antd/lib/locale-provider/en_US';
import enMessages from '../locales/au.json';

const AuLang = {
    messages: {
        ...enMessages,
    },
    antd: antdEn,
    locale: 'au',
};
export default AuLang;
