import actions from './actions';
import { getToken } from '../../library/helpers/utility';

const initState = {
    idToken: null,
    redirect_to_ref: false,
    isLoading: false,
};

export default function authReducer(state = initState, action) {
    switch (action.type) {

    case actions.CHECK_AUTHORIZATION:
        return {
            ...state,
            idToken: getToken(),
        };
    case actions.LOGIN_SUCCESS:
        return {
            ...state,
            idToken: action.token,
        };
    case actions.SET_REDIRECT_TO_REFERRER:
        return {
            ...state,
            redirect_to_ref: action.payload,
        };
    case actions.LOGOUT:
        return initState;
    case actions.IS_LOADING_LOGIN:
        return {
            ...state,
            isLoading: action.payload
        };
    case actions.SET_REGISTRATION_PLACE:
        return {
            ...state,
            registrationPlace: action.payload
        };
    default:
        return state;
    }
}
