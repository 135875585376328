import HttpClient from './http.api';

export class CleanerTaskService {
    static fetchCleanerTasks(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/getForCleaner', data });
    }

    static addCleanerTask(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/addCleaner/', data });
    }

    static editCleanerTask(data, id) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/updateCleaner/' + id, data });
    }

    static deleteCleanerTask(data) {
        return HttpClient.delete({ url: 'api/v1/web/taskOrNote/delete/', data });
    }

    static changeCleanerTaskStatus(data) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/changeTaskStatus', data });
    }
}
