import HttpClient from './http.api';

export class CleanersApiService {

    static fetchCleaner(cleanerId) {
        return HttpClient.get({ url: `api/v1/web/cleaner/${cleanerId}` });
    }

    static fetchAllCleaners(data) {
        return HttpClient.post({ url: 'api/v1/web/cleaner/list', data });
    }

    static fetchAllCleanersInJobs(data) {
        return HttpClient.post({ url: 'api/v1/web/cleaner/listInJob', data });
    }

    static addCleaner(data) {
        return HttpClient.post({ url: 'api/v1/web/cleaner/add', data });
    }

    static updateCleaner(cleanerId, data) {
        return HttpClient.put({ url: `api/v1/web/cleaner/update/${cleanerId}`, data });
    }

    static deleteCleaners(data) {
        return HttpClient.post({ url: 'api/v1/web/cleaner/delete', data });
    }

}
