import reqwest from 'reqwest';
import { appConfig } from '@iso/config/app.config';
import { message } from 'antd';
import { store } from '../redux/store';
import authActions from '../redux/auth/actions';

const base = (method, { url, data = null, token = true, type = 'json' }) => {
	const config = {
		url: `${appConfig.apiUrl}${url}`,
		method,
		type: 'json',
		contentType: 'application/json',
		crossOrigin: true,
	};

	if (token) {
		config['headers'] = {
			authorization: localStorage.getItem('id_token'),
		};
	}

	if (data) {
		if (type === 'formData') {
			config['data'] = data;
			config['processData'] = false;
			delete config.contentType;
		} else if (type === 'string') {
			config['data'] = data;
		} else {
			config['data'] = JSON.stringify(data);
		}
	}

	return reqwest(config)
		.then(res => res)
		.catch(error => {
			if (error.status === 401) {
				store.dispatch(authActions.logout());
			} else {
				message.error(error.message || 'Oops, something went wrong');
				return error;
			}

		});
};

const HttpClient = {};
['get', 'post', 'put', 'delete'].forEach(method => {
	HttpClient[method] = base.bind(null, method);
});

export default HttpClient;
