import React, { useState } from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import adminAction from '@iso/redux/admin/actions';
import UserAction from '@iso/redux/user/actions';


export default function AppProvider({ children }) {
    const { localeUpdated } = useSelector(state => state.Admin);
    const { registrationPlace } = useSelector(state => state.User);
    const { locale } = useSelector(state => state.Locale);
    const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
    const [rerenderApp, forceUpdate] = useState(0);

    const dispatch = useDispatch();

    const setLocale = () => {
        if (registrationPlace === 'UK') return AppLocale['en'];
        if (registrationPlace === 'NO') return AppLocale['no'];
        if (registrationPlace === 'IE') return AppLocale['ie'];
        if (registrationPlace === 'AU') return AppLocale['au'];
    };

    const currentAppLocale = registrationPlace ?  setLocale() : AppLocale[locale === 'uk' ? 'en' : locale];

    React.useEffect(() => {
        if (localeUpdated) {
            dispatch(UserAction.getUser());
            dispatch(adminAction.updateLocale(false));
        } 
    }, [localeUpdated]); 

    React.useEffect(() => {
        if (locale) {
            forceUpdate(() => rerenderApp + 1);
        } 
    }, [locale]); 

    return (

        <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
            </IntlProvider>
        </ConfigProvider>
    );
}
