import { franchiseeMenu } from '@iso/containers/Sidebar/menuConfigs/franchiseeMenu';
import { signatoryMenu } from '@iso/containers/Sidebar/menuConfigs/signatoryMenu';
import { superAdminMenu } from '@iso/containers/Sidebar/menuConfigs/superAdminMenu';
import { managerMenu } from '@iso/containers/Sidebar/menuConfigs/managerMenu';


export class UserRolesConductor {

	static config = {};


	static resetConfig() {
		this.config.editProfileMethod = '';
		this.config.userDetailsMapper = '';
		this.config.sideBarMenu = [];
		this.config.isSuperAdmin = false;
		this.config.isManager = false;
		this.config.isFranchisee = false;
		this.config.isSignatory = false;
	}

	static initConfig(role) {
		this.resetConfig();
		switch (role) {
			case 'ROLE_SUPER_ADMIN':
				this.config.editProfileMethod = 'editSuperAdmin';
				this.config.userDetailsMapper = 'mapSuperAdminProfileResponse';
				this.config.sideBarMenu = superAdminMenu;
				this.config.isSuperAdmin = true;
				return;
			case 'ROLE_MANAGER':
				this.config.editProfileMethod = 'editManager';
				this.config.userDetailsMapper = 'mapManagerProfileResponse';
				this.config.sideBarMenu = managerMenu;
				this.config.isManager = true;
				return;
			case 'ROLE_SIGNATORY':
				this.config.editProfileMethod = 'editSignatory';
				this.config.userDetailsMapper = 'mapSignatoryProfileResponse';
				this.config.sideBarMenu = signatoryMenu;
				this.config.isSignatory = true;
				return;
			case 'ROLE_FRANCHISE':
				this.config.editProfileMethod = 'editFranchisee';
				this.config.userDetailsMapper = 'mapFranchiseeProfileResponse';
				this.config.sideBarMenu = franchiseeMenu;
				this.config.isFranchisee = true;
				return;
			default:
				return;

		}
	}



}
