import antdNo from 'antd/lib/locale-provider/nb_NO';
import noMessages from '../locales/no.json';

const NoLang = {
    messages: {
        ...noMessages,
    },
    antd: antdNo,
    locale: 'no',
};
export default NoLang;
