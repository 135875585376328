import { SearchApiService } from '../../api/searchApiService';

const DOCUMENT = 'SEARCH_';

const actions = {
	SEARCH_DATA: DOCUMENT + 'SEARCH_DATA',

	searchData: (data) => {
		return async dispatch => {
			// dispatch(actions.loadingTrue());
			await SearchApiService.searchData(data)
				.then(data => {
					dispatch({
						type: actions.SEARCH_DATA,
						payload: data
					});
				});
			// dispatch(actions.loadingFalse());
		};
	}
};

export default actions;
