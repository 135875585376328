import { message } from 'antd';
import { AuthApiService } from '../../api/authApiService';
import { clearStorage } from '../../library/helpers/utility';
import { createBrowserHistory } from 'history';


const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    SET_REDIRECT_TO_REFERRER: 'SET_REDIRECT_TO_REFERRER',
    IS_LOADING_LOGIN: 'IS_LOADING_LOGIN',
    SET_REGISTRATION_PLACE: 'SET_REGISTRATION_PLACE',

    checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

    setRedirectToReferrer: (val) => {
        return {
            type: actions.SET_REDIRECT_TO_REFERRER,
            payload: val
        };
    },

    login: (data = {}, cb = () => {}) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await AuthApiService.login(data)
                .then(data => {
                    if (data.msg) {
                        message.error(data.msg);
                    } else {

                        cb();

                        localStorage.setItem('id_token', data.token);
                        localStorage.setItem('user_id', data.userId || '');

                        dispatch({
                            type: actions.LOGIN_SUCCESS,
                            token: data.token,
                        });
                        dispatch(actions.isloading(false));
                    }
                });
        };
    },

    logout: () => {
        return async (dispatch) => {
            clearStorage();
            createBrowserHistory().push('/');
            dispatch({
                type: actions.LOGOUT,
                data: null
            });
        };
    },

    isloading: (data) => {
        return {
            type: actions.IS_LOADING_LOGIN,
            payload: data
        };
    },

    setRegistrationPlace: (data) => {
        return {
            type: actions.SET_REGISTRATION_PLACE,
            payload: data
        };
    }
};
export default actions;
