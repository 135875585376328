import actions from './actions';

const initState = {
    isLoading: false,
    loading: false,
    errorMessage: false,
    articles: {},
    modalActive: false,
    article: {
        key: null,
        id: new Date().getTime(),
        title: '',
        slug: '',
        excerpt: '',
        status: 'draft', // publish
        description: '',
        created_at: new Date().getTime(),
        deleted_at: null, // soft delete
    },
    selectedCleanerRow: {},
    jobsCurrentPage: 1,
    tasksCurrentPage: {
        toDo: 1,
        completed: 1
    },
    jobsFilters: {
        isActive: false,
        isInActive: false
    },
    tasksFilters: {
        isToDo: false,
        isCompleted: false
    },
    terminateAccount: {
        termination_date: '',
        reason: '',
        not_taken_up: '',
        reactivation_date: ''
    },
    allCleanerTasks: [],
    edited_cleaner_task_id: '',
    cleaners: [],
    mapCleaners: [],
    pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
        hideOnSinglePage: true,
    },
    contactValue: 'ACTIVE',
    statusValue: null,
    selectedRowKeys: [],
    loadingCleaner: false,
    searchCleanersValue: ''
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case actions.CLEANERS_SEARCH_VALUE:

            return {
                ...state,
                searchCleanersValue: payload
            };

        case actions.FETCH_ALL_CLEANERS:
            return {
                ...state,
                cleaners: payload.cleaner,
                pagination: {
                    ...state.pagination,
                    total: Number(payload.total)
                }
            };

        case actions.ON_SELECT_ID_CHANGE:
            return {
                ...state,
                selectedRowKeys: payload,
            };
        case actions.FETCH_ALL_CLEANERS_FOR_MAP:
            return {
                ...state,
                mapCleaners: payload.cleaner
            };
        case actions.EDIT_CLEANER_TASK:
            return {
                ...state,
                edited_cleaner_task_id: payload.data
            };
        case actions.FETCH_CLEANER_TASKS:
            return {
                ...state,
                allCleanerTasks: payload.response.list,
                pagination: {
                    ...state.pagination,
                    total: Number(payload.total)
                }
            };
        case actions.ADD_CLEANER_TASK:
            return {
                ...state,
                allCleanerTasks: payload.data
            };
        case actions.DELETE_CLEANER_TASK:
            const filtered_tasks = state.tasks.filter(el => el.id !== payload.data.taskId);
            return {
                ...state,
                tasks: filtered_tasks
            };
        case actions.LOADING_TRUE:
            return {
                ...state,
                loading: payload,
            };
        case actions.LOADING_FALSE:
            return {
                ...state,
                loading: payload,
            };
        case actions.HANDLE_PAGINATION_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: payload.page,
                }
            };
        case actions.HANDLE_PAGE_SIZE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 1,
                    pageSize: Number(payload)
                }
            };
        case actions.ON_CHANGE_CONTACT:
            return {
                ...state,
                contactValue: payload,
            };
        case actions.ON_CHANGE_STATUS:
            return {
                ...state,
                statusValue: payload,
            };
        case actions.SELECT_ROW:
            return {
                ...state,
                selectedCleanerRow: payload.data,
            };
        case actions.JOBS_CURRENT_PAGE:
            return {
                ...state,
                jobsCurrentPage: payload.data,
            };
        case actions.TASKS_CURRENT_PAGE:
            const field = payload.changing_field;
            return {
                ...state,
                tasksCurrentPage: {
                    ...state.tasksCurrentPage,
                    [field]: payload.data
                }
            };
        case actions.GET_CLEANER:
            payload.cleaner.status = payload.cleaner.active_status;
            return {
                ...state,
                selectedCleanerRow: {
                    ...state.selectedCleanerRow,
                    rowObj: payload.cleaner,
                },
                loadingCleaner: false
            };
        case actions.LOADING_CLEANER:
            return {
                ...state,
                loadingCleaner: true,
            };
        case actions.SET_FILTER:
            const filters = { ...state[payload.changing_field] };
            for (const filter in filters) {
                if (payload.data === '') {
                    filters[filter] = false;
                } else {
                    if (payload.data === filter) {
                        filters[filter] = !!payload.data;
                    } else {
                        filters[filter] = !payload.data;
                    }
                }
            }
            return {
                ...state,
                [payload.changing_field]: filters
            };
        case actions.TERMINATE_ACCOUNT:
            return {
                ...state,
                terminateAccount: payload.data.state
            };
        case actions.RESET_CLEANERS_LIST:
            return { ...initState };

        default:
            return state;

    }
}
