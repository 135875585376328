import actions from './actions';

const initState = {
    isNewEmail: false,
    isLoading: false,
    localeUpdated: false,
    changeCredentials: false
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
    case actions.CHECK_IS_NEW_EMAIL:
        return {
            ...state,
            isNewEmail: action.payload
        };
    case actions.IS_LOADING:
        return {
            ...state,
            isLoading: action.payload,
        };
    case actions.FORCE_UPDATE:
        return {
            ...state,
            localeUpdated: action.payload
        };
    case actions.CHANGE_CREDENTIALS:
        return {
            ...state,
            changeCredentials: action.payload
        };
    default:
        return state;
    }
}
