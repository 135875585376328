import actions from './actions';
import moment from 'moment';
import setDayNumber from '../../components/Helper/setDayNumber';

const initState = {
    jobDetails: {},
    loading: false,
    errorMessage: false,
    articles: {},
    modalActive: false,
    isVisible: false,
    // selectedRow: {},
    selectedClientRow: {},
    receivedClientById: {},
    clientAddData: {
        client_house_holder1Fn:'',
        client_house_holder1Ln:'',
        client_house_holder2Fn:'',
        client_house_holder2Ln:'',
        client_street:'',
        client_town:'',
        client_county:'',
        client_postcode:'',
        client_address2:'',
        client_email:'',
        client_mobile:'',
        client_telephone:'',
        client_work_phone:'',
        client_service_gap:'',
        client_hours_required_per_week:'',
        client_work_required:'',
        client_retainer_calculated:'',
        client_discount:'',
        client_comment:'',
    },
    clientUpdateData: {
        access_token: null,
        client: {
            PDF_data: '',
            address: {
                address2: '',
                county: '',
                postcode: '',
                street: '',
                town: ''
            },
            agreement_date: '',
            area: '',
            contact_details: {
                email: '',
                mobile: '',
                telephone: '',
                work_phone: ''
            },
            contract_date: null,
            createdate_time: '',
            discount: '',
            franchise_details: {
                retainer_calculated: '',
                // signatory: ""
            },
            house_holder1: {
                first_name: '',
                last_name: ''
            },
            house_holder2: {
                first_name: '',
                last_name: ''
            },
            // lead: true,
            marketing: '',
            ntu: true,
            preferences: {
                comment: '',
                days_preferred: [
                    {
                        day_number: '',
                        time_slot: ''
                    }
                ],
                enquiry_source: '',
                hours_required_per_week: '',
                service_gap: '',
                work_required: ''
            },
            property_details: {
                alarm: false,
                alarm_code: false,
                children: false,
                number_of_bedrooms: '',
                pet_details: '',
                pets: false,
                smoking: false
            },
            reactivate_date: null,
            strdays_preferred_day_time: '',
            terminate_date: null,
            terminate_reason: '',
            uniqueId_client: '',
            payment_type: '',
            privacy_policy: false,
        }
    },
    jobsCurrentPage: 1,
    tasksCurrentPage: {
        toDo: 1,
        completed: 1
    },
    jobsFilters: {
        isActive: false,
        isInActive: false
    },
    tasksFilters: {
        isToDo: false,
        isCompleted: false
    },
    allClientTasks: [],
    edited_client_task_id: '',
    clients: [],
    mapClients: [],
    allMapClients: [],
    selectedRowKeys: [],
    pagination: {
        current: 1,
        pageSize: 10, // default 10
        showSizeChanger: false,
        hideOnSinglePage: true,
    },
    contactValue: 'ACTIVE',
    statusValue: null,
    current_add_client_step: 0,
    resetFormFields: false,
    searchClientsValue: ''
};

export default function reducer(state = initState, { type, payload }) {
    switch (type) {

        case actions.CLIENTS_SEARCH_VALUE:

            return {
                ...state,
                searchClientsValue: payload
            };

        case actions.FETCH_ALL_CLIENTS:
            return {
                ...state,
                clients: payload.clients,
                mapClients: payload.clients,
                pagination: {
                    ...state.pagination,
                    total: Number(payload.total)
                },
                clientUpdateData: {
                    ...initState.clientUpdateData
                }
            };
        case actions.FETCH_ALL_CLIENTS_FOR_MAP:
            return {
                ...state,
                allMapClients: payload.clients
            };
        case actions.EDIT_CLIENT_TASK:
            return {
                ...state,
                edited_client_task_id: payload.data
            };
        case actions.LOADING_TRUE:
            return {
                ...state,
                loading: payload,
            };
        case actions.LOADING_FALSE:
            return {
                ...state,
                loading: payload,
            };
        case actions.TOGGLE_MODAL:
            return {
                ...state,
                isVisible: payload,
            };
        case actions.CHANGE_CURRENT_CLIENT_STEP:
            return {
                ...state,
                current_add_client_step: payload,
            };
        case actions.HANDLE_PAGINATION_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: payload.page,
                }
            };
        case actions.HANDLE_PAGE_SIZE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 1,
                    pageSize: Number(payload)
                }
            };
        case actions.ON_CHANGE_CONTACT:
            return {
                ...state,
                contactValue: payload,
            };
        case actions.ON_CHANGE_STATUS:
            return {
                ...state,
                statusValue: payload,
            };
        case actions.ON_SELECT_ID_CHANGE:
            return {
                ...state,
                selectedRowKeys: payload,
            };
        case actions.SELECT_ROW:
            return {
                ...state,
                selectedClientRow: payload.data,
            };
        case actions.JOBS_CURRENT_PAGE:
            return {
                ...state,
                jobsCurrentPage: payload.data,
            };
        case actions.EDIT_JOB_DETAILS:
            return {
                ...state,
                jobDetails: {
                    ...state.jobDetails,
                    [payload.changing_field]: payload.data
                }
            };
        case actions.TASKS_CURRENT_PAGE:
            const field = payload.changing_field;
            return {
                ...state,
                tasksCurrentPage: {
                    ...state.tasksCurrentPage,
                    [field]: payload.data
                }
            };
        case actions.SET_FILTER:
            const filters = { ...state[payload.changing_field] };
            for (const filter in filters) {
                if (payload.data === '') {
                    filters[filter] = false;
                } else {
                    if (payload.data === filter) {
                        filters[filter] = !!payload.data;
                    } else {
                        filters[filter] = !payload.data;
                    }
                }
            }
            return {
                ...state,
                [payload.changing_field]: filters
            };
        case actions.GET_CLIENT:
            return {
                ...state,
                receivedClientById: {
                    ...state.receivedClientById,
                    rowObj: {
                        ...payload.client,
                        availability_work_days: payload.client.days_period_of_work,
                        hours: payload.client.weekly_hrs
                    }
                }
            };
        case actions.ON_CHANGE_DAY_TIME:
            return {
                ...state,
                clientUpdateData: {
                    ...state.clientUpdateData,
                    client: {
                        ...state.clientUpdateData.client,
                        preferences: {
                            ...state.clientUpdateData.client.preferences,
                            days_preferred: {
                                ...state.clientUpdateData.client.preferences.days_preferred,
                            }
                        }
                    }
                }
            };
        case actions.FETCH_CLIENT_TASKS:
            return {
                ...state,
                allClientTasks: payload.response.list,
                pagination: {
                    ...state.pagination,
                    total: Number(payload.total)
                }
            };
        case actions.ADD_TASK:
            return {
                ...state,
                allClientTasks: payload.data
            };
        case actions.EDIT_TASK:
            const tasks = state.tasks.map(el => {
                if (el.id === payload.data.id) {
                    return el = payload.data.taskItem;
                } else {
                    return el;
                }
            });
            return { ...state, tasks };
        case actions.DELETE_TASK:
            const filtered_tasks = state.tasks.filter(el => el.id !== payload.data.taskId);
            return {
                ...state,
                tasks: filtered_tasks
            };
        case actions.RESET_CLIENT_UPDATE_DATA:
            return {
                ...state,
                clientUpdateData: {
                    ...initState.clientUpdateData
                }
            };
        case actions.SET_CLIENT_ON_UPDATE_DATA:
            return {
                ...state,
                clientUpdateData: {
                    ...state.clientUpdateData,
                    client: {
                        ...state.clientUpdateData.client,
                        ...payload
                    }
                },
            };
        case actions.SET_CLIENT_ON_ADD_DATA:
            return {
                ...state,
                clientAddData: {
                    ...payload
                },
            };
        case actions.SET_CLIENT_DATA:
            const prefferedDays = [];

            if (payload.client.days_period_of_work) {
                Object.keys(payload.client.days_period_of_work).forEach(dayPart => {
                    Object.keys(payload.client.days_period_of_work[dayPart]).forEach((el, i) => {
                        if (payload.client.days_period_of_work[dayPart][el] === true) {
                            prefferedDays.push({
                                day_number: setDayNumber(el),
                                time_slot: dayPart
                            });
                        }
                    });
                });
            }

            return {
                ...state,
                clientUpdateData: {
                    ...state.clientUpdateData,
                    client: {
                        ...state.clientUpdateData.client,
                        PDF_data: payload.client.client_agreement_pdf,
                        address: {
                            address2: payload.client.address2,
                            county: payload.client.address.county,
                            postcode: payload.client.address.postcode,
                            street: payload.client.address.street,
                            town: payload.client.address.town
                        },
                        agreement_date: payload.client.agreement_date,
                        area: payload.client.area_name,
                        contact_details: {
                            email: payload.client.email,
                            mobile: payload.client.mobile,
                            telephone: payload.client.telephone,
                            work_phone: payload.client.work_phone
                        },
                        contract_date: payload.client.contract_date,
                        createdate_time: moment(payload.client.created_time).format('YYYY-MM-DD HH:mm:ss'),
                        discount: payload.client.discount,
                        franchise_details: {
                            retainer_calculated: payload.client.retailer_calculated_cost,
                            // signatory: ''
                        },
                        house_holder1: {
                            first_name: payload.client.house_holder1_fn,
                            last_name: payload.client.house_holder1_ln
                        },
                        house_holder2: {
                            first_name: payload.client.house_holder2_fn,
                            last_name: payload.client.house_holder2_ln
                        },
                        //Нужен для конверта лидов
                        // lead: false,
                        marketing: payload.client.marketing,
                        ntu: payload.client.ntu,
                        preferences: {
                            comment: payload.client.comment,
                            days_preferred: prefferedDays,
                            enquiry_source: payload.client.enquiry_source,
                            hours_required_per_week: payload.client.weekly_hrs,
                            service_gap: payload.client.service_gap_type,
                            work_required: payload.client.work_required
                        },
                        property_details: {
                            alarm: payload.client.prop_alarm,
                            alarm_code: payload.client.prop_alarm_key,
                            children: payload.client.prop_children,
                            number_of_bedrooms: payload.client.prop_no_of_bedrooms,
                            pet_details: payload.client.prop_pets_details,
                            pets: payload.client.prop_pets,
                            smoking: payload.client.prop_smoking
                        },
                        reactivate_date: payload.client.reactivate_date,
                        strdays_preferred_day_time: '',
                        terminate_date: payload.client.terminate_date,
                        // terminate_date: moment(payload.client.terminate_date).format('YYYY-MM-DD HH:mm:ss'),

                        terminate_reason: payload.client.terminate_reason,
                        uniqueId_client: payload.client.client_id,
                        payment_type: payload.client.payment_type,
                        privacy_policy: payload.client.privacy_policy,
                    },
                }
            };
        case actions.SHOULD_RESET_FORM_FIELDS:
            return {
                ...state,
                resetFormFields: payload
            };
        case actions.RESET_CLIENTS_LIST:
            return { ...initState };
        case actions.SET_CLIENT_ACCESS_TOKEN:
            return {
                ...state,
                clientUpdateData: {
                    ...state.clientUpdateData,
                    access_token: payload
                }
            };
        default:
            return state;
    }
}
