import HttpClient from './http.api';
import { getUserId } from '../library/helpers/utility';

export class UserApiService {

    static userInfo(id = null) {
        return HttpClient.get({ url: `api/v1/web/admin/${id ? id : getUserId()}` });
    }

    static updateAvatar(data) {
        return HttpClient.post({ url: 'api/v1/web/image/updateProfileImage', data, type: 'formData' });
    }

    static editFranchisee(data) {
        return HttpClient.put({ url: 'api/v1/web/franchise/updateProfile', data });
    }

    static editSignatory(data) {
        return HttpClient.put({ url: 'api/v1/web/signatory/update', data });
    }

    static editManager(data) {
        return HttpClient.put({ url: 'api/v1/web/manager/updateProfile', data });
    }

    static editSuperAdmin(data) {
        return HttpClient.put({ url: 'api/v1/web/admin/updateProfile', data });
    }


    // areas

    static userAreas() {
        return HttpClient.get({ url: 'api/v1/area/listByFranchise' });
    }

    static addUserAreas(data) {
        return HttpClient.post({ url: 'api/v1/area/add', data });
    }

    static editUserAreas(data) {
        return HttpClient.put({ url: 'api/v1/area/update', data });
    }

    static deleteUserArea(areaId) {
        return HttpClient.delete({ url: `api/v1/area/${areaId}` });
    }


}
