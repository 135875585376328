import { message } from 'antd';
import { FranchiseeApiService } from '../../api/franchiseeApiService';

const DOCUMENT = 'FRANCHISE_';

const actions = {
	LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
	LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
	TOGGLE_MODAL: DOCUMENT + 'TOGGLE_MODAL',
	FETCH_ALL_FRANCHISEES: DOCUMENT + 'FETCH_ALL_FRANCHISEES',
	DELETE_FRANCHISE: DOCUMENT + 'DELETE_FRANCHISE',
	EDIT_FRANCHISE: DOCUMENT + 'EDIT_FRANCHISE',
    SEARCH_FRANCHISEE: DOCUMENT + 'SEARCH_FRANCHISEE',
	ON_SELECT_ID_CHANGE: DOCUMENT + 'ON_SELECT_ID_CHANGE',
	HANDLE_PAGINATION_CHANGE: DOCUMENT + 'HANDLE_PAGINATION_CHANGE',
	HANDLE_PAGE_SIZE_CHANGE: DOCUMENT + 'HANDLE_PAGE_SIZE_CHANGE',

	loadingTrue:() => {
		return {
			type: actions.LOADING_TRUE,
			payload: true
		};
	},

	loadingFalse:() => {
		return {
			type: actions.LOADING_FALSE,
			payload: false
		};
	},

	toggleModal:(val) => {
		return {
			type: actions.TOGGLE_MODAL,
			payload: val
		};
	},

	handlePaginationChange: (page, pageSize) => {
		return {
			type: actions.HANDLE_PAGINATION_CHANGE,
			payload: { page, pageSize }
		};
	},

	resetPagination: () => {
		return {
			type: actions.HANDLE_PAGINATION_CHANGE,
			payload: { page: 1, pageSize: 10 }
		};
	},

	handlePageSizeChange: (value) => {
		return {
			type: actions.HANDLE_PAGE_SIZE_CHANGE,
			payload: value
		};
	},

	onSelectFranchiseesChange:(selectedRowKeys) => {
		return {
			type: actions.ON_SELECT_ID_CHANGE,
			payload: selectedRowKeys
		};
	},

	fetchAllFranchisees:(params = {}) => {
		return async (dispatch, getState) => {
			dispatch(actions.loadingTrue());

			const user = getState()?.User;

			const fetchParams = {
				searchByActiveStatus: params.searchByActiveStatus,
				limit: params.limit || 10,
				registration_place: user.registrationPlaceId,
				start: params.start || 0
			};

			await FranchiseeApiService.getFranchiseeList(fetchParams)
				.then(data => {
					dispatch({
						type: actions.FETCH_ALL_FRANCHISEES,
						payload: data
					});
				});
			dispatch(actions.loadingFalse());
		};
	},

	searchFranchisee:(params = {}) => {
		return async dispatch => {
			dispatch(actions.loadingTrue());
			await FranchiseeApiService.searchFranchisee(params)
				.then(data => {
					dispatch({
						type: actions.SEARCH_FRANCHISEE,
						payload: data
					});
				});
			dispatch(actions.loadingFalse());
		};
	},

	deleteFranchisee:(params = {}) => {
		return async dispatch => {
			dispatch(actions.loadingTrue());
			await FranchiseeApiService.deleteFranchisee(params)
				.then(() => {
					message.success('Franchisee deleted successfully!');
				});
			dispatch(actions.loadingFalse());
		};
	},

	editFranchisee:(params = {}, id) => {
		return async dispatch => {
			dispatch(actions.loadingTrue());
			await FranchiseeApiService.updateFranchisee(params, id)
				.then(() => {
					message.success('Franchisee updated successfully!');
				});
			dispatch(actions.loadingFalse());
		};
	},

	setActiveFranchisee:(id) => {
		return async dispatch => {
			dispatch(actions.loadingTrue());
			await FranchiseeApiService.setActiveFranchisee(id)
				.then(() => {
					message.success('Success!');
				});
			dispatch(actions.loadingFalse());
		};
	},
};

export default actions;
