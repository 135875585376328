import HttpClient from './http.api';

export class ManagerApiService {
    static getManagerList(data) {
        return HttpClient.post({ url: '/api/v1/web/admin/manager/manager/list', data });
    }
    static addManager(data) {
        return HttpClient.post({ url: '/api/v1/web/manager/add', data });
    }
    static updateManager(data, id) {
        return HttpClient.put({ url: 'api/v1/web/admin/manager/update?userId=' + id, data });
    }
}
