import { message } from 'antd';
import { LeadApiService } from '../../api/leadApiService';
import { LeadTaskService } from '../../api/leadTaskService';
import { ClientApiService } from '../../api/clientApiService';
import { CleanersApiService } from '../../api/cleanersApiService';

const DOCUMENT = 'LEAD_';

const actions = {
    FETCH_ALL_CLIENTS: DOCUMENT + 'FETCH_ALL_CLIENTS',
    FETCH_ALL_CLEANERS: DOCUMENT + 'FETCH_ALL_CLEANERS',
    FETCH_SINGLE_CLIENT: DOCUMENT + 'FETCH_SINGLE_CLIENT',
    FETCH_SINGLE_CLEANER: DOCUMENT + 'FETCH_SINGLE_CLEANER',
    NEW_LEAD_PARAMS: DOCUMENT + 'NEW_LEAD_PARAMS',
    RESET_NEW_LEAD_PARAMS: DOCUMENT + 'RESET_NEW_LEAD_PARAMS',
    CREATE_LEAD: DOCUMENT + 'CREATE_LEAD',
    UPDATE_LEAD: DOCUMENT + 'UPDATE_POST',
    DELETE_LEAD: DOCUMENT + 'DELETE_POST',
    FETCH_CLIENT_TASKS: DOCUMENT + 'FETCH_CLIENT_TASKS',
    FETCH_CLEANER_TASKS: DOCUMENT + 'FETCH_CLEANER_TASKS',
    ADD_CLIENT_TASK: DOCUMENT + 'ADD_CLIENT_TASK',
    ADD_CLEANER_TASK: DOCUMENT + 'ADD_CLEANER_TASK',
    EDIT_CLIENT_TASK: DOCUMENT + 'EDIT_CLIENT_TASK',
    EDIT_CLEANER_TASK: DOCUMENT + 'EDIT_CLEANER_TASK',
    DELETE_CLIENT_TASK: DOCUMENT + 'DELETE_CLIENT_TASK',
    DELETE_CLEANER_TASK: DOCUMENT + 'DELETE_CLEANER_TASK',
    CHANGE_CLIENT_TASK_STATUS: DOCUMENT + 'CHANGE_CLIENT_TASK_STATUS',
    CHANGE_CLEANER_TASK_STATUS: DOCUMENT + 'CHANGE_CLEANER_TASK_STATUS',
    LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
    LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
    HANDLE_PAGINATION_CHANGE: DOCUMENT + 'HANDLE_PAGINATION_CHANGE',
    HANDLE_PAGE_SIZE_CHANGE: DOCUMENT + 'HANDLE_PAGE_SIZE_CHANGE',
    ON_CHANGE_STATUS: DOCUMENT + 'ON_CHANGE_STATUS',
    ON_CHANGE_CONTACT: DOCUMENT + 'ON_CHANGE_CONTACT',
    ON_CHANGE_SINGLE_LEAD_STATUS: DOCUMENT + 'ON_CHANGE_SINGLE_LEAD_STATUS',
    ON_CHANGE_SINGLE_LEAD_TEMPORARY_STATUS: DOCUMENT + 'ON_CHANGE_SINGLE_LEAD_TEMPORARY_STATUS',
    ON_CHANGE_SINGLE_LEAD_PERSONAL_INFO: DOCUMENT + 'ON_CHANGE_SINGLE_LEAD_PERSONAL_INFO',
    ROW_SELECTION: DOCUMENT + 'ROW_SELECTION',
    ON_SELECT_ID_CHANGE: DOCUMENT + 'ON_SELECT_ID_CHANGE',
    ADD_LEAD_MODAL_VISIBLE_STATUS: DOCUMENT + 'ADD_LEAD_MODAL_VISIBLE_STATUS',
    STATUS_MODAL_VISIBLE_STATUS: DOCUMENT + 'STATUS_MODAL_VISIBLE_STATUS',
    CONVERT_MODAL_VISIBLE_STATUS: DOCUMENT + 'CONVERT_MODAL_VISIBLE_STATUS',
    EDIT_INFO_MODAL_VISIBLE_STATUS: DOCUMENT + 'EDIT_INFO_MODAL_VISIBLE_STATUS',
    EDIT_CONTACTS_MODAL_VISIBLE_STATUS: DOCUMENT + 'EDIT_CONTACTS_MODAL_VISIBLE_STATUS',
    EDIT_NOTE_MODAL_VISIBLE_STATUS: DOCUMENT + 'EDIT_NOTE_MODAL_VISIBLE_STATUS',
    EDIT_ADDITIONAL_MODAL_VISIBLE_STATUS: DOCUMENT + 'EDIT_ADDITIONAL_MODAL_VISIBLE_STATUS',
    CHANGE_CURRENT_LEAD_STEP: DOCUMENT + 'CHANGE_CURRENT_LEAD_STEP',
    CHANGE_CURRENT_STEP: DOCUMENT + 'CHANGE_CURRENT_STEP',
    SELECTED_LEAD_ID: DOCUMENT + 'SELECTED_LEAD_ID',
    VALID_DATA_STATUS: DOCUMENT + 'VALID_DATA_STATUS',
    LEAD_TYPE: DOCUMENT + 'LEAD_TYPE',
    NEW_LEAD_TYPE: DOCUMENT + 'NEW_LEAD_TYPE',
    TOGGLE_MODAL: DOCUMENT + 'TOGGLE_MODAL',
    ADD_TASK_MODAL_VISIBLE_STATUS: DOCUMENT + 'ADD_TASK_MODAL_VISIBLE_STATUS',
    CHANGE_CURRENT_CONVERT_STEP: DOCUMENT + 'CHANGE_CURRENT_CONVERT_STEP',
    REDIRECT_TO_CLIENT: DOCUMENT + 'REDIRECT_TO_CLIENT',
    RESET_PAGINATION_TO_INIT_STATE: DOCUMENT + 'RESET_PAGINATION_TO_INIT_STATE',
    LEADS_SEARCH_VALUE: DOCUMENT + 'SEARCH_VALUE',
    TASKS_CURRENT_PAGE: DOCUMENT + 'TASKS_CURRENT_PAGE',

    searchLeads: (data) => ({
        type: actions.LEADS_SEARCH_VALUE,
        payload: data,
    }),

    toggleModal:(val) => {
        return {
            type: actions.TOGGLE_MODAL,
            payload: val
        };
    },

    setTasksCurrentPage: (data, changing_field) => ({
        type: actions.TASKS_CURRENT_PAGE,
        payload: { data, changing_field }
    }),

    onChangeStatusFilterButton:(value) => {
        return {
            type: actions.ON_CHANGE_STATUS,
            payload: value
        };
    },

    resetPaginationToInitState: () => {
        return {
            type: actions.RESET_PAGINATION_TO_INIT_STATE,
            payload: ''
        };
    },

    onChangeContactFilterButton:(e) => {
        return {
            type: actions.ON_CHANGE_CONTACT,
            payload: e.target.value
        };
    },

    changeSingleLeadTemporaryStatusRadio:(e) => {
        return {
            type: actions.ON_CHANGE_SINGLE_LEAD_TEMPORARY_STATUS,
            payload: e.target.value
        };
    },

    changeSingleLeadStatus:(status) => {
        return {
            type: actions.ON_CHANGE_SINGLE_LEAD_STATUS,
            payload: status
        };
    },

    changeSingleLeadPersonalInfo:(params = {}) => {
        return {
            type: actions.ON_CHANGE_SINGLE_LEAD_PERSONAL_INFO,
            payload: params
        };
    },

    setLeadType:(lead_type) => {
        return {
            type: actions.LEAD_TYPE,
            payload: lead_type
        };
    },

    setAddLeadModalVisible:(visible_status) => {
        return {
            type: actions.ADD_LEAD_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setStatusModalVisible:(visible_status) => {
        return {
            type: actions.STATUS_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setEditInfoModalVisible:(visible_status) => {
        return {
            type: actions.EDIT_INFO_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setEditContactsModalVisible:(visible_status) => {
        return {
            type: actions.EDIT_CONTACTS_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setEditNoteModalVisible:(visible_status) => {
        return {
            type: actions.EDIT_NOTE_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setEditAdditionalModalVisible:(visible_status) => {
        return {
            type: actions.EDIT_ADDITIONAL_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setConvertModalVisible:(visible_status) => {
        return {
            type: actions.CONVERT_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setAddTaskModalVisible:(visible_status) => {
        return {
            type: actions.ADD_TASK_MODAL_VISIBLE_STATUS,
            payload: visible_status
        };
    },

    setLeadCurrentStep:(current_lead_step) => {
        return {
            type: actions.CHANGE_CURRENT_LEAD_STEP,
            payload: current_lead_step
        };
    },

    setCurrentStep:(current_step) => {
        return {
            type: actions.CHANGE_CURRENT_STEP,
            payload: current_step
        };
    },

    setCurrentConvertClientStep:(step) => {
        return {
            type: actions.CHANGE_CURRENT_CONVERT_STEP,
            payload: step
        };
    },

    onSelectLeadsChange:(selectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        return {
            type: actions.ON_SELECT_ID_CHANGE,
            payload: selectedRowKeys
        };
    },

    getLeadId:(id) => {
        return {
            type: actions.SELECTED_LEAD_ID,
            payload: Number(id)
        };
    },

    loadingTrue:() => {
        return {
            type: actions.LOADING_TRUE,
            payload: true
        };
    },

    loadingFalse:() => {
        return {
            type: actions.LOADING_FALSE,
            payload: false
        };
    },

    newLeadParams:(params = {}) => {
        return {
            type: actions.NEW_LEAD_PARAMS,
            payload: params
        };
    },

    newLeadType:(type) => {
        return {
            type: actions.NEW_LEAD_TYPE,
            payload: type
        };
    },

    resetNewLeadParams:() => {
        return {
            type: actions.RESET_NEW_LEAD_PARAMS,
            payload: {}
        };
    },

    stringLengthValidStatus:(string, string_length) => {
        if (string.length > string_length) {
            return {
                type: actions.VALID_DATA_STATUS,
                payload: false
            };
        } else {
            return {
                type: actions.VALID_DATA_STATUS,
                payload: true
            };
        }
    },

    stringDataValidStatus:(string) => {
        if (!string.match(/^[a-zA-Z\s-']+$/)) {
            return {
                type: actions.VALID_DATA_STATUS,
                payload: false
            };
        } else {
            return {
                type: actions.VALID_DATA_STATUS,
                payload: true
            };
        }
    },

    handlePaginationChange: (page, pageSize) => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: { page, pageSize }
        };
    },

    handlePageSizeChange: (value) => {
        return {
            type: actions.HANDLE_PAGE_SIZE_CHANGE,
            payload: value
        };
    },

    redirectToClient: (value) => {
        return {
            type: actions.REDIRECT_TO_CLIENT,
            payload: value
        };
    },

    fetchAllLeads:(params = {}, type) => {
        return async (dispatch, getState) => {

            dispatch(actions.loadingTrue());

            const leadsState = getState()?.Leads;

            params['search'] = leadsState.searchLeadsValue || null;

            await LeadApiService.fetchAllLeads(params, type)
                .then(data => {
                    if (type === 'client') {
                        dispatch({
                            type: actions.FETCH_ALL_CLIENTS,
                            payload: data
                        });
                    } else if (type === 'cleaner') {
                        dispatch({
                            type: actions.FETCH_ALL_CLEANERS,
                            payload: data
                        });
                    }

                });
            dispatch(actions.loadingFalse());
        };
    },

    fetchSingleLead:(id, type) => {
        return async dispatch => {
            await LeadApiService.fetchSingleLead(id, type)
                .then(data => {
                    if (type === 'client') {
                        dispatch({
                            type: actions.FETCH_SINGLE_CLIENT,
                            payload: data
                        });
                    } else if (type === 'cleaner') {
                        dispatch({
                            type: actions.FETCH_SINGLE_CLEANER,
                            payload: data
                        });
                    }
                });
        };
    },

    addNewSingleLead:(new_lead_params = {}, type, params = {}, history) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadApiService.addNewSingleLead(new_lead_params, type)
                .then(() => {

                    let targetStatus = 1;

                    if (new_lead_params.leadStatus === 'LOST') {
                        targetStatus = 0;
                    }

                    dispatch({
                        type: actions.ON_CHANGE_CONTACT,
                        payload: targetStatus
                    });

                    params.searchByActiveStatus = targetStatus;

                    if (type === 'client') {
                        history.push('/dashboard/clients-leads');
                        dispatch(actions.fetchAllLeads(params, type));
                    } else if (type === 'cleaner') {
                        history.push('/dashboard/cleaners-leads');
                        dispatch(actions.fetchAllLeads(params, type));
                    }
                    dispatch(actions.resetNewLeadParams());
                    dispatch(actions.setLeadCurrentStep(0));
                });
            dispatch(actions.loadingFalse());
            dispatch(actions.setAddLeadModalVisible(false));
            message.success('Lead added successfully');
        };
    },

    updateSingleLead:(params = {}, status, modal_type, id, type) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadApiService.updateSingleLead(params, id, type)
                .then(data => {
                    if (modal_type === 'status' ){
                        dispatch(actions.changeSingleLeadStatus(status));
                    } else if (modal_type === 'personal' || modal_type === 'contacts' || modal_type === 'note' || modal_type === 'additional') {
                        dispatch(actions.changeSingleLeadPersonalInfo(params));
                    }
                });
            dispatch(actions.loadingFalse());
            if (modal_type === 'status' ){
                dispatch(actions.setStatusModalVisible(false));
            } else if (modal_type === 'personal') {
                dispatch(actions.setEditInfoModalVisible(false));
            } else if (modal_type === 'contacts') {
                dispatch(actions.setEditContactsModalVisible(false));
            } else if (modal_type === 'note') {
                dispatch(actions.setEditNoteModalVisible(false));
            } else if (modal_type === 'additional') {
                dispatch(actions.setEditAdditionalModalVisible(false));
            }
            message.success('Lead updated successfully');
        };
    },

    fetchClientTasks:(params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.fetchClientTasks(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_CLIENT_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },
    addClientTask: (data) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.addClientTask(data)
                .then(data => {
                    message.success('Task created successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    editClientTask: (params = {}, id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.editClientTask(params, id)
                .then(data => {
                    message.success('Task updated successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    deleteClientTask: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.deleteClientTask(params)
                .then(data => {
                    message.success('Task deleted successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    changeClientTaskStatus: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.changeClientTaskStatus(params)
                .then(data => {
                    message.success('Status changed successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },
    fetchCleanerTasks:(params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.fetchCleanerTasks(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_CLEANER_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    addCleanerTask: (data) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.addCleanerTask(data)
                .then(data => {
                    message.success('Task created successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    editCleanerTask: (params = {}, id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.editCleanerTask(params, id)
                .then(data => {
                    message.success('Task updated successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    deleteCleanerTask: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.deleteCleanerTask(params)
                .then(data => {
                    message.success('Task deleted successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },
    convertClientById: (id, params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientApiService.updateClientById(id, params)
                .then(data => {
                    if (data.msg === 'Client wasn\'t updated by Web') {
                        message.error('Client wasn\'t updated');
                    } else {
                        window.location.replace(`/dashboard/clients/${id}/edit`);
                        dispatch(actions.setConvertModalVisible(false));
                        dispatch(actions.setCurrentConvertClientStep(0));
                        message.success('Client update successfully!');
                    }
                });
            dispatch(actions.loadingFalse());
        };
    },

    changeCleanerTaskStatus: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await LeadTaskService.changeCleanerTaskStatus(params)
                .then(data => {
                    message.success('Status changed successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },
    deleteLeadById: (ids_array, params = {}, type) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            const targetMethod = type === 'client' ? ClientApiService.deleteClientById : CleanersApiService.deleteCleaners;
            await targetMethod(ids_array)
                .then(() => {
                    dispatch(actions.fetchAllLeads(params, type));
                    message.success('Lead(s) delete successfully!');
                });
            dispatch(actions.loadingFalse());
        };
    },
};

export default actions;
