import HttpClient from './http.api';

export class FranchiseeApiService {

	static getFranchiseeList(data) {
		return HttpClient.post({ url: '/api/v1/web/admin/franchise/franchise/list', data });
	}

	static searchFranchisee(data) {
		return HttpClient.post({ url: '/api/v1/web/franchise/search', data });
	}

	static addNewFranchise(data) {
		return HttpClient.post({ url: 'api/v1/web/franchise/add', data });
	}

	static updateFranchisee(data, id) {
		return HttpClient.put({ url: 'api/v1/web/admin/franchise/update?userId=' + id, data });
	}

	static deleteFranchisee(data) {
		return HttpClient.delete({ url: '/api/v1/web/franchise/delete', data });
	}

	static setActiveFranchisee(id) {
		return HttpClient.post({ url: 'api/v1/web/admin/franchise/setActive?userId=' + id });
	}

}
