import actions from './actions';
import { handleStatisticsResponse } from './reducer';

const initState = {
    loading: true,
    statisticsItems: [
        { name: 'active.clients', count: 0, withMoreBtn: false  },
        { name: 'active.cleaners', count: 0, withMoreBtn: false, },
        { name: 'total.cleaning.hours', count: 0, withMoreBtn: false },
        { name: 'estimated.turnover', count: 0, withMoreBtn: false },

        { name: 'active.franchisees', count: 0, withMoreBtn: true, link: 'sa-franchisees?status=active' },
        { name: 'pending.frachisees', count: 0, withMoreBtn: true, link: 'sa-franchisees?status=pending' },
        { name: 'av.client.hours', count: 0, withMoreBtn: false },
        { name: 'av.client.payment', count: 0, withMoreBtn: false },
    ],

};

export default function superAdminStatisticsReducer(state = initState, action) {
    switch (action.type) {

    case actions.SET_SA_STATISTICS:

        const result = [...state.statisticsItems];

        action.payload.forEach((el, i) => {
            result[i].count = handleStatisticsResponse(el);
        });

        return {
            ...state,
            statisticsItems: result,
            loading: false
        };

    case actions.LOADING:
        const data = { ...state[action.payload] };
        data.loading = true;
        return {
            ...state,
            [action.payload]: data
        };

    default:
        return state;
    }
}


