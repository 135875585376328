import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import SignUp from '@iso/redux/signup/reducers';
import Leads from '@iso/redux/clients-leads/reducers';
import Clients from '@iso/redux/clients/reducers';
import Cleaners from '@iso/redux/cleaners/reducers';
import Locale from '@iso/redux/locale/reducers';
import Signatory from '@iso/redux/signatory/reducers';
import NewJob from '@iso/redux/newJob/reducers';
import ForgotPassReducer from '@iso/redux/forgot-password/reducer';
import ResetPassReducer from '@iso/redux/reset-password/reducer';
import User from '@iso/redux/user/reducer';
import Statistics from '@iso/redux/statistics/reducer';
import SaStatistics from '@iso/redux/statistics/sa-reducer';
import TasksToDo from '@iso/redux/tasks-to-do/reducers';
import FileAttachmentReducer from '@iso/redux/file-attachment/reducer';
import Admin from '@iso/redux/admin/reducer';
import Reports from '@iso/redux/reports/reducers';
import Terms from '@iso/redux/terms/reducer';
import Franchise from '@iso/redux/franchisee/reducers';
import Managers from '@iso/redux/managers/reducers';
import Search from '@iso/redux/search/reducers';
import ReportAnIssueReducer from '@iso/redux/report-an-issue/reducers';
import Zapier from '@iso/redux/zapier/reducers';

export default combineReducers({
	User,
	Statistics,
	SaStatistics,
	Auth,
	App,
	ThemeSwitcher,
	LanguageSwitcher,
	modal,
	drawer,
	SignUp,
	Leads,
	Clients,
	Cleaners,
	Locale,
	Signatory,
	NewJob,
	ForgotPassReducer,
	ResetPassReducer,
	Admin,
	Reports,
	TasksToDo,
	FileAttachmentReducer,
	Terms,
	Franchise,
	Managers,
	Search,
	ReportAnIssueReducer,
	Zapier
});
