import JobApiService from '../../api/jobApiService';
import ACCOUNT_TYPE from '../../components/AccountInfoView/AccountType';
import { message } from 'antd';

const actions = {
    GET_CLEANERS_LIST: 'GET_CLEANERS_LIST',
    GET_CLIENTS_LIST: 'GET_CLIENTS_LIST',
    IS_LOADING: 'IS_LOADING',
    IS_JOB_CREATED: 'IS_JOB_CREATED',
    RESET_CLEANERS: 'RESET_CLEANERS',
    RESET_CLIENTS: 'RESET_CLIENTS',
    HANDLE_CLEANERS_PAGINATION_CHANGE: 'HANDLE_CLEANERS_PAGINATION_CHANGE',
    HANDLE_CLIENTS_PAGINATION_CHANGE: 'HANDLE_CLIENTS_PAGINATION_CHANGE',
    HANDLE_PAGE_SIZE_CHANGE_CLEANERS: 'HANDLE_PAGE_SIZE_CHANGE_CLEANERS',
    HANDLE_PAGE_SIZE_CHANGE_CLIENTS: 'HANDLE_PAGE_SIZE_CHANGE_CLIENTS',
    GET_CLEANER_JOBS: 'GET_CLEANER_JOBS',
    GET_CLIENT_JOBS: 'GET_CLIENT_JOBS',
    DELETE_JOB: 'DELETE_JOB',
    SAVE_EDITED_JOB: 'SAVE_EDITED_JOB',
    UPDATE_JOBS_LIST: 'UPDATE_JOBS_LIST',
    SHOULD_UPDATE_CLIENT: 'SHOULD_UPDATE_CLIENT',
    GET_DISTANCE: 'GET_DISTANCE',
    GET_JOB: 'GET_JOB',

    addNewJob: (data) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.addNewJob(data)
                .then(() => {
                    dispatch(actions.isJobLoaded(true));
                    dispatch(actions.isloading(false));
                });
        };
    },

    selectCleanerForNewJob: (data, id) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.selectCleanerForNewJob(data, id)
                .then(data => {
                    dispatch({
                        type: actions.GET_CLEANERS_LIST,
                        payload: data
                    });
                    dispatch(actions.isloading(false));
                });
        };
    },

    selectClientsNearCleaner: (data, id) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.selectClientsNearCleaner(data, id)
                .then(data => {
                    dispatch({
                        type: actions.GET_CLIENTS_LIST,
                        payload: data
                    });
                    dispatch(actions.isloading(false));
                });
        };
    },

    resetCleaners: () => {
        return {
            type: actions.RESET_CLEANERS,
            payload: []
        };
    },

    resetClients: () => {
        return {
            type: actions.RESET_CLIENTS,
            payload: []
        };
    },

    isloading: (data) => {
        return {
            type: actions.IS_LOADING,
            payload: data
        };
    },

    isJobLoaded: (data) => {
        return {
            type: actions.IS_JOB_CREATED,
            payload: data
        };
    },

    getJobsListForClient: (data, id) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.getJobsListForClient(data, id)
                .then(data => {
                    dispatch({
                        type: actions.GET_CLIENT_JOBS,
                        payload: data
                    });
                });
        };
    },

    getJobsListForCleaner: (data, id) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.getJobsListForCleaner(data, id)
                .then(data => {
                    dispatch({
                        type: actions.GET_CLEANER_JOBS,
                        payload: data
                    });
                });
        };
    },

    getDistance: (cleanerId, clientId) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.getDistance(cleanerId, clientId)
                .then(data => {
                    dispatch({
                        type: actions.GET_DISTANCE,
                        payload: data
                    });
                });
            dispatch(actions.isloading(false));
        };
    },

    getJob: (id) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.getJob(id)
                .then(data => {
                    dispatch({
                        type: actions.GET_JOB,
                        payload: data
                    });
                });
            dispatch(actions.isloading(false));
        };
    },

    deleteJobById: (jobId, accountType) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await JobApiService.deleteJob(jobId)
                .then(() => {
                    dispatch({
                        type: actions.DELETE_JOB,
                        payload: { jobId, accountType }
                    });
                    dispatch(actions.shouldUpdateClient(true));
                    dispatch(actions.isloading(false));
                });
        };
    },

    updateJob: (data, id) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            JobApiService.updateJob(data, id)
                .then(() => {
                    dispatch(actions.updateJobsList(true));
                    dispatch(actions.shouldUpdateClient(true));
                    message.success('Job updated');
                });
        };
    },

    clearJobs: (accountType) => {
        return async dispatch => {
            if (accountType === ACCOUNT_TYPE.CLIENT) {
                dispatch({
                    type: actions.GET_CLIENT_JOBS,
                    payload: { job : [] }
                });
            } else {
                dispatch({
                    type: actions.GET_CLEANER_JOBS,
                    payload: { job : [] }
                });
            }
        };
    },

    passEditedJobToEditJobModal: (job, jobId, showModal = false) => {
        return async dispatch => {
            dispatch({
                type: actions.SAVE_EDITED_JOB,
                payload: { job, jobId, showModal }
            });
        };
    },

    updateJobsList: (data) => {
        return {
            type: actions.UPDATE_JOBS_LIST,
            payload: data
        };
    },

    shouldUpdateClient: (data) => {
        return {
            type: actions.SHOULD_UPDATE_CLIENT,
            payload: data
        };
    }
};
export default actions;
