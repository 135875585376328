import actions from './actions';

const initialState = {
	allFranchisees: [],
	loading: false,
	isVisible: false,
	pagination: {
		current: 1,
		pageSize: 10,
		total: 0,
		showSizeChanger: false,
		hideOnSinglePage: true,
	},
	selectedRowKeys: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
	case actions.LOADING_TRUE:
		return {
			...state,
			loading: action.payload,
		};
	case actions.LOADING_FALSE:
		return {
			...state,
			loading: action.payload,
		};
	case actions.TOGGLE_MODAL:
		return {
			...state,
			isVisible: action.payload,
		};
	case actions.HANDLE_PAGINATION_CHANGE:
		return {
			...state,
			pagination: {
				...state.pagination,
				current: action.payload.page,
			}
		};
	case actions.HANDLE_PAGE_SIZE_CHANGE:
		return {
			...state,
			pagination: {
				...state.pagination,
				current: 1,
				pageSize: Number(action.payload)
			}
		};
	case actions.FETCH_ALL_FRANCHISEES:
		return {
			...state,
			allFranchisees: action.payload.response.list,
			pagination: {
				...state.pagination,
				total: Number(action.payload.response.total)
			},
		};
	case actions.SEARCH_FRANCHISEE:
		return {
			...state,
			allFranchisees: action.payload.response.list,
			pagination: {
				...state.pagination,
				total: Number(action.payload.response.total)
			},
		};

	case actions.ON_SELECT_ID_CHANGE:
		return {
			...state,
			selectedRowKeys: action.payload,
		};
	default: return state;
	}
}
