import logoutIcon from '@iso/assets/icons/logout.svg';

export const superAdminMenu = [
    {
        key: 'home',
        label: 'sidebar.sa_dashboard',
        leftIcon: '',
    },
    {
        key: 'sa-managers',
        label: 'sidebar.sa_managers',
        leftIcon: '',
    },
    {
        key: 'sa-franchisees',
        label: 'sidebar.sa_franchissees',
        leftIcon: '',
        queryParams: '?status=active'
    },
    {
        key: 'sa-locale',
        label: 'sidebar.sa_locale',
        leftIcon: '',
    },
    {
        key: 'sa-conditions',
        label: 'sidebar.sa_conditions',
        leftIcon: '',
        align: 'flex-end'
    },
    {
        key: 'sa-service',
        label: 'sidebar.sa_services',
        leftIcon: '',
        align: 'flex-end'
    },
    {
        key: 'sa-email-templates',
        label: 'sidebar.sa_email',
        leftIcon: '',
        align: 'flex-end'
    },
    {
        key: 'logout',
        label: 'sidebar.logout',
        leftIcon: logoutIcon,
    },
];

