import actions from './actions';

const initState = {
    pagination: {
        current: 1,
        pageSize: 10, // default 10
        showSizeChanger: false,
        hideOnSinglePage: true,
    },
    loading: false,
    // tasksCurrentPage: {
    //     upcoming: 1,
    //     overdue: 1
    // },
    tasksCurrentPage: 1,
    tasksFilters: {
        isToDo: false,
        isCompleted: false
    },
    allTasks: [],
    // allClientTasks: [],
    edited_client_task_id: '',
    // allCleanerTasks: [],
    edited_cleaner_task_id: '',
    isVisible: false,
    singleTask: {},
};

export default function reducer(state = initState, action) {
    switch (action.type) {
    case actions.LOADING_TRUE:
        return {
            ...state,
            loading: action.payload,
        };
    case actions.LOADING_FALSE:
        return {
            ...state,
            loading: action.payload,
        };
    case actions.HANDLE_PAGINATION_CHANGE:
        return {
            ...state,
            pagination: {
                ...state.pagination,
                current: action.payload.page,
            }
        };
    case actions.HANDLE_PAGE_SIZE_CHANGE:
        return {
            ...state,
            pagination: {
                ...state.pagination,
                current: 1,
                pageSize: action.payload,
            }
        };
    // case actions.TASKS_CURRENT_PAGE:
    //     const field = action.payload.changing_field;
    //     return {
    //         ...state,
    //         tasksCurrentPage: {
    //             ...state.tasksCurrentPage,
    //             [field]: action.payload.data
    //         }
    //     };
    case actions.TASKS_CURRENT_PAGE:
        return {
            ...state,
            tasksCurrentPage: action.payload.data,
        };
    case actions.GET_SINGLE_TASK:
        return {
            ...state,
            singleTask: action.payload.response,
        };
    case actions.FETCH_LEAD_OR_GENERAL_TASKS:
        return {
            ...state,
            allTasks: action.payload.response.list,
            pagination: {
                ...state.pagination,
                total: Number(action.payload.total)
            }
        };
    case actions.FETCH_CLIENT_TASKS:
        return {
            ...state,
            allTasks: action.payload.response.list,
            pagination: {
                ...state.pagination,
                total: Number(action.payload.total)
            }
        };
    case actions.EDIT_CLIENT_TASK:
        const allTasks = state.tasks.map(el => {
            if (el.id === action.payload.data.id) {
                return el = action.payload.data.taskItem;
            } else {
                return el;
            }
        });
        return { ...state, allTasks };
    case actions.DELETE_CLIENT_TASK:
        const filtered_tasks = state.tasks.filter(el => el.id !== action.payload.data.taskId);
        return {
            ...state,
            tasks: filtered_tasks
        };
    case actions.FETCH_CLEANER_TASKS:
        return {
            ...state,
            allTasks: action.payload.response.list,
            pagination: {
                ...state.pagination,
                total: Number(action.payload.total)
            }
        };
    case actions.EDIT_CLEANER_TASK:
        const cleanerTasks = state.tasks.map(el => {
            if (el.id === action.payload.data.id) {
                return el = action.payload.data.taskItem;
            } else {
                return el;
            }
        });
        return { ...state, cleanerTasks };
    case actions.DELETE_CLEANER_TASK:
        const filtered_cleaner_tasks = state.tasks.filter(el => el.id !== action.payload.data.taskId);
        return {
            ...state,
            tasks: filtered_cleaner_tasks
        };
    case actions.TOGGLE_MODAL:
        return {
            ...state,
            isVisible: action.payload,
        };
    default: return state;
    }
}
