import actions from './actions';

const initState = {
    user_files_list: [],
    file_name: '',
    fileList: [],
    uploading: false,
};

export default function FileAttachmentReducer(state = initState, action) {
    switch (action.type) {
    case actions.FETCH_USER_FILES:
        return {
            ...state,
            user_files_list: action.payload,
        };
    case actions.CHANGE_FILE_NAME:
        return {
            ...state,
            file_name: action.payload,
        };
    case actions.SET_UPLOADING:
        return {
            ...state,
            uploading: action.payload,
        };
    case actions.ON_UPLOAD_FILE:
        return {
            ...state,
            fileList: action.payload.fileList,
            uploading: action.payload.uploading,
        };
    case actions.ON_FAIL_UPLOAD_FILE:
        return {
            ...state,
            uploading: action.payload,
        };
    case actions.ON_REMOVE_FILE:
        return {
            ...state,
            fileList: action.payload,
        };
    case actions.BEFORE_UPLOAD_FILE:
        return {
            ...state,
            fileList: [
                ...state.fileList,
                action.payload
            ],
        };
    default:
        return state;
    }
}
