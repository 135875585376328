import actions from './actions';

const initialState = {
	searchResults: [],
};

export default function reducer(
	state = initialState,
	{ type, payload }
) {
	switch (type) {
	case actions.SEARCH_DATA:
		return {
			...state,
			searchResults: payload.response,
		};
	default:
		return state;
	}
}
