import actions from './actions';

const initState = {
    signatoryList: [],
    signatory: null,
    shouldUpdateSignatoryList: false,
    isLoading: false,
    pagination: {
        current: 1,
        pageSize: 10, //default
        showSizeChanger: false,
        hideOnSinglePage: true
    },
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
    case actions.GET_SIGNATORY:
        return {
            ...state,
            signatory: payload.data
        };
    case actions.GET_SIGNATORY_LIST:
        return {
            ...state,
            signatoryList: payload.list,
            pagination: {
                ...state.pagination,
                total: payload.list.length
            },
        };
    case actions.SHOULD_UPDATE_SIGNATORY_LIST:
        return {
            ...state,
            shouldUpdateSignatoryList: payload.data
        };
    case actions.IS_LOADING:
        return {
            ...state,
            isLoading: payload
        };
    case actions.HANDLE_PAGINATION_CHANGE:
        return {
            ...state,
            pagination: {
                ...state.pagination,
                current: payload
            }
        };
    default:
        return state;
    }
}
