import actions from './actions';

const initState = {
    loading: false,
    log_file: {},
};

export default function ReportAnIssueReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOADING_TRUE:
            return {
                ...state,
                loading: action.payload,
            };
        case actions.LOADING_FALSE:
            return {
                ...state,
                loading: action.payload,
            };
        case actions.GET_LOG_FILE:
            return {
                ...state,
                log_file: action.payload.response,
            };
        default:
            return state;
    }
}
