import actions from './actions';

const initState = {
    redirect_to: false,
};

export default function ForgotPassReducer(state = initState, action) {
    switch (action.type) {
    case actions.SET_REDIRECT:
        return {
            ...state,
            redirect_to: action.payload,
        };
    default:
        return state;
    }
}
