import { userConstants } from '../redux/user/userConstants';


export class UserMapper {

    static mapSuperAdminProfileResponse({ user }) {
        if (user) {
            return {
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                emailSignature: user.emailSignature || '',
                email: user.email,
            };
        } else {
            return {};
        }
    }

    static mapManagerProfileResponse({ user }) {
        if (user) {
            return {
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                emailSignature: user.emailSignature || '',
                email: user.email,
                status: true
            };
        } else {
            return {};
        }
    }

    static mapSignatoryProfileResponse({ user, franchiseId }) {
        if (user) {
            return {
                firstName: user.firstName || '',
                franchiseId,
                lastName: user.lastName || '',
                signatoryAccessType: user.signatoryAccessType || 'BOTH',
                signatoryId: user.access_token,
                email: user.email,
                status: true
            };
        } else {
            return {};
        }
    }

    static mapFranchiseeProfileResponse({ franchise_details }) {
        if (franchise_details) {
            return {
                activeStatus: 0,
                additionalEmail: '',
                address2: franchise_details.address2 || '',
                companyName: franchise_details.company_name || '',
                companyNumber: franchise_details.company_number || '',
                county: franchise_details.address.county || '',
                email: franchise_details.email,
                emailSignature: franchise_details.email_signature || '',
                emails: [],
                legalType: userConstants.companyTypes.find(el => el.constant === franchise_details.legal_type)?.value || '1',
                mobile: franchise_details.mobile_no,
                notifications: franchise_details.notifications || false,
                postCode: franchise_details.address.postcode,
                primaryUserFirstName: franchise_details.primary_user_first_name || '',
                primaryUserLastName: franchise_details.primary_user_last_name || '',
                registration_place: franchise_details.register_area,
                secondaryOwnerName: franchise_details.secondary_user_name || '',
                street: franchise_details.address.street || '',
                town: franchise_details.address.town || '',
                vatNumber: franchise_details.vat_no
            };
        } else {
            return {};
        }

    }

    static mapProfileRequest(initialState, newState) {
        const result = { ...initialState };
        Object.keys(newState).forEach(key => {
            result[key] = newState[key];
        });
        if (result.legalType && result.legalType === '2') {
            delete result.secondaryOwnerName;
            delete result.companyNumber;
        }
        return result;
    }


}
