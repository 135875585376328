import React from 'react';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error) {
        console.log('error', error);
        // const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        // if (error && chunkFailedMessage.test(error)) {
        //     window.location.reload();
        // }
    }

    render() {
        const { error, hasError } = this.state;

        if (hasError) {
            if (error.name === 'ChunkLoadError') {
                return <p>This application has been updated, please refresh your browser to see the latest content.</p>;
            } else {
                return <p>Loading failed! Please reload.</p>;
            }
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
