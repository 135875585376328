import HttpClient from './http.api';

export class ZapierApiService {

    static getZapierToken() {
        return HttpClient.get({ url: '/api/zapier/getToken' });
    }

    static changeZapierToken(changedToken) {
        return HttpClient.put({ url: `/api/zapier/changeZapierToken?token=${changedToken}` });
    }
}
