import { ReportsApiService } from '../../api/reportsApiService';
import { saveAs } from 'file-saver';
import { message } from 'antd';

const getFileName = (data) => {
    switch (data.reportType) {
        case 'ACTIVE_CLIENTS':
            return 'Active Clients Report';
        case 'INACTIVE_CLIENTS':
            return 'Inactive Clients Report';
        case 'ACTIVE_CLIENTS_WITH_ACTIVE_JOB':
            return 'Active Clients With Active Job Report';
        case 'ACTIVE_CLIENTS_WITH_PENDING_JOB':
            return 'Active Clients With Pending Job Report';
        case 'ACTIVE_CLIENTS_WITH_NO_JOB':
            return 'Active Clients With No Active Job Report';
        case 'CLIENTS_BY_ENQUIRY_SOURCE':
            return 'Clients By Enquiry Source Report';
        case 'CLIENTS_PENDING_TERMINATION':
            return 'Clients Pending Termination Report';
        case 'FULL_CLIENTS_ALL':
            return 'Clients Full Active and Inactive Report';
        case 'FULL_CLIENTS_ACTIVE':
            return 'Clients Full Active Report';
        case 'ACTIVE_CLEANERS':
            return 'Active Cleaners Report';
        case 'INACTIVE_CLEANERS':
            return 'Inactive Cleaners Report';
        case 'ACTIVE_CLEANERS_WITH_ACTIVE_JOB':
            return 'Active Cleaners With Active Job Report';
        case 'ACTIVE_CLEANERS_WITH_PENDING_JOB':
            return 'Active Cleaners With Pending Job Report';
        case 'ACTIVE_CLEANERS_WITH_NO_JOB':
            return 'Active Cleaners With No Active Job Report';
        case 'CLEANERS_BY_POLICE_CHECK':
            return 'Cleaners By Police Check Report';
        case 'CLEANERS_BY_SELF_EMPLOYMENT':
            return 'Cleaners By Self Employment Report';
        case 'FULL_CLEANERS_ALL':
            return 'Cleaners Full Active and Inactive Report';
        case 'FULL_CLEANERS_ACTIVE':
            return 'Cleaners Full Active Report';
        case 'LEAD_ALL':
            return 'Lead Cleaners And Clients Report';
        case 'LEAD_CLIENTS':
            return 'Lead Clients Report';
        case 'NEW_LEAD_CLIENTS':
            return 'New Lead Clients Report';
        case 'FOLLOW_UP_LEAD_CLIENTS':
            return 'Follow Up Lead Clients Report';
        case 'BOOKED_LEAD_CLIENTS':
            return 'Booked Lead Clients Report';
        case 'WAITING_LEAD_CLIENTS':
            return 'Waiting Lead Clients Report';
        case 'LOST_LEAD_CLIENTS':
            return 'Lost Clients Report';
        case 'LEAD_CLEANERS':
            return 'Lead Cleaners Report';
        case 'NEW_LEAD_CLEANERS':
            return 'New Lead Cleaners Report';
        case 'FOLLOW_UP_LEAD_CLEANERS':
            return 'Follow Up Lead Cleaners Report';
        case 'TASK_TO_DO_CLIENT':
            return 'Task to do Active Clients';
        case 'TASK_TO_DO_CLEANER':
            return 'Task to do Active Cleaners';
        case 'BOOKED_LEAD_CLEANERS':
            return 'Booked Lead Cleaners Report';
        case 'WAITING_LEAD_CLEANERS':
            return 'Waiting Up Lead Cleaners Report';
        case 'LOST_LEAD_CLEANERS':
            return 'Lost Lead Cleaners Report';
        case 'TASK_TO_DO_ALL':
            return 'Task to do Active Clients and Cleaners';
        case 'TASK_TO_DO_LEAD_ALL':
            return 'Task to do Lead All Report';
    }
};

const actions = {
    DOWNLOAD_PDF_REPORT: 'DOWNLOAD_PDF_REPORT',
    DOWNLOAD_CSV_REPORT: 'DOWNLOAD_CSV_REPORT',
    GET_CHART_DATA: 'GET_CHART_DATA',

    downloadPdfReport: (data = {}) => {
        return async () => {
            try {
                const hide = message.loading('Report is loading...', 0);
                ReportsApiService.downloadReport(data, 'pdf')
                    .then(blob => {
                        setTimeout(hide, 0);
                        const fileName = getFileName(data);
                        const newBlob = new Blob([blob], { type: 'application/pdf' });
                        const pdf = new File([newBlob], fileName + '.pdf', { type: 'application/pdf' });
                        saveAs(pdf);
                    });
            } catch (e) {
                message.error('Unknown error.');
            }
        };
    },

    downloadCsvReport: (data = {}) => {
        return async () => {
            try {
                const hide = message.loading('Report is loading...', 0);
                ReportsApiService.downloadReport(data, 'csv')
                    .then(blob => {
                        setTimeout(hide, 0);
                        const fileName = getFileName(data);
                        const newBlob = new Blob([blob], { type: 'text/csv' });
                        const csv = new File([newBlob], fileName + '.csv', { type: 'text/csv' });
                        saveAs(csv);
                    });
            } catch (e) {
                message.error('Unknown error.');
            }
        };
    },

    downloadCVS: (data = {}) => {
        return async () => {
            try {
                const hide = message.loading('Report is loading...', 0);
                ReportsApiService.downloadCvs(data)
                    .then(blob => {
                        setTimeout(hide, 0);
                        const fileName = String(data.type + '' + (data.onlyActive ? 'Active' : 'Active and inactive'));
                        const newBlob = new Blob([blob], { type: 'text/csv' });
                        const csv = new File([newBlob], fileName + '.csv', { type: 'text/csv' });
                        saveAs(csv);
                    });
            } catch (e) {
                message.error('Unknown error.');
            }
        };
    },


    getChartData: (type, data) => {
        return async dispatch => {
            try {
                const hide = message.loading('Data is loading...', 0);
                ReportsApiService.getChartData(type, data)
                    .then(data => {
                        setTimeout(hide, 0);
                        dispatch({
                            type: actions.GET_CHART_DATA,
                            payload: data.response
                        });
                        if (data.status === 'FAILURE') {
                            message.error('Could not get statistics');
                        }
                    });
            } catch (e) {
                message.error('Unknown error.');
            }
        };
    },
};
export default actions;
