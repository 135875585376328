import { message } from 'antd';
import { TasksToDoApiService } from '../../api/tasksToDoApiService';

const DOCUMENT = 'TASKS_TO_DO';

const taskToDoConfig = ({ getState, limit, searchLeadOrGeneral = null, start, taskSearchType }) => {

    const user = getState()?.User;

    return {
        franchiseId: user.franchiseId,
        limit: limit || 999999,
        searchLeadOrGeneral,
        start: start || 0,
        taskSearchType: taskSearchType || 0,
    };
};

const actions = {
    FETCH_LEAD_OR_GENERAL_TASKS: DOCUMENT + 'FETCH_LEAD_OR_GENERAL_TASKS',
    FETCH_CLIENT_TASKS: DOCUMENT + 'FETCH_CLIENT_TASKS',
    FETCH_CLEANER_TASKS: DOCUMENT + 'FETCH_CLEANER_TASKS',
    EDIT_CLIENT_TASK: DOCUMENT + 'EDIT_CLIENT_TASK',
    EDIT_CLEANER_TASK: DOCUMENT + 'EDIT_CLEANER_TASK',
    DELETE_TASK: DOCUMENT + 'DELETE_TASK',
    CHANGE_TASK_STATUS: DOCUMENT + 'CHANGE_TASK_STATUS',
    LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
    LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
    HANDLE_PAGINATION_CHANGE: DOCUMENT + 'HANDLE_PAGINATION_CHANGE',
    HANDLE_PAGE_SIZE_CHANGE: DOCUMENT + 'HANDLE_PAGE_SIZE_CHANGE',
    TOGGLE_MODAL: DOCUMENT + 'TOGGLE_MODAL',
    TASKS_CURRENT_PAGE: DOCUMENT + 'TASKS_CURRENT_PAGE',
    GET_SINGLE_TASK: DOCUMENT + 'GET_SINGLE_TASK',

    toggleModal:(val) => {
        return {
            type: actions.TOGGLE_MODAL,
            payload: val
        };
    },

    // setTasksCurrentPage: (data, changing_field) => ({
    //     type: actions.TASKS_CURRENT_PAGE,
    //     payload: { data, changing_field }
    // }),

    setTasksCurrentPage: (data) => ({
        type: actions.TASKS_CURRENT_PAGE,
        payload: { data }
    }),

    loadingTrue:() => {
        return {
            type: actions.LOADING_TRUE,
            payload: true
        };
    },

    loadingFalse:() => {
        return {
            type: actions.LOADING_FALSE,
            payload: false
        };
    },

    handlePaginationChange: (page, pageSize) => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: { page, pageSize }
        };
    },

    handlePageSizeChange: (value) => {
        return {
            type: actions.HANDLE_PAGE_SIZE_CHANGE,
            payload: value
        };
    },

    getSingleTask: (id) => {
        return async dispatch => {
          dispatch(actions.loadingTrue());
          await TasksToDoApiService.getSingleTask(id)
              .then(data => {
                  dispatch({
                      type: actions.GET_SINGLE_TASK,
                      payload: data
                  });
              });
          dispatch(actions.loadingFalse());
        };
    },

    fetchLeadOrGeneralTasks:(params = {}) => {
        return async (dispatch, getState) => {
            dispatch(actions.loadingTrue());

            const fetchParams = taskToDoConfig({ ...params, getState, });

            await TasksToDoApiService.fetchLeadOrGeneralTasks(fetchParams)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_LEAD_OR_GENERAL_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    fetchClientTasks:(params = {}) => {
        return async (dispatch, getState) => {

            dispatch(actions.loadingTrue());

            const fetchParams = taskToDoConfig({ ...params, getState, });

            await TasksToDoApiService.fetchClientTasks(fetchParams)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_CLIENT_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    editClientTask: (params = {}, id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await TasksToDoApiService.editClientTask(params, id)
                .then(data => {
                    message.success('Task updated successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    fetchCleanerTasks:(params = {}) => {
        return async (dispatch, getState) => {
            dispatch(actions.loadingTrue());

            const fetchParams = taskToDoConfig({ ...params, getState, });

            await TasksToDoApiService.fetchCleanerTasks(fetchParams)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_CLEANER_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    editCleanerTask: (params = {}, id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await TasksToDoApiService.editCleanerTask(params, id)
                .then(data => {
                    message.success('Task updated successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    changeTaskStatus: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await TasksToDoApiService.changeTaskStatus(params)
                .then(data => {
                    message.success('Status changed successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    deleteTask: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await TasksToDoApiService.deleteTask(params)
                .then(data => {
                    message.success('Task deleted successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },
};

export default actions;
