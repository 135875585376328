import antdEn from 'antd/lib/locale-provider/en_GB';
import enMessages from '../locales/en.json';

const EnLang = {
    messages: {
        ...enMessages,
    },
    antd: antdEn,
    locale: 'en',
};
export default EnLang;
