import HttpClient from './http.api';

export class TasksToDoApiService {

    static fetchLeadOrGeneralTasks(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/getByFranchise', data });
    }

    static fetchClientTasks(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/getByFranchiseOnlyClients', data });
    }

    static getSingleTask(id) {
        return HttpClient.get({ url: 'api/v1/web/taskOrNote/' + id });
    }

    static editClientTask(data, id) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/updateClient/' + id, data });
    }

    static changeTaskStatus(data) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/changeTaskStatus', data });
    }

    static fetchCleanerTasks(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/getByFranchiseOnlyCleaners', data });
    }

    static editCleanerTask(data, id) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/updateCleaner/' + id, data });
    }

    static deleteTask(data) {
        return HttpClient.delete({ url: 'api/v1/web/taskOrNote/delete/', data });
    }
}
