
export function clearToken() {
    localStorage.removeItem('id_token');
}

export function clearStorage() {
    localStorage.clear();
}

export function getUserId() {
    return localStorage.getItem('user_id');
}

export function getToken() {
    try {
        return localStorage.getItem('id_token');
    } catch (err) {
        clearToken();
        return null;
    }
}

// TODO: put all regexps here
export const Regexps = {
    street: /^[a-zA-Z0-9\s&,.()'-]{1,100}$/,
    dateReg: /([12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01]))\s\d{2}:\d{2}:\d{2}/g
};

