import Enlang from './entries/en_US';
import NoLang from './entries/no_NO';
import AuLang from './entries/au_AU';
import IeLang from './entries/au_AU';


const AppLocale = {
    en: Enlang,
    no: NoLang,
    au: AuLang,
    ie: IeLang
};

export default AppLocale;
