import HttpClient from './http.api';

export class AdminApiService {

    static checkIsNewEmail(data) {
        return HttpClient.post({ url: '/api/v1/web/admin/admin/checkIsNewEmail', data });
    }

    static updateLoginRegistrationPlace(registrationPlace) {
        return HttpClient.put({ url: `/api/v1/web/admin/updateLoginRegistrationPlace?locale=${registrationPlace}` });
    }

    static relogin(email) {
        return HttpClient.post({ url: '/api/v1/web/admin/user/login', data : email });
    }

    static changeCredentials(data) {
        return HttpClient.post({ url: '/api/v1/web/admin/user/change', data });
    }
}
