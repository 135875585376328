import HttpClient from './http.api';


export class StatisticsApiService {

	static _STATISTICS_URIS = [
		'ACTIVE_CLIENTS',
		'ACTIVE_CLEANERS',
		'CLEANING_HOURS_WEEKLY',
		'AVERAGE_CLIENT_HOURS',
		'AVERAGE_CLIENT_RETAINER',
		'WEEKLY_VS_FORTNIGHTLY',
		'ESTIMATED_TURNOVER_ANNUM',
		'PENDING_TASKS',
		'LEAD_CLIENTS',
		'LEAD_CLEANERS'
	];

	static _SA_STATISTICS_URIS = [
		'ACTIVE_CLIENTS',
		'ACTIVE_CLEANERS',
		'CLEANING_HOURS_WEEKLY',
		'ESTIMATED_TURNOVER_ANNUM',
		'ACTIVE_FRANCHISEES',
		'PENDING_FRANCHISEES',
		'AVERAGE_CLIENT_HOURS',
		'AVERAGE_CLIENT_RETAINER',
	];

	static taskData() {
		return HttpClient.get({ url: '/api/v1/web/statistic/getTaskData' });
	}

	static allStatistics(type, data) {
		return HttpClient.post({ url: `api/v1/web/statistic/getData?type=${type}`, data })
	}

	static allStatisticsTasks() {
		return HttpClient.get({ url: 'api/v1/web/statistic/getTaskData' })
	}

	static getActivity(bool) {
		return HttpClient.get({ url: 'api/v1/web/statistic/getTriggersData?task=' + bool })
	}

	static getReport(data) {
		return HttpClient.post({ url: 'api/v1/web/reports/getList', data})
	}

	static saStatistics(registrationPlace) {
		const requests = this._SA_STATISTICS_URIS.map(uri => (
			HttpClient.get({ url: `api/v1/web/statistic/getData?registrationPlace=${registrationPlace}&type=${uri}` })
		));
		return Promise.all(requests)
	}


}
