import { ZapierApiService } from '../../api/zapierApiService';

const actions = {
    GET_ZAPIER_TOKEN: 'GET_ZAPIER_TOKEN',
    CHANGE_ZAPIER_TOKEN: 'CHANGE_ZAPIER_TOKEN',

    getZapierToken: () => {
        return async dispatch => {
            await ZapierApiService.getZapierToken()
                .then(data => {
                    dispatch({
                        type: actions.GET_ZAPIER_TOKEN,
                        payload: data.response

                    });
                });
        };
    },

    changeZapierToken: (token = '') => {
        return async dispatch => {
            await ZapierApiService.changeZapierToken(token)
                .then(() => {
                    dispatch({
                        type: actions.CHANGE_ZAPIER_TOKEN,
                        payload:token
                    });
                });
        };
    }
};
export default actions;
