import HttpClient from './http.api';

export class SignatoryApiService {

    static getSignatoryList(data) {
        return HttpClient.post({ url: '/api/v1/web/signatory/list', data });
    }

    static getSignatoryById(data) {
        return HttpClient.post({ url: '/api/v1/web/signatory/get', data });
    }

    static addSignatory(data) {
        return HttpClient.post({ url: '/api/v1/web/signatory/add', data });
    }

    static updateSignatory(data) {
        return HttpClient.put({ url: '/api/v1/web/signatory/update', data });
    }

    static deleteSignatory(id) {
        return HttpClient.delete({ url: `/api/v1/web/signatory/delete/${id}` });
    }


}
