import HttpClient from './http.api';

export class LeadTaskService {

    static fetchClientTasks(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/getForClient', data });
    }

    static addClientTask(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/addClient/', data });
    }

    static editClientTask(data, id) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/updateClient/' + id, data });
    }

    static deleteClientTask(data) {
        return HttpClient.delete({ url: 'api/v1/web/taskOrNote/delete/', data });
    }

    static changeClientTaskStatus(data) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/changeTaskStatus', data });
    }

    static fetchCleanerTasks(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/getForCleaner', data });
    }

    static addCleanerTask(data) {
        return HttpClient.post({ url: 'api/v1/web/taskOrNote/addCleaner/', data });
    }

    static editCleanerTask(data, id) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/updateCleaner/' + id, data });
    }

    static deleteCleanerTask(data) {
        return HttpClient.delete({ url: 'api/v1/web/taskOrNote/delete/', data });
    }

    static changeCleanerTaskStatus(data) {
        return HttpClient.put({ url: 'api/v1/web/taskOrNote/changeTaskStatus', data });
    }
}
