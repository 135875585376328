import React, { lazy, Suspense } from 'react';
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import('@iso/containers/Pages/Locale/Locale')),
    },
    {
        path: PUBLIC_ROUTE.PAGE_404,
        component: lazy(() => import('@iso/containers/Pages/404/404')),
    },
    {
        path: PUBLIC_ROUTE.PAGE_500,
        component: lazy(() => import('@iso/containers/Pages/500/500')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP,
        component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP_COMPLETE,
        component: lazy(() => import('@iso/containers/Pages/SignUpComplete/SignUpComplete')),
    },
    {
        path: PUBLIC_ROUTE.FORGET_PASSWORD,
        component: lazy(() =>
            import('@iso/containers/Pages/ForgotPassword/ForgotPassword')
        ),
    },
    {
        path: PUBLIC_ROUTE.RESET_PASSWORD,
        component: lazy(() =>
            import('@iso/containers/Pages/ResetPassword/ResetPassword')
        ),
    }
];
function PrivateRoute({ children, ...rest }) {
    const { idToken, isLoading } = useSelector((state) => state.Auth);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoading ? <Loader/> :
                idToken ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </Route>
                        ))}
                        <PrivateRoute path="/dashboard">
                            <Dashboard />
                        </PrivateRoute>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
