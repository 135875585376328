import logoutIcon from '@iso/assets/icons/logout.svg';
import signatureIcon from '@iso/assets/icons/signature.svg';
import reportsIcon from '@iso/assets/icons/report.svg';
import jobIcon from '@iso/assets/icons/new-job.svg';
import toDoIcon from '@iso/assets/icons/task.svg';
import cleanersIcon from '@iso/assets/icons/cleaning.svg';
import clientsIcon from '@iso/assets/icons/customer.svg';
import leadsIcon from '@iso/assets/icons/leadership.svg';
import homeIcon from '@iso/assets/icons/home.svg';
import issueIcon from '@iso/assets/icons/issue.svg';

export const franchiseeMenu = [
    {
        key: 'home',
        label: 'sidebar.dashboard',
        leftIcon: homeIcon
    },
    {
        key: 'leads',
        label: 'sidebar.leads',
        leftIcon: leadsIcon,
    },
    {
        key: 'clients',
        label: 'sidebar.clients',
        leftIcon: clientsIcon,
    },
    {
        key: 'cleaners',
        label: 'sidebar.cleaners',
        leftIcon: cleanersIcon,
    },
    {
        key: 'tasks-to-do',
        label: 'sidebar.tasks_to_do',
        leftIcon: toDoIcon,
    },
    {
        key: 'new-job',
        label: 'sidebar.new_job',
        leftIcon: jobIcon,
    },
    {
        key: 'reports',
        label: 'sidebar.reports',
        leftIcon: reportsIcon,
    },
    {
        key: 'signatories',
        label: 'sidebar.signatories',
        leftIcon: signatureIcon,
    },
    {
        key: 'report-issue',
        label: 'sidebar.report-issue',
        leftIcon: issueIcon,
    },
    {
        key: 'logout',
        label: 'sidebar.logout',
        leftIcon: logoutIcon,
    },
];

