import { message } from 'antd';
import { StatisticsApiService } from '../../api/statisticsApiService';
import { ClientApiService } from '../../api/clientApiService';
import { getUserId } from '../../library/helpers/utility';
import { CleanersApiService } from '../../api/cleanersApiService';
import { LeadApiService } from '../../api/leadApiService';

export const searchData = ({ start, limit = 6, searchByActiveStatus = null, searchByStatus, getState = () => {} }) => {
    const user = getState()?.User;

    return {
        access_token: getUserId(),
        limit: start * limit,
        search: null,
        registrationPlace: user.registrationPlace,
        searchByAccountStatus: null,
        searchByActiveStatus,
        searchOneOffJob: null,
        searchByStatus: searchByStatus || null,
        start: limit * (start - 1)
    };
};

const actions = {
    SET_STATISTICS: 'SET_STATISTICS',
    SET_SA_STATISTICS: 'SET_SA_STATISTICS',
    SET_MAP_MARKERS: 'SET_MAP_MARKERS',
    SET_LEAD_CLEANERS: 'SET_LEAD_CLEANERS',
    SET_LEAD_CLIENTS: 'SET_LEAD_CLIENTS',
    SET_CLIENTS: 'SET_CLIENTS',
    SET_CLEANERS: 'SET_CLEANERS',
    SET_ALL_ACTIVITIES: 'SET_ALL_ACTIVITIES',
    SET_TASK_ACTIVITIES: 'SET_TASK_ACTIVITIES',
    SET_BOOKED_CLIENTS_LEADS: 'SET_BOOKED_CLIENTS_LEADS',
    SET_FOLLOW_CLIENTS_LEADS: 'SET_FOLLOW_CLIENTS_LEADS',
    SET_NEW_CLIENTS_LEADS: 'SET_NEW_CLIENTS_LEADS',
    SET_WAITING_CLIENTS_LEADS: 'SET_WAITING_CLIENTS_LEADS',
    SET_BOOKED_CLEANERS_LEADS: 'SET_BOOKED_CLEANERS_LEADS',
    SET_FOLLOW_CLEANERS_LEADS: 'SET_FOLLOW_CLEANERS_LEADS',
    SET_NEW_CLEANERS_LEADS: 'SET_NEW_CLEANERS_LEADS',
    SET_WAITING_CLEANERS_LEADS: 'SET_WAITING_CLEANERS_LEADS',
    SET_LOST_LEADS: 'SET_LOST_LEADS',
    SET_ALL_LEADS: 'SET_ALL_LEADS',
    SET_CLEANERS_LEADS: 'SET_CLEANERS_LEADS',
    SET_CLIENTS_LEADS: 'SET_CLIENTS_LEADS',
    SET_ALL_CLIENTS: 'SET_ALL_CLIENTS',
    SET_STARTED_CLIENTS: 'SET_STARTED_CLIENTS',
    SET_PENDING_CLIENTS: 'SET_PENDING_CLIENTS',
    SET_NO_CLEANER_CLIENTS: 'SET_NO_CLEANER_CLIENTS',
    SET_GOOGLE_SOURCE_CLIENTS: 'SET_GOOGLE_SOURCE_CLIENTS',
    SET_LEAFLETS_SOURCE_CLIENTS: 'SET_LEAFLETS_SOURCE_CLIENTS',
    SET_MAGAZINES_SOURCE_CLIENTS: 'SET_MAGAZINES_SOURCE_CLIENTS',
    SET_FACEBOOK_SOURCE_CLIENTS: 'SET_FACEBOOK_SOURCE_CLIENTS',
    SET_INSTAGRAM_SOURCE_CLIENTS: 'SET_INSTAGRAM_SOURCE_CLIENTS',
    SET_LINKEDIN_SOURCE_CLIENTS: 'SET_LINKEDIN_SOURCE_CLIENTS',
    SET_REFERRAL_SOURCE_CLIENTS: 'SET_REFERRAL_SOURCE_CLIENTS',
    SET_DIRECTORY_SOURCE_CLIENTS: 'SET_DIRECTORY_SOURCE_CLIENTS',
    SET_OTHER_SOURCE_CLIENTS: 'SET_OTHER_SOURCE_CLIENTS',
    SET_CLEANING_HOURS_WEEKLY: 'SET_CLEANING_HOURS_WEEKLY',
    SET_AVERAGE_CLIENT_HOURS: 'SET_AVERAGE_CLIENT_HOURS',
    SET_AVERAGE_CLIENT_RETAINER: 'SET_AVERAGE_CLIENT_RETAINER',
    SET_ESTIMATED_TURNOVER_ANNUM: 'SET_ESTIMATED_TURNOVER_ANNUM',
    SET_ONE_OFF_CLIENTS: 'SET_ONE_OFF_CLIENTS',
    SET_WEEKLY_VS_FORTNIGHTLY: 'SET_WEEKLY_VS_FORTNIGHTLY',
    SET_ACTIVE_WEEKLY_CLIENTS: 'SET_ACTIVE_WEEKLY_CLIENTS',
    SET_ACTIVE_FORTNIGHTLY_CLIENTS: 'SET_ACTIVE_FORTNIGHTLY_CLIENTS',
    SET_AVERAGE_BEDROOMS_CLIENTS: 'SET_AVERAGE_BEDROOMS_CLIENTS',
    SET_PERCENT_PETS_CLIENTS: 'SET_PERCENT_PETS_CLIENTS',
    SET_AVERAGE_TIME_CLIENTS_STAY: 'SET_AVERAGE_TIME_CLIENTS_STAY',
    SET_ACTIVE_CLEANERS: 'SET_ACTIVE_CLEANERS',
    SET_ALLOCATED_CLEANERS: 'SET_ALLOCATED_CLEANERS',
    SET_PENDING_CLEANERS: 'xSET_PENDING_CLEANERS',
    SET_VACANT_CLEANERS: 'SET_VACANT_CLEANERS',
    SET_AVERAGE_JOBS_CLEANERS: 'SET_AVERAGE_JOBS_CLEANERS',
    SET_AVERAGE_JOBS_HOURS_CLEANERS: 'SET_AVERAGE_JOBS_HOURS_CLEANERS',
    SET_PERCENT_SELF_EMPLOYMENT_CLEANERS: 'SET_PERCENT_SELF_EMPLOYMENT_CLEANERS',
    SET_PERCENT_POLICE_CHECK_CLEANERS: 'SET_PERCENT_POLICE_CHECK_CLEANERS',
    SET_PENDING_TASKS: 'SET_PENDING_TASKS',
    SET_STATISTICS_TASKS: 'SET_STATISTICS_TASKS',
    SET_PENDING_FRANCHISEES: 'SET_PENDING_FRANCHISEES',
    SET_ACTIVE_FRANCHISEES: 'SET_ACTIVE_FRANCHISEES',
    SET_REPORT: 'SET_REPORT',
    LOADING: 'LOADING',

    getSaStatistics: () => {
        return async (dispatch, getState) => {
            const { registrationPlaceId } = getState().User;
            StatisticsApiService.saStatistics(registrationPlaceId)
                .then(data => {
                    dispatch({
                        type: actions.SET_SA_STATISTICS,
                        payload: data,
                    });
                });
        };
    },

    getStatisticsTasks: () => {
        return async dispatch => {
            await StatisticsApiService.allStatisticsTasks()
                .then(data => {
                    dispatch({
                        type: actions.SET_STATISTICS_TASKS,
                        payload: data,
                    });
                });
        };
    },

    getStatistics: (type, data) => {
        return async dispatch => {
            await StatisticsApiService.allStatistics(type, data)
                .then(item => {
                    if (type === 'LEAD_CLIENTS_BY_TYPE') {
                        if (data.leadType === 'Booked') {
                            dispatch({
                                type: actions.SET_BOOKED_CLIENTS_LEADS,
                                payload: item,
                            });
                        } else if (data.leadType === 'Follow up') {
                            dispatch({
                                type: actions.SET_FOLLOW_CLIENTS_LEADS,
                                payload: item,
                            });
                        } else if (data.leadType === 'New') {
                            dispatch({
                                type: actions.SET_NEW_CLIENTS_LEADS,
                                payload: item,
                            });
                        } else if (data.leadType === 'Waiting') {
                            dispatch({
                                type: actions.SET_WAITING_CLIENTS_LEADS,
                                payload: item,
                            });
                        } 
                    } else if (type === 'LEAD_CLEANERS_BY_TYPE') {
                        if (data.leadType === 'Booked') {
                            dispatch({
                                type: actions.SET_BOOKED_CLEANERS_LEADS,
                                payload: item,
                            });
                        } else if (data.leadType === 'Follow up') {
                            dispatch({
                                type: actions.SET_FOLLOW_CLEANERS_LEADS,
                                payload: item,
                            });
                        } else if (data.leadType === 'New') {
                            dispatch({
                                type: actions.SET_NEW_CLEANERS_LEADS,
                                payload: item,
                            });
                        } else if (data.leadType === 'Waiting') {
                            dispatch({
                                type: actions.SET_WAITING_CLEANERS_LEADS,
                                payload: item,
                            });
                        } 
                    } else if (type === 'ALL_LEADS_LOST') {
                        dispatch({
                            type: actions.SET_LOST_LEADS,
                            payload: item,
                        });
                    } else if (type === 'ALL_LEADS') {
                        dispatch({
                            type: actions.SET_ALL_LEADS,
                            payload: item,
                        });
                    } else if (type === 'LEAD_CLIENTS') {
                        dispatch({
                            type: actions.SET_CLIENTS_LEADS,
                            payload: item,
                        });
                    } else if (type === 'LEAD_CLEANERS') {
                        dispatch({
                            type: actions.SET_CLEANERS_LEADS,
                            payload: item,
                        });
                    } else if (type === 'ACTIVE_CLIENTS') {
                        dispatch({
                            type: actions.SET_ALL_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'ALLOCATED_CLIENTS') {
                        dispatch({
                            type: actions.SET_STARTED_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'PENDING_CLIENTS') {
                        dispatch({
                            type: actions.SET_PENDING_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'VACANT_CLIENTS') {
                        dispatch({
                            type: actions.SET_NO_CLEANER_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'GOOGLE_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_GOOGLE_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'LEAFLETS_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_LEAFLETS_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'MAGAZINES_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_MAGAZINES_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'FACEBOOK_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_FACEBOOK_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'INSTAGRAM_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_INSTAGRAM_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'LINKEDIN_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_LINKEDIN_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'REFERRAL_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_REFERRAL_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'DIRECTORY_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_DIRECTORY_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'OTHER_SOURCE_CLIENTS') {
                        dispatch({
                            type: actions.SET_OTHER_SOURCE_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'CLEANING_HOURS_WEEKLY') {
                        dispatch({
                            type: actions.SET_CLEANING_HOURS_WEEKLY,
                            payload: item,
                        });
                    } else if (type === 'AVERAGE_CLIENT_HOURS') {
                        dispatch({
                            type: actions.SET_AVERAGE_CLIENT_HOURS,
                            payload: item,
                        });
                    } else if (type === 'AVERAGE_CLIENT_RETAINER') {
                        dispatch({
                            type: actions.SET_AVERAGE_CLIENT_RETAINER,
                            payload: item,
                        });
                    } else if (type === 'ESTIMATED_TURNOVER_ANNUM') {
                        dispatch({
                            type: actions.SET_ESTIMATED_TURNOVER_ANNUM,
                            payload: item,
                        });
                    } else if (type === 'ONE_OFF_CLIENTS') {
                        dispatch({
                            type: actions.SET_ONE_OFF_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'WEEKLY_VS_FORTNIGHTLY') {
                        dispatch({
                            type: actions.SET_WEEKLY_VS_FORTNIGHTLY,
                            payload: item,
                        });
                    } else if (type === 'ACTIVE_WEEKLY_CLIENTS') {
                        dispatch({
                            type: actions.SET_ACTIVE_WEEKLY_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'ACTIVE_FORTNIGHTLY_CLIENTS') {
                        dispatch({
                            type: actions.SET_ACTIVE_FORTNIGHTLY_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'AVERAGE_BEDROOMS_CLIENTS') {
                        dispatch({
                            type: actions.SET_AVERAGE_BEDROOMS_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'PERCENT_PETS_CLIENTS') {
                        dispatch({
                            type: actions.SET_PERCENT_PETS_CLIENTS,
                            payload: item,
                        });
                    } else if (type === 'AVERAGE_TIME_CLIENTS_STAY') {
                        dispatch({
                            type: actions.SET_AVERAGE_TIME_CLIENTS_STAY,
                            payload: item,
                        });
                    } else if (type === 'ACTIVE_CLEANERS') {
                        dispatch({
                            type: actions.SET_ACTIVE_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'ALLOCATED_CLEANERS') {
                        dispatch({
                            type: actions.SET_ALLOCATED_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'PENDING_CLEANERS') {
                        dispatch({
                            type: actions.SET_PENDING_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'VACANT_CLEANERS') {
                        dispatch({
                            type: actions.SET_VACANT_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'AVERAGE_JOBS_CLEANERS') {
                        dispatch({
                            type: actions.SET_AVERAGE_JOBS_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'AVERAGE_JOBS_HOURS_CLEANERS') {
                        dispatch({
                            type: actions.SET_AVERAGE_JOBS_HOURS_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'PERCENT_SELF_EMPLOYMENT_CLEANERS') {
                        dispatch({
                            type: actions.SET_PERCENT_SELF_EMPLOYMENT_CLEANERS,
                            payload: item,
                        });
                    } else if (type === 'PERCENT_POLICE_CHECK_CLEANERS') {
                        dispatch({
                            type: actions.SET_PERCENT_POLICE_CHECK_CLEANERS,
                            payload: item,
                        });
                    }
                    else if (type === 'PENDING_FRANCHISEES') {
                        dispatch({
                            type: actions.SET_PENDING_FRANCHISEES,
                            payload: item,
                        });
                    }
                    else if (type === 'ACTIVE_FRANCHISEES') {
                        dispatch({
                            type: actions.SET_ACTIVE_FRANCHISEES,
                            payload: item,
                        });
                    }
                    else if (type === 'PENDING_TASKS') {
                        dispatch({
                            type: actions.SET_PENDING_TASKS,
                            payload: item,
                        });
                    }
                });
        };
    },

    getMapClientsAndCleaners: () => {

        return async (dispatch, getState) => {
            const searchParams = searchData({ start: 1, limit: 1000000000000, searchByActiveStatus: 0, getState });

            const [clients, cleaners] = await Promise.all([
                ClientApiService.fetchAllClients(searchParams),
                CleanersApiService.fetchAllCleaners(searchParams)
            ]);

            if (clients.clients && cleaners.cleaner) {
                dispatch({
                    type: actions.SET_MAP_MARKERS,
                    payload: clients.clients.concat(cleaners.cleaner),
                });
            } else {
                message.error(clients.msg);
            }
        };
    },

    getClients: (start) => {

        return async (dispatch, getState) => {

            dispatch({ type: actions.LOADING, payload: 'activeClients' });

            const clients = await ClientApiService.fetchAllClients(searchData({ start, searchByActiveStatus: 'BOTH', getState }));

            if (clients.clients) {
                dispatch({
                    type: actions.SET_CLIENTS,
                    payload: clients,
                });
            } else {
                dispatch({
                    type: actions.SET_CLIENTS,
                    payload: {
                        clients: [],
                        total: 0
                    },
                });
                message.error(clients.msg);
            }
        };
    },

    getCleaners: (start) => {

        return async (dispatch, getState) => {
            dispatch({ type: actions.LOADING, payload: 'activeCleaners' });

            const cleaners = await CleanersApiService.fetchAllCleaners(searchData({ start, searchByActiveStatus: 'BOTH', getState }));

            if (cleaners.cleaner) {
                dispatch({
                    type: actions.SET_CLEANERS,
                    payload: cleaners,
                });
            } else {
                dispatch({
                    type: actions.SET_CLEANERS,
                    payload: {
                        cleaner: [],
                        total: 0
                    },
                });
                message.error(cleaners.msg);
            }
        };
    },

    getLeadCleaners: (start) => {

        return async (dispatch, getState) => {
            dispatch({ type: actions.LOADING, payload: 'activeLeadCleaners' });

            const leadCleaners = await LeadApiService.fetchAllLeads(searchData({ start, getState }), 'cleaner');

            if (leadCleaners.cleaner) {
                dispatch({
                    type: actions.SET_LEAD_CLEANERS,
                    payload: leadCleaners,
                });
            } else {
                dispatch({
                    type: actions.SET_LEAD_CLEANERS,
                    payload: [],
                });
                message.error(leadCleaners.msg);
            }
        };
    },

    getLeadClients: (start) => {

        return async (dispatch, getState) => {
            dispatch({ type: actions.LOADING, payload: 'activeLeadClients' });

            const leadClients = await LeadApiService.fetchAllLeads(searchData({ start, getState }), 'client');

            if (leadClients.clients) {
                dispatch({
                    type: actions.SET_LEAD_CLIENTS,
                    payload: leadClients,
                });
            } else {
                dispatch({
                    type: actions.SET_LEAD_CLIENTS,
                    payload: [],
                });
                message.error(leadClients.msg);
            }
        };
    },


    getActivities: (bool) => {

        return async (dispatch, getState) => {
            dispatch({ type: actions.LOADING, payload: !bool ? 'allActivities' : 'taskActivities' });

            const activities = await StatisticsApiService.getActivity(bool);

            if (activities) {
                if (activities.status === 'SUCCESS') {
                    dispatch({
                        type: !bool ? actions.SET_ALL_ACTIVITIES : actions.SET_TASK_ACTIVITIES,
                        payload: activities.response,
                    });
                } else {
                    dispatch({
                        type: actions.SET_LEAD_CLIENTS,
                        payload: [],
                    });
                    message.error(activities.errorMessage);
                }
            }
        };
    },

    getReports: (data) => {

        return async dispatch => {
            await StatisticsApiService.getReport(data)
                .then(item => {
                    dispatch({
                        type: actions.SET_REPORT,
                        payload: item,
                    });
                });
        };
    },
};
export default actions;