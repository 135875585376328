import HttpClient from './http.api';

export default class JobApiService {
    static getJobsListForClient(data, id) {
        return HttpClient.post({ url: `api/v1/web/job/getJobsListForClient?clientId=${id}`, data });
    }

    static getJobsListForCleaner(data, id) {
        return HttpClient.post({ url: `api/v1/web/job/getJobsListForCleaner?cleanerId=${id}`, data });
    }

    static deleteJob(data) {
        return HttpClient.delete({ url: 'api/v1/web/job/delete', data: { jobsIdList: [data] } });
    }

    static updateJob(data, id) {
        return HttpClient.put({ url: `api/v1/web/job/update/${id}`, data });
    }

    static selectClientsNearCleaner(data, id) {
        return HttpClient.post({ url: `api/v1/web/job/selectClientsNearCleaner?cleanerId=${id}`, data });
    }

    static selectCleanerForNewJob(data, id) {
        return HttpClient.post({ url: `api/v1/web/job/selectCleanerForNewJob?clientId=${id}`, data });
    }

    static addNewJob(data) {
        return HttpClient.post({ url: 'api/v1/web/job/add', data });
    }

    static getJob(id) {
        return HttpClient.get({ url: `api/v1/web/job/${id}` });
    }

    static getDistance(cleanerId, clientId) {
        return HttpClient.post({ url: `api/v1/web/job/getDistanceBetweenClientAndCleaner?cleanerId=${cleanerId}&clientId=${clientId}` });
    }
}
