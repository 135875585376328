import ACCOUNT_TYPE from '../../components/AccountInfoView/AccountType';
import actions from './actions';

const initState = {
    cleanersNearClient: [],
    clientsNearCleaner: [],
    cleanerJobs: [],
    clientJobs: [],
    jobs: [],
    job: {},
    isLoading: false,
    isJobCreated: false,
    totalClients: null,
    totalCleaners: null,
    savedEditedJob: {},
    savedEditedJobId: null,
    updateJobsList: false,
    getUpdatedClient: false,
    clientCleanerDistance: '',
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
    case actions.GET_CLEANERS_LIST:
        // eslint-disable-next-line no-case-declarations
        const cleaners = payload.cleaner.map(el => {
            return { ...el, 
                police_check: `${el.police}`, UTR: `${el.utr}`, 
                status_job: el.jobStatus,
                availability_work_days: {
                    am: el.availabilityWorkDaysWrapper.am,
                    pm: el.availabilityWorkDaysWrapper.pm
                },
                cleaner_id: el.id
            };
        });
        return {
            ...state,
            cleanersNearClient: cleaners,
            totalCleaners: Number(payload.total)
        };
    case actions.GET_CLIENTS_LIST:
        const clientsNearCleaner = payload.client.map( el => {
            return { ...el, 
                status_job: el.jobStatus,
                availability_work_days: {
                    am: el.availabilityWorkDaysWrapper.am,
                    pm: el.availabilityWorkDaysWrapper.pm
                } 
            };
        });
        return {
            ...state,
            clientsNearCleaner,
            totalClients: Number(payload.total)
        };
    case actions.IS_LOADING:
        return {
            ...state,
            isLoading: payload,
        };
    case actions.IS_JOB_CREATED:
        return {
            ...state,
            isJobCreated: payload,
        };
    case actions.GET_CLEANER_JOBS:
        return {
            ...state,
            cleanerJobs: payload.job,
            isLoading: false,
            updateJobsList: false
        };
    case actions.GET_CLIENT_JOBS:
        return {
            ...state,
            clientJobs: payload.job,
            isLoading: false,
            updateJobsList: false
        };
    case actions.GET_DISTANCE:
        return {
            ...state,
            clientCleanerDistance: payload.job.distance,
        };
    case actions.GET_JOB:
        return {
            ...state,
            job: payload.job,
        };
    case actions.RESET_CLEANERS:
        return {
            ...state,
            cleanersNearClient: payload,
        };
    case actions.RESET_CLIENTS:
        return {
            ...state,
            clientsNearCleaner: payload,
        };
    case actions.DELETE_JOB:
        let jobs;
        if (payload.accountType === ACCOUNT_TYPE.CLIENT) {
            jobs = state.clientJobs.filter(el => el.jobId !== payload.jobId);
            return {
                ...state,
                clientJobs: jobs
            };
        } else {
            jobs = state.cleanerJobs.filter(el => el.jobId !== payload.jobId);
            return {
                ...state,
                cleanerJobs: jobs
            };
        }      
    case actions.SAVE_EDITED_JOB:
        return {
            ...state,
            savedEditedJob: payload.job,
            savedEditedJobId: payload.jobId,
            showEditJobDetails: payload.showModal
        };
    case actions.UPDATE_JOBS_LIST:
        return {
            ...state,
            updateJobsList: payload,
        };
    case actions.SHOULD_UPDATE_CLIENT:
        return {
            ...state,
            getUpdatedClient: payload
        };
    default:
        return state;
    }
}
