import { message } from 'antd';
import { AuthApiService } from '../../api/authApiService';

const DOCUMENT = 'RESET_PASS_';

const actions = {
    SET_REDIRECT_TO_LOGIN: DOCUMENT + 'SET_REDIRECT_TO_LOGIN',

    resetPassword: (params = {}) => {
        return async dispatch => {

            await AuthApiService.resetPassword(params)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        message.success('Password was set successfully');
                        dispatch({
                            type: actions.SET_REDIRECT_TO_LOGIN,
                            payload: true,
                        });
                    } else {
                        message.error('Wrong email or verification code');
                    }
                });
        };
    }

};
export default actions;
