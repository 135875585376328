import HttpClient from './http.api';

export class ReportAnIssueApiService {
    static addBugReport(params, data) {
        return HttpClient.post({ url: '/api/v1/bugReport/add?' + params, data, type: 'formData' });
    }
    static getLogFile() {
        return HttpClient.get({ url: '/api/v1/bugReport/getLogFile' });
    }
}
