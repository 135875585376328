import { message } from 'antd';
import { SignatoryApiService } from '../../api/signatoryApiService';

const actions = {
    GET_SIGNATORY: 'GET_SIGNATORY',
    GET_SIGNATORY_LIST: 'GET_SIGNATORY_LIST',
    SHOULD_UPDATE_SIGNATORY_LIST: 'SHOULD_UPDATE_SIGNATORY_LIST',
    IS_LOADING: 'IS_LOADING',
    HANDLE_PAGINATION_CHANGE: 'HANDLE_PAGINATION_CHANGE',

    getSignatoryList: (data = {}) => {
        return async dispatch => {
            try {
                dispatch(actions.isloading(true));
                await   SignatoryApiService.getSignatoryList(data)
                    .then(data => {
                        dispatch({
                            type: actions.GET_SIGNATORY_LIST,
                            payload: data.response

                        });
                        dispatch(actions.isloading(false));
                    });
            } catch (e) {
                message.error('Unknown error.'); 
            }
        };
    },

    getSignatoryById: (data = {}) => {
        return async dispatch => {
            try {
                dispatch(actions.isloading(true));
                SignatoryApiService.getSignatoryById(data)
                    .then(data => {
                        dispatch({
                            type: actions.GET_SIGNATORY,
                            payload: data
                        });
                        dispatch(actions.isloading(false));
                    });
            } catch (e) {
                message.error('Unknown error.'); 
            }
        };
    },

    addSignatory: (data = {}) => {
        return async dispatch => {
            try {
                dispatch(actions.isloading(true));
                SignatoryApiService.addSignatory(data)
                    .then(() => {
                        message.success('Signatory created. Check inbox.'); 
                        dispatch({
                            type: actions.SHOULD_UPDATE_SIGNATORY_LIST,
                            payload: { data : true }
                        });
                        dispatch(actions.isloading(false));
                    });
            } catch (e) {
                message.error('Unknown error.'); 
            }
        };
    },

    updateSignatory: (data = {}) => {
        return async dispatch => {
            try {
                dispatch(actions.isloading(true));
                SignatoryApiService.updateSignatory(data)
                    .then(() => {
                        message.success('Updated successfully.');  
                        dispatch({
                            type: actions.SHOULD_UPDATE_SIGNATORY_LIST,
                            payload: { data: true }
                        });
                        dispatch(actions.isloading(false));
                    });
            } catch (e) {
                message.error('Unknown error.');  
            }
        };
    },

    updateSignatoryList: data => ({
        type: actions.SHOULD_UPDATE_SIGNATORY_LIST,
        payload: { data },
    }),

    deleteSignatory: (id) => {
        return async dispatch => {
            try {
                dispatch(actions.isloading(true));
                SignatoryApiService.deleteSignatory(id)
                    .then(() => {
                        message.success('Deleted successfully.');  
                        dispatch({
                            type: actions.SHOULD_UPDATE_SIGNATORY_LIST,
                            payload: { data: true }
                        });
                        dispatch(actions.isloading(false));
                    });
            } catch (e) {
                message.error('Unknown error.');  
            }
        };
    },

    isloading: (data) => {
        return {
            type: actions.IS_LOADING,
            payload: data
        };
    },

    handlePaginationChange: (page) => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: page
        };
    },
};
export default actions;
