import flags from '../../containers/Pages/Locale/flags';
import actions from './actions';

const initState = {
    locale: 'uk'
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
    case actions.SELECT_LOCALE:
        return {
            ...state,
            locale: flags[payload.data].registrationPlace.toLowerCase()
        };

    default:
        return state;
    }
}
