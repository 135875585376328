
export const PUBLIC_ROUTE = {
    LANDING: '/',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    SIGN_UP_COMPLETE: '/signup-complete',
    FORGET_PASSWORD: '/forgotpassword',
    RESET_PASSWORD: '/resetpassword',
    PAGE_404: '/404',
    PAGE_500: '/500',
};

export const PRIVATE_ROUTE = {
    PROFILE: 'my-profile',
    CLIENT_LEAD_EDIT: 'clients-leads/:lead_id/edit',
    CLIENT_LEADS: 'clients-leads',
    CLEANER_LEAD_EDIT: 'cleaners-leads/:lead_id/edit',
    CLEANER_LEADS: 'cleaners-leads',
    CLIENT_EDIT: 'clients/:client_id/edit',
    CLEANER_EDIT: 'cleaners/:cleaner_id/edit',
    CLIENTS: 'clients',
    CLEANERS: 'cleaners',
    TASK_TO_DO_SHOW: 'tasks-to-do/:task_id/',
    TASK_TO_DO: 'tasks-to-do',
    NEW_JOB: 'new-job',
    REPORTS: 'reports',
    SIGNATORIES: 'signatories',
    SA_MANAGERS: 'sa-managers',
    SA_FRANCHISEES: 'sa-franchisees',
    SA_CONDITIONS: 'sa-conditions',
    SA_SERVICE: 'sa-service',
    SA_EMAIL_TEMPLATES: 'sa-email-templates',
    EDIT_EMAIL_TEMPLATES: 'edit-email-template',
    EDIT_FRANCHISEES: 'edit-franchisees',
    REPORT_ISSUE: 'report-issue',
    CLIENTS_REPORT: 'clients-report',
    CLEANERS_REPORT: 'cleaners-report',
    LEADS_REPORT: 'leads-report',
    TASKS_REPORT: 'tasks-report',
};
