import { AdminApiService } from '../../api/adminApiService';
import { message } from 'antd';

const actions = {
    CHECK_IS_NEW_EMAIL: 'CHECK_IS_NEW_EMAIL',
    IS_LOADING: 'IS_LOADING',
    FORCE_UPDATE: 'FORCE_UPDATE',
    CHANGE_CREDENTIALS: 'CHANGE_CREDENTIALS',

    checkIsNewEmail: (data = '') => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await AdminApiService.checkIsNewEmail(data)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        dispatch({
                            type: actions.CHECK_IS_NEW_EMAIL,
                            payload: true,
                        });
                    } else {
                        message.error('Email already exists.');
                        dispatch({
                            type: actions.CHECK_IS_NEW_EMAIL,
                            payload: false,
                        });
                    }
                });
            dispatch(actions.isloading(false));
        };
    },

    changeCredentials: (data) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await AdminApiService.changeCredentials(data)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        dispatch({
                            type: actions.CHANGE_CREDENTIALS,
                            payload: true,
                        });
                        message.success('Franchise user parameters updated successfully!');
                    } else {
                        message.error('Please check your credentials');
                        dispatch({
                            type: actions.CHANGE_CREDENTIALS,
                            payload: false,
                        });
                    }
                });
            dispatch(actions.isloading(false));
        };
    },

    updateLoginRegistrationPlace: (registrationPlace) => {
        return async dispatch => {   
            dispatch(actions.isloading(true));
            await AdminApiService.updateLoginRegistrationPlace(registrationPlace)
                .then(() => {
                    message.success('Locale updated.');
                    dispatch(actions.updateLocale(true));
                    dispatch(actions.isloading(false));
                });
            
        };
    },

    updateLocale: (data) => {
        return {
            type: actions.FORCE_UPDATE,
            payload: data
        };
    },

    isloading: (data) => {
        return {
            type: actions.IS_LOADING,
            payload: data
        };
    },

    resetCheckIsNewEmail: () => {
        return {
            type: actions.CHECK_IS_NEW_EMAIL,
            payload: false
        };
    }
};
export default actions;
