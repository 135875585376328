import { appConfig } from '@iso/config/app.config';
import HttpClient from './http.api';

export class ReportsApiService {
    static downloadReport(data, format) {
        return (
            fetch(`${appConfig.apiUrl}api/v1/web/download/${format}`, {
                method: 'POST',
                headers: new Headers({
                    'authorization': localStorage.getItem('id_token'),
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(data)
            })
                .then(response => response.blob())
        );
    }

    static downloadCvs(data) {
        return (
            fetch(`${appConfig.apiUrl}api/v1/web/download/csv`, {
                method: 'POST',
                headers: new Headers({
                    'authorization': localStorage.getItem('id_token'),
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify(data)
            })
                .then(response => response.blob())
        );
    }

    static getChartData(type, data) {
        return HttpClient.post({ url: `api/v1/web/statistic/getData?type=${type}`, data });
    }
}

