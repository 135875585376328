import actions from './actions';

const initState = {
    leads: [],
    single_lead: {
        town: '',
        county: '',
        postcode: '',
        street: '',
        address2: '',
        comment: '',
        email: '',
        house_holder1Fn: '',
        house_holder1Ln: '',
        house_holder2Fn: '',
        house_holder2Ln: '',
        cleaner_name_fn: '',
        cleaner_name_ln: '',
        leadStatus: '',
        mobile: '',
        telephone: '',
        work_phone: '',
        utr_number: '',
        nationality: '',
        national_insur_number: '',
        date_of_birth: '',
    },
    lead_object: {},
    new_lead: {
        data: {
            town: '',
            county: '',
            postcode: '',
            street: '',
            address2: '',
            comment: '',
            email: '',
            house_holder1Fn: '',
            house_holder1Ln: '',
            house_holder2Fn: '',
            house_holder2Ln: '',
            first_name: '',
            last_name: '',
            leadStatus: '',
            mobile: '',
            telephone: '',
            work_phone: '',
            // createdate_time: "",
        },
        type: '',
    },
    selected_lead_id: 0, // default 0
    add_lead_modal_visible: false,
    status_modal_visible: false,
    convert_modal_visible: false,
    edit_info_modal_visible: false,
    edit_contacts_modal_visible: false,
    edit_note_modal_visible: false,
    edit_additional_modal_visible: false,
    add_task_modal_visible: false,
    current_lead_step: 0,
    current_step: 0,
    current_client_step: 0,
    pagination: {
        current: 1,
        pageSize: 10, // default 10
        showSizeChanger: false,
        hideOnSinglePage: true,
    },
    selectedRowKeys: [],
    loading: false,
    statusValue: '',
    contactValue: 1,
    temporary_single_lead_status: '',
    valid_data: true,
    lead_type: '',
    tasksCurrentPage: {
        toDo: 1,
        completed: 1
    },
    tasksFilters: {
        isToDo: false,
        isCompleted: false
    },
    allClientTasks: [],
    edited_client_task_id: '',
    allCleanerTasks: [],
    edited_cleaner_task_id: '',
    isVisible: false,
    redirect_to_client: false,
    searchLeadsValue: '',
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.LEADS_SEARCH_VALUE:

            return {
                ...state,
                searchLeadsValue: action.payload
            };

        case actions.TASKS_CURRENT_PAGE:
            const field = action.payload.changing_field;
            return {
                ...state,
                tasksCurrentPage: {
                    ...state.tasksCurrentPage,
                    [field]: action.payload.data
                }
            };

        case actions.RESET_PAGINATION_TO_INIT_STATE:
            return {
                ...state,
                pagination: {
                    ...initState.pagination
                }
            };
        case actions.FETCH_ALL_CLIENTS:
            return {
                ...state,
                leads: action.payload.clients,
                selectedRowKeys: [],
                pagination: {
                    ...state.pagination,
                    total: Number(action.payload.total)
                }
            };
        case actions.FETCH_ALL_CLEANERS:
            return {
                ...state,
                leads: action.payload.cleaner,
                selectedRowKeys: [],
                pagination: {
                    ...state.pagination,
                    total: Number(action.payload.total)
                }
            };
        case actions.FETCH_SINGLE_CLIENT:
            return {
                ...state,
                single_lead: {
                    ...state.single_lead,
                    town: action.payload.client.address.town,
                    county: action.payload.client.address.county,
                    postcode: action.payload.client.address.postcode,
                    street: action.payload.client.address.street,
                    address2: action.payload.client.address2,
                    email: action.payload.client.email,
                    house_holder1Fn: action.payload.client.house_holder1_fn,
                    house_holder1Ln: action.payload.client.house_holder1_ln,
                    house_holder2Fn: action.payload.client.house_holder2_fn,
                    house_holder2Ln: action.payload.client.house_holder2_ln,
                    leadStatus: action.payload.client.leadStatus,
                    mobile: action.payload.client.mobile,
                    telephone: action.payload.client.telephone,
                    work_phone: action.payload.client.work_phone,
                    comment: action.payload.client.comment,
                    createdate_time: action.payload.client.createdate_time,
                },
                lead_object: {
                    ...state.lead_object,
                    rowObj: action.payload.client
                }
            };
        case actions.FETCH_SINGLE_CLEANER:
            return {
                ...state,
                single_lead: {
                    ...state.single_lead,
                    town: action.payload.cleaner?.address.town,
                    county: action.payload.cleaner?.address.county,
                    postcode: action.payload.cleaner?.address.postcode,
                    street: action.payload.cleaner?.address.street,
                    address2: action.payload.cleaner?.address2,
                    email: action.payload.cleaner?.email,
                    first_name: action.payload.cleaner?.cleaner_name_fn,
                    last_name: action.payload.cleaner?.cleaner_name_ln,
                    leadStatus: action.payload.cleaner?.leadStatus,
                    mobile: action.payload.cleaner?.mobile,
                    telephone: action.payload.cleaner?.telephone,
                    work_phone: action.payload.cleaner?.work_phone,
                    comment: action.payload.cleaner?.comment,
                    utr_number: action.payload.cleaner?.UTR_number,
                    nationality: action.payload.cleaner?.nationality,
                    national_insur_number: action.payload.cleaner?.national_insur_number,
                    date_of_birth: action.payload.cleaner?.date_of_birth,
                },
                lead_object: {
                    ...state.lead_object,
                    rowObj: action.payload.cleaner
                }
            };
        case actions.ON_CHANGE_SINGLE_LEAD_PERSONAL_INFO:
            return {
                ...state,
                single_lead: {
                    ...state.single_lead,
                    ...action.payload,
                },
            };
        case actions.LOADING_TRUE:
            return {
                ...state,
                loading: action.payload,
            };
        case actions.LOADING_FALSE:
            return {
                ...state,
                loading: action.payload,
            };
        case actions.NEW_LEAD_PARAMS:
            return {
                ...state,
                new_lead: {
                    ...state.new_lead,
                    data: {
                        ...state.new_lead.data,
                        ...action.payload,
                    }
                },
            };
        case actions.RESET_NEW_LEAD_PARAMS:
            return {
                ...state,
                new_lead: {
                    ...state.new_lead,
                    data: {
                        ...state.new_lead.data,
                        ...initState.new_lead.data
                    }
                },
            };
        case actions.NEW_LEAD_TYPE:
            return {
                ...state,
                new_lead: {
                    ...state.new_lead,
                    type: action.payload,
                },
            };
        case actions.LEAD_TYPE:
            return {
                ...state,
                lead_type: action.payload,
            };
        case actions.ON_CHANGE_STATUS:
            return {
                ...state,
                statusValue: action.payload,
            };
        case actions.ON_CHANGE_CONTACT:
            return {
                ...state,
                contactValue: action.payload,
            };
        case actions.ON_CHANGE_SINGLE_LEAD_TEMPORARY_STATUS:
            return {
                ...state,
                temporary_single_lead_status: action.payload,
            };
        case actions.ON_CHANGE_SINGLE_LEAD_STATUS:
            return {
                ...state,
                single_lead: {
                    ...state.single_lead,
                    leadStatus: action.payload,
                },
            };
        case actions.ON_SELECT_ID_CHANGE:
            return {
                ...state,
                selectedRowKeys: action.payload,
            };
        case actions.VALID_DATA_STATUS:
            return {
                ...state,
                valid_data: action.payload,
            };
        case actions.HANDLE_PAGINATION_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: action.payload.page,
                }
            };
        case actions.HANDLE_PAGE_SIZE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 1,
                    pageSize: action.payload,
                }
            };
        case actions.ADD_LEAD_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                add_lead_modal_visible: action.payload,
            };
        case actions.STATUS_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                status_modal_visible: action.payload,
            };
        case actions.CONVERT_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                convert_modal_visible: action.payload,
            };
        case actions.EDIT_INFO_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                edit_info_modal_visible: action.payload,
            };
        case actions.EDIT_CONTACTS_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                edit_contacts_modal_visible: action.payload,
            };
        case actions.EDIT_NOTE_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                edit_note_modal_visible: action.payload,
            };
        case actions.EDIT_ADDITIONAL_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                edit_additional_modal_visible: action.payload,
            };
        case actions.ADD_TASK_MODAL_VISIBLE_STATUS:
            return {
                ...state,
                add_task_modal_visible: action.payload,
            };
        case actions.CHANGE_CURRENT_LEAD_STEP:
            return {
                ...state,
                current_lead_step: action.payload,
            };
        case actions.CHANGE_CURRENT_STEP:
            return {
                ...state,
                current_step: action.payload,
            };
        case actions.CHANGE_CURRENT_CONVERT_STEP:
            return {
                ...state,
                current_client_step: action.payload,
            };
        case actions.SELECTED_LEAD_ID:
            return {
                ...state,
                selected_lead_id: action.payload,
            };
        case actions.FETCH_CLIENT_TASKS:
            return {
                ...state,
                allClientTasks: action.payload.response.list,
                pagination: {
                    ...state.pagination,
                    total: Number(action.payload.total)
                }
            };
        case actions.ADD_CLIENT_TASK:
            return {
                ...state,
                allClientTasks: action.payload.data
            };
        case actions.EDIT_CLIENT_TASK:
            const clientTasks = state.tasks.map(el => {
                if (el.id === action.payload.data.id) {
                    return el = action.payload.data.taskItem;
                } else {
                    return el;
                }
            });
            return { ...state, clientTasks };
        case actions.DELETE_CLIENT_TASK:
            const filtered_tasks = state.tasks.filter(el => el.id !== action.payload.data.taskId);
            return {
                ...state,
                tasks: filtered_tasks
            };
        case actions.FETCH_CLEANER_TASKS:
            return {
                ...state,
                allCleanerTasks: action.payload.response.list,
                pagination: {
                    ...state.pagination,
                    total: Number(action.payload.total)
                }
            };
        case actions.ADD_CLEANER_TASK:
            return {
                ...state,
                allCleanerTasks: action.payload.data
            };
        case actions.EDIT_CLEANER_TASK:
            const cleanerTasks = state.tasks.map(el => {
                if (el.id === action.payload.data.id) {
                    return el = action.payload.data.taskItem;
                } else {
                    return el;
                }
            });
            return { ...state, cleanerTasks };
        case actions.DELETE_CLEANER_TASK:
            const filtered_cleaner_tasks = state.tasks.filter(el => el.id !== action.payload.data.taskId);
            return {
                ...state,
                tasks: filtered_cleaner_tasks
            };
        case actions.TOGGLE_MODAL:
            return {
                ...state,
                isVisible: action.payload,
            };
        case actions.REDIRECT_TO_CLIENT:
            return {
                ...state,
                redirect_to_client: action.payload,
            };
        default: return state;
    }
}
