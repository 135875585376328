import { message } from 'antd';
import { ReportAnIssueApiService } from '../../api/reportAnIssueApiService';

const DOCUMENT = 'REPORT_AN_ISSUE';

const actions = {
    LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
    LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
    GET_LOG_FILE: DOCUMENT + 'GET_LOG_FILE',

    loadingTrue:() => {
        return {
            type: actions.LOADING_TRUE,
            payload: true
        };
    },

    loadingFalse:() => {
        return {
            type: actions.LOADING_FALSE,
            payload: false
        };
    },
    
    addBugReport:(filesList, screenshotList, logFile, params={}) => {
        return async dispatch => {
            const formData = new FormData();
            if (filesList.length === 0 && logFile === null) {
                screenshotList.forEach((el, index) => {
                    formData.append('screenshot' + (Number(index)+1), el);
                });
            }
            else if (filesList.length !== 0 && logFile === null) {
                formData.append('file', filesList.at(-1));
                screenshotList.forEach((el, index) => {
                    formData.append('screenshot' + (Number(index)+1), el);
                });
            }
            else if (logFile !== null && filesList.length === 0) {
                formData.append('logFile', logFile);
                screenshotList.forEach((el, index) => {
                    formData.append('screenshot' + (Number(index)+1), el);
                });
            }
            else {
                formData.append('file', filesList.at(-1));
                formData.append('logFile', logFile);
                screenshotList.forEach((el, index) => {
                    formData.append('screenshot' + (Number(index)+1), el);
                });
            }
            const str_params = new URLSearchParams(params).toString();
            dispatch(actions.loadingTrue());
            await ReportAnIssueApiService.addBugReport(str_params, formData)
                .then(() => {
                    message.success('Bug report created successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    getLogFile:() => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            try {
            await ReportAnIssueApiService.getLogFile()
                .then(data => {
                    dispatch({
                        type: actions.GET_LOG_FILE,
                        payload: data,
                    });
                    if (data.status === 'FAILURE') {
                        message.error('No log file presented for this user id');
                    }
                });
            } catch (error) {
                if (error.status === 500) {
                    dispatch({
                        type: actions.GET_LOG_FILE,
                        payload: {},
                    });
                    message.error('Can\'t fetch log file due to error');
                }
            }
            dispatch(actions.loadingFalse());
        };
    },
};

export default actions;
