import actions from "./actions";

const initState = {
  loading: true,
  commonItems: [
    { name: "active.clients", count: 0, withMoreBtn: true, link: "clients" },
    { name: "active.cleaners", count: 0, withMoreBtn: true, link: "cleaners" },
    { name: "total.cleaning.hours", count: 0, withMoreBtn: false },
    { name: "av.client.hours", count: 0, withMoreBtn: false },
    { name: "av.client.payment", count: 0, withMoreBtn: false },
    { name: "weekly.vs.fortnightly", count: 0, withMoreBtn: false },
    { name: "estimated.turnover", count: 0, withMoreBtn: false },
    { name: "pending.task", count: 0, withMoreBtn: true, link: "tasks-to-do" },
  ],

  additionalItems: [
    { name: "tasks.overdue", count: 0, withMoreBtn: true, link: "tasks-to-do" },
    {
      name: "leads.client",
      count: 0,
      withMoreBtn: true,
      link: "clients-leads",
    },
    {
      name: "leads.cleaner",
      count: 0,
      withMoreBtn: true,
      link: "cleaners-leads",
    },
  ],
  statisticsItems: [
    { name: "active.clients", count: 0, withMoreBtn: false },
    { name: "active.cleaners", count: 0, withMoreBtn: false },
    { name: "total.cleaning.hours", count: 0, withMoreBtn: false },
    { name: "estimated.turnover", count: 0, withMoreBtn: false },
    {
      name: "active.franchisees",
      count: 0,
      withMoreBtn: true,
      link: "sa-franchisees?status=active",
    },
    {
      name: "pending.frachisees",
      count: 0,
      withMoreBtn: true,
      link: "sa-franchisees?status=pending",
    },
    { name: "av.client.hours", count: 0, withMoreBtn: false },
    { name: "av.client.payment", count: 0, withMoreBtn: false },
  ],
  activeLeadClients: {
    total: 0,
    loading: true,
    data: [],
  },
  activeLeadCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  allActivities: {
    total: 0,
    loading: true,
    data: [],
  },
  taskActivities: {
    total: 0,
    loading: true,
    data: [],
  },
  additionalItemsForClientLeads: [
    {
      name: "booked.statistic",
      count: 0,
      withMoreBtn: true,
      link: "clients-leads",
    },
    {
      name: "follow.statistic",
      count: 0,
      withMoreBtn: true,
      link: "clients-leads",
    },
    {
      name: "new.statistic",
      count: 0,
      withMoreBtn: true,
      link: "clients-leads",
    },
    {
      name: "waiting.statistic",
      count: 0,
      withMoreBtn: true,
      link: "clients-leads",
    },
  ],
  additionalItemsForCleanersLeads: [
    {
      name: "booked.statistic",
      count: 0,
      withMoreBtn: true,
      link: "cleaners-leads",
    },
    {
      name: "follow.statistic",
      count: 0,
      withMoreBtn: true,
      link: "cleaners-leads",
    },
    {
      name: "new.statistic",
      count: 0,
      withMoreBtn: true,
      link: "cleaners-leads",
    },
    {
      name: "waiting.statistic",
      count: 0,
      withMoreBtn: true,
      link: "cleaners-leads",
    },
  ],
  additionalItemsForClient: [
    { name: "started.statistic", count: 0, withMoreBtn: true, link: "clients" },
    { name: "pending.statistic", count: 0, withMoreBtn: true, link: "clients" },
    {
      name: "noCleaner.statistic",
      count: 0,
      withMoreBtn: true,
      link: "clients",
    },
  ],
  additionalItemsForCleaners: [
    {
      name: "started.statistic",
      count: 0,
      withMoreBtn: true,
      link: "cleaners",
    },
    {
      name: "pending.statistic",
      count: 0,
      withMoreBtn: true,
      link: "cleaners",
    },
    { name: "noJobs.statistic", count: 0, withMoreBtn: true, link: "cleaners" },
  ],
  bookedClientsLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  followClientsLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  newClientsLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  waitingClientsLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  bookedCleanersLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  followCleanersLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  newCleanersLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  waitingCleanersLeads: {
    total: 0,
    loading: true,
    data: [],
  },
  lostLeads: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  activeLeads: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  cleanersLeads: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  clientsLeads: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  activeClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  startedClients: {
    total: 0,
    loading: true,
    data: [],
  },
  pendingClients: {
    total: 0,
    loading: true,
    data: [],
  },
  noCleanerClients: {
    total: 0,
    loading: true,
    data: [],
  },
  googleClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  leafletsClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  magazinsClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  facebookClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  instagramClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  linkedinClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  refferralClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  directoryClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  otherClients: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  totalHoursClients: {
    total: 0,
    loading: true,
    data: [],
  },
  averageHoursClients: {
    total: 0,
    loading: true,
    data: [],
  },
  averagePaymentClients: {
    total: 0,
    loading: true,
    data: [],
  },
  estTurnoverClients: {
    total: 0,
    loading: true,
    data: [],
  },
  oneOffsClients: {
    total: 0,
    loading: true,
    data: [],
  },
  weeklyFortnightlyClients: {
    total: 0,
    loading: true,
    data: [],
  },
  activeWeeklyClients: {
    total: 0,
    loading: true,
    data: [],
  },
  activeFortnightlyClients: {
    total: 0,
    loading: true,
    data: [],
  },
  averageBedroomsClients: {
    total: 0,
    loading: true,
    data: [],
  },
  prWithPetsClients: {
    total: 0,
    loading: true,
    data: [],
  },
  averageTimeClientsStay: {
    total: 0,
    loading: true,
    data: [],
  },
  activeCleaners: {
    total: 0,
    loading: true,
    data: [],
    lost30Days: 0,
  },
  startedCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  pendingCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  noJobCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  averageJobsCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  averageJobsHoursCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  percentSelfEmploymentCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  percentPoliceCheckCleaners: {
    total: 0,
    loading: true,
    data: [],
  },
  pengingTasks: {
    total: 0,
    loading: true,
    data: [],
  },
  statisticsTasks: {
    total: 0,
    loading: true,
    data: [],
  },
  pendingFranchisees: {
    total: 0,
    loading: true,
    data: [],
  },
  activeFranchisees: {
    total: 0,
    loading: true,
    data: [],
  },
  report: {
    loading: true,
    data: [],
  },
};

export default function statisticsReducer(state = initState, action) {
  const additionalItemsForClientLeads = [
    ...state.additionalItemsForClientLeads,
  ];
  const additionalItemsForCleanersLeads = [
    ...state.additionalItemsForCleanersLeads,
  ];
  const additionalItemsForClient = [...state.additionalItemsForClient];
  const additionalItemsForCleaners = [...state.additionalItemsForCleaners];
  const commonItems = [...state.commonItems];
  const additionalItems = [...state.additionalItems];
  const statisticsItems = [...state.statisticsItems];

  switch (action.type) {
    case actions.SET_MAP_MARKERS:
      const mapData = action.payload.map((el) => {
        const data = { lat: el.lat, lng: el.lng };
        if (el.client_name) {
          data["client_name"] = el.client_name;
        }
        if (el.cleaner_name) {
          data["cleaner_name"] = el.cleaner_name;
        }
        return data;
      });
      return {
        ...state,
        mapClients: mapData,
      };

    case actions.SET_CLIENTS:
      return {
        ...state,
        activeClients: {
          total: action.payload.total,
          loading: false,
          data: action.payload.clients,
        },
      };

    case actions.SET_CLEANERS:
      return {
        ...state,
        activeCleaners: {
          total: action.payload.total,
          loading: false,
          data: action.payload.cleaner,
        },
      };

    case actions.SET_LEAD_CLEANERS:
      return {
        ...state,
        activeLeadCleaners: {
          total: action.payload.total,
          loading: false,
          data: action.payload.cleaner,
        },
      };

    case actions.SET_LEAD_CLIENTS:
      return {
        ...state,
        activeLeadClients: {
          total: action.payload.total,
          loading: false,
          data: action.payload.clients,
        },
      };

    case actions.SET_ALL_ACTIVITIES:
      return {
        ...state,
        allActivities: {
          loading: false,
          data: action.payload,
        },
      };

    case actions.SET_TASK_ACTIVITIES:
      return {
        ...state,
        taskActivities: {
          loading: false,
          data: action.payload,
        },
      };

    case actions.SET_BOOKED_CLIENTS_LEADS:
      additionalItemsForClientLeads[0].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        bookedClientsLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClientLeads,
        loading: false,
      };

    case actions.SET_FOLLOW_CLIENTS_LEADS:
      additionalItemsForClientLeads[1].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        followClientsLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClientLeads,
        loading: false,
      };

    case actions.SET_NEW_CLIENTS_LEADS:
      additionalItemsForClientLeads[2].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        newClientsLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClientLeads,
        loading: false,
      };

    case actions.SET_WAITING_CLIENTS_LEADS:
      additionalItemsForClientLeads[3].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        waitingClientsLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClientLeads,
        loading: false,
      };

    case actions.SET_BOOKED_CLEANERS_LEADS:
      additionalItemsForCleanersLeads[0].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        bookedCleanersLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleanersLeads,
        loading: false,
      };

    case actions.SET_FOLLOW_CLEANERS_LEADS:
      additionalItemsForCleanersLeads[1].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        followCleanersLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleanersLeads,
        loading: false,
      };

    case actions.SET_NEW_CLEANERS_LEADS:
      additionalItemsForCleanersLeads[2].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        newCleanersLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleanersLeads,
        loading: false,
      };

    case actions.SET_WAITING_CLEANERS_LEADS:
      additionalItemsForCleanersLeads[3].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        waitingCleanersLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleanersLeads,
        loading: false,
      };

    case actions.SET_LOST_LEADS:
      return {
        ...state,
        lostLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_ALL_LEADS:
      return {
        ...state,
        activeLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: makeFullArray(action.payload),
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_CLEANERS_LEADS:
      additionalItems[2].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        cleanersLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_CLIENTS_LEADS:
      additionalItems[1].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        clientsLeads: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_STATISTICS_TASKS:
      additionalItems[0].count = handleTaskDataResponse(action.payload);
      return {
        ...state,
        statisticsTasks: {
          total: handleTaskDataResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ALL_CLIENTS:
      commonItems[0].count = handleStatisticsResponse(action.payload);
      statisticsItems[0].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        activeClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: makeFullArray(action.payload),
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_STARTED_CLIENTS:
      additionalItemsForClient[0].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        startedClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClient,
        loading: false,
      };

    case actions.SET_PENDING_CLIENTS:
      additionalItemsForClient[1].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        pendingClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClient,
        loading: false,
      };

    case actions.SET_NO_CLEANER_CLIENTS:
      additionalItemsForClient[2].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        noCleanerClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForClient,
        loading: false,
      };

    case actions.SET_GOOGLE_SOURCE_CLIENTS:
      return {
        ...state,
        googleClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_LEAFLETS_SOURCE_CLIENTS:
      return {
        ...state,
        leafletsClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_MAGAZINES_SOURCE_CLIENTS:
      return {
        ...state,
        magazinsClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_FACEBOOK_SOURCE_CLIENTS:
      return {
        ...state,
        facebookClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_INSTAGRAM_SOURCE_CLIENTS:
      return {
        ...state,
        instagramClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_LINKEDIN_SOURCE_CLIENTS:
      return {
        ...state,
        linkedinClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_REFERRAL_SOURCE_CLIENTS:
      return {
        ...state,
        refferralClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_DIRECTORY_SOURCE_CLIENTS:
      return {
        ...state,
        directoryClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_OTHER_SOURCE_CLIENTS:
      return {
        ...state,
        otherClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
          lost30Days: checkForDays(action),
        },
        loading: false,
      };
    case actions.SET_CLEANING_HOURS_WEEKLY:
      commonItems[2].count = handleStatisticsResponse(action.payload);
      statisticsItems[2].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        totalHoursClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_AVERAGE_CLIENT_HOURS:
      commonItems[3].count = handleStatisticsResponse(action.payload);
      statisticsItems[6].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        averageHoursClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_AVERAGE_CLIENT_RETAINER:
      commonItems[4].count = handleStatisticsResponse(action.payload);
      statisticsItems[7].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        averagePaymentClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ESTIMATED_TURNOVER_ANNUM:
      commonItems[6].count = handleStatisticsResponse(action.payload);
      statisticsItems[3].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        estTurnoverClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_PENDING_TASKS:
      commonItems[7].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        pendingTasks: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ONE_OFF_CLIENTS:
      return {
        ...state,
        oneOffsClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_WEEKLY_VS_FORTNIGHTLY:
      commonItems[5].count = `${handleStatisticsResponse(action.payload)} %`;
      return {
        ...state,
        weeklyFortnightlyClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ACTIVE_WEEKLY_CLIENTS:
      return {
        ...state,
        activeWeeklyClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ACTIVE_FORTNIGHTLY_CLIENTS:
      return {
        ...state,
        activeFortnightlyClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_AVERAGE_BEDROOMS_CLIENTS:
      return {
        ...state,
        averageBedroomsClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_PERCENT_PETS_CLIENTS:
      return {
        ...state,
        prWithPetsClients: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_AVERAGE_TIME_CLIENTS_STAY:
      return {
        ...state,
        averageTimeClientsStay: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ACTIVE_CLEANERS:
      commonItems[1].count = handleStatisticsResponse(action.payload);
      statisticsItems[1].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        activeCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: makeFullArray(action.payload),
          lost30Days: checkForDays(action),
        },
        loading: false,
      };

    case actions.SET_ALLOCATED_CLEANERS:
      additionalItemsForCleaners[0].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        startedCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleaners,
        loading: false,
      };

    case actions.SET_PENDING_CLEANERS:
      additionalItemsForCleaners[1].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        pendingCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleaners,
        loading: false,
      };

    case actions.SET_VACANT_CLEANERS:
      additionalItemsForCleaners[2].count = handleStatisticsResponse(
        action.payload
      );
      return {
        ...state,
        noJobCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        additionalItemsForCleaners,
        loading: false,
      };

    case actions.SET_AVERAGE_JOBS_CLEANERS:
      return {
        ...state,
        averageJobsCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_AVERAGE_JOBS_HOURS_CLEANERS:
      return {
        ...state,
        averageJobsHoursCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_PERCENT_SELF_EMPLOYMENT_CLEANERS:
      return {
        ...state,
        percentSelfEmploymentCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_PERCENT_POLICE_CHECK_CLEANERS:
      return {
        ...state,
        percentPoliceCheckCleaners: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_PENDING_FRANCHISEES:
      statisticsItems[5].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        pendingFranchisees: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_ACTIVE_FRANCHISEES:
      statisticsItems[4].count = handleStatisticsResponse(action.payload);
      return {
        ...state,
        activeFranchisees: {
          total: handleStatisticsResponse(action.payload),
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.SET_REPORT:
      return {
        ...state,
        report: {
          loading: false,
          data: action.payload,
        },
        loading: false,
      };

    case actions.LOADING:
      const data = { ...state[action.payload] };
      data.loading = true;
      return {
        ...state,
        [action.payload]: data,
      };

    default:
      return state;
  }
}

export const handleStatisticsResponse = (item) => {
  if (item.status === "SUCCESS") {
    if (item.response.length > 0) {
      return Number(item.response.slice(-1)[0].count.toFixed(2));
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

function handleTaskDataResponse(item) {
  if (item.status === "SUCCESS" && item.response) {
    return item.response.allTasks + "/" + item.response.overDueTasks;
  } else {
    return 0;
  }
}

function checkForDays(item) {
  let data;
  if (item.payload.response.length !== 0) {
    // if (item.payload.response[0].count === 1 || item.payload.response.length < 29) {
    //     data = item.payload.response[item.payload.response.length - 1].count;
    // }
    // else if (item.payload.response[item.payload.response.length - 1].count === item.payload.response[0]?.count) {
    //     data = item.payload.response[item.payload.response.length - 1].count;
    // }
    data =
      item.payload.response[item.payload.response.length - 1].count -
      (item.payload.response[0]?.count || 0);
    if (data < 0) {
      return 0;
    } else {
      return data;
    }
  } else {
    return 0;
  }
}

function makeFullArray(item) {
  if (item?.response.length < 10) {
    for (let i = item?.response.length; i < 10; i++) {
      const day = new Date(item.response[0]?.date || new Date());
      const nextDay = new Date(day);
      nextDay.setDate(day.getDate() - 3);
      item.response.unshift({
        count: 0,
        date: nextDay.toISOString().split("T")[0],
      });
    }
    return item.response?.map((item) => ({ ...item, count: item.count - 0 }));
  } else {
    return item.response;
  }
}
