import actions from './actions';
import authActions from '../auth/actions';
import { UserMapper } from '../../mappers/user.mapper';
import userAvatar from '@iso/assets/images/blank-avatar.png';
import { UserRolesConductor } from '../../conductors/userRoles.conductor';
import { userConstants } from './userConstants';
const initState = {
    areas: []
};

export default function userReducer(state = initState, action) {
    switch (action.type) {
    case authActions.LOGOUT:
        return initState;
    case actions.SET_USER:
        const user = action.payload.user;

        const coords = action.payload.franchise_details;
        if (!user.icon_id) {
            user.icon_id = userAvatar;
        }

        UserRolesConductor.initConfig(user.role);

        let registrationPlaceId =  1;

        if (UserRolesConductor.config.isSuperAdmin || UserRolesConductor.config.isManager) {
            registrationPlaceId = action.payload.user.loginRegistrationPlace;
        } else {
            registrationPlaceId = action.payload.user.user_register_area;
        }

        const registrationPlace = userConstants.registeredInOptions.find(el => el.value === registrationPlaceId)?.name;

        return {
            ...state,
            user,
            userDetails: UserMapper[UserRolesConductor.config.userDetailsMapper](action.payload),
            registrationPlaceId,
            registrationPlace,
            lat: coords?.lat || 0,
            lng: coords?.lng || 0,
            franchiseId: action.payload.franchiseId
        };
    case actions.SET_USER_AREAS:
        return { ...state, areas: action.payload };

    case actions.ADD_USER_AREAS:
        return { ...state, areas: [ ...action.payload, ...state.areas ] };

    case actions.EDIT_USER_AREAS:

        action.payload.forEach(area => {
            const targetIndex = state.areas.findIndex(el => el.id === area.id);
            if (targetIndex !== -1) {
                state.areas[targetIndex] = area;
            }
        });

        return { ...state, areas: [...state.areas] };

    case actions.DELETE_USER_AREA:
        return { ...state, areas: [...state.areas].filter(el => el.id !== action.payload) };
    default:
        return state;
    }
}
