export const userConstants = {
	 registeredInOptions: [
        { label: 'england.wales', value: 1, name: 'UK' },
        { label: 'scotland', value: 2, name: 'UK' },
        { label: 'northen.ireland', value: 3, name: 'UK' },
        { label: 'ireland', value: 4, name: 'IE' },
        { label: 'norway', value: 5, name: 'NO' },
        { label: 'australia', value: 6, name: 'AU' },
    ],
    companyTypes: [
        { label: 'sole.t', value: '2', constant: 'SOLETRADER' },
        { label: 'partnership', value: '1', constant: 'PARTNERSHIP' },
        { label: 'ltd.comp', value: '0', constant: 'LTD_COMPANY' },
    ],
    emailUserTypes: {
        SUPER_ADMIN: 0,
        MANAGER: 1,
        FRANCHISEE: 2,
        SIGNATORY: 3,
        CLIENT: 4,
        CLEANER: 5,
    }
};
