import { message } from 'antd';
import { FileAttachmentService } from '../../api/fileAttachmentApiService';

const DOCUMENT = 'FILE_ATTACHMENT_';

const actions = {
    FETCH_USER_FILES: DOCUMENT + 'FETCH_USER_FILES',
    CHANGE_FILE_NAME: DOCUMENT + 'CHANGE_FILE_NAME',
    ON_UPLOAD_FILE: DOCUMENT + 'ON_UPLOAD_FILE',
    ON_FAIL_UPLOAD_FILE: DOCUMENT + 'ON_FAIL_UPLOAD_FILE',
    ON_REMOVE_FILE: DOCUMENT + 'ON_REMOVE_FILE',
    BEFORE_UPLOAD_FILE: DOCUMENT + 'BEFORE_UPLOAD_FILE',
    SET_UPLOADING: DOCUMENT + 'SET_UPLOADING',

    onChangeFileName: (data) => {
        return {
            type: actions.CHANGE_FILE_NAME,
            payload: data
        };
    },

    setUploading: (val) => {
        return {
            type: actions.SET_UPLOADING,
            payload: val
        };
    },

    fetchAllFiles: (params = {}) => {
        return async dispatch => {
            const str_params = new URLSearchParams(params).toString();
            FileAttachmentService.fetchAllUserFiles(str_params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_USER_FILES,
                        payload: data.response.list
                    });
                });
        };
    },

    onHandleUpload: (fileList, params = {}) => {
        return async dispatch => {
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('file', file);
            });

            const str_params = new URLSearchParams(params).toString();

            dispatch(actions.setUploading(true));

            await FileAttachmentService.uploadFile(str_params, formData)
                .then(response => {
                    if (response.status !== 'FAILURE') {
                        dispatch({
                            type: actions.ON_UPLOAD_FILE,
                            payload: {
                                fileList: [],
                                uploading: false,
                            }
                        });
                        dispatch(actions.fetchAllFiles({
                            emailUserTypeId: params.emailUserTypeId,
                            userId: params.userId
                        }));
                        dispatch(actions.onChangeFileName(''));
                        message.success('Upload successfully');
                    }
                    else {
                        dispatch({
                            type: actions.ON_FAIL_UPLOAD_FILE,
                            payload: false
                        });
                        message.error('Upload failed');
                    }
                });
        };
    },

    onFileRemove: (file, fileList) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        return {
            type: actions.ON_REMOVE_FILE,
            payload: newFileList
        };
    },

    beforeFileUpload: (file) => {
        return {
            type: actions.BEFORE_UPLOAD_FILE,
            payload: file
        };
    },

    deleteFile: (params = {}, fetch_params = {}) => {
        return async dispatch => {
            const str_params = new URLSearchParams(params).toString();
            await FileAttachmentService.deleteFile(str_params)
                .then(data => {
                    dispatch(actions.fetchAllFiles(fetch_params));
                    message.success('Deleted successfully');
                });
        };
    },

};
export default actions;
