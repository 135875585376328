import HttpClient from './http.api';

export class TermsApiService {

    static getTermsConditions(data) {
        return HttpClient.post({ url: 'api/v1/termcondition', data });
    }

    static getTermsService(data) {
        return HttpClient.post({ url: 'api/v1/termcondition/service/', data });
    }

    static updateTermsCondition(data) {
        return HttpClient.put({ url: 'api/v1/termcondition/update', data });
    }

    static updateTermsService(data) {
        return HttpClient.put({ url: 'api/v1/termcondition/service/update', data });
    }

    static getSystemTemplateList(data) {
        return HttpClient.post({ url: 'api/v1/web/systemtemplate/list', data });
    }

    static getEditedTemplate(id) {
        return HttpClient.get({ url: `api/v1/web/systemtemplate/getContent?id=${id}` });
    }

    static updateTemplate(data) {
        return HttpClient.put({ url: 'api/v1/web/systemtemplate/update', data });
    }
}
