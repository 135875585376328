import { message } from 'antd';
import { UserApiService } from '../../api/userApiService';
import { AuthApiService } from '../../api/authApiService';
import { AdminApiService } from '../../api/adminApiService';
import { setUsersTokens, userRoles } from '../../components/Helper/setUsersTokens';

const actions = {
    SET_USER: 'SET_USER',
    SET_USER_AREAS: 'SET_USER_AREAS',
    ADD_USER_AREAS: 'ADD_USER_AREAS',
    EDIT_USER_AREAS: 'EDIT_USER_AREAS',
    DELETE_USER_AREA: 'DELETE_USER_AREA',


    getUser: (id = null) => {
        return async dispatch => {
            await UserApiService.userInfo(id)
                .then(data => {
                    if (!data.user) {
                        message.error(data.msg);
                    } else {
                        dispatch({
                            type: actions.SET_USER,
                            payload: data,
                        });
                    }
                });
        };
    },

    changeAvatar: (data) => {
        return async dispatch => {
            await UserApiService.updateAvatar(data)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        dispatch(actions.getUser());
                        message.success('Profile avatar updated successfully');
                    } else {
                        message.error(data.msg);
                    }
                });
        };
    },

    editProfile: (data, targetMethod) => {
        return async dispatch => {
            await UserApiService[targetMethod](data)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        dispatch(actions.getUser());
                        message.success('Profile data updated successfully');
                    } else {
                        message.error(data.msg);
                    }
                });
        };
    },

    changePass: (oldPass, newPass) => {
        return async dispatch => {
            return new Promise(async (resolve) => {
                const passChange = await AuthApiService.changePassword({ oldPass, newPass });
                if (passChange.status === 'SUCCESS') {
                    resolve();
                    message.success('Password changed successfully');
                } else {
                    message.error('Password wasn\'t changed, please make sure you entered correct values');
                }
            });
        };
    },

    getUserAreas: () => {
        return async dispatch => {
            await UserApiService.userAreas()
                .then(data => {
                    if (data.errorcode === 0) {
                        dispatch({
                            type: actions.SET_USER_AREAS,
                            payload: data.areas,
                        });
                    } else {
                        message.error(data.msg);
                    }
                });
        };
    },

    editUserAreas: (data, franchiseId) => {
        const _data = data.map(el => ({ areaCode: el.areaCode, areaName: el.areaName, areaId: el.id, franchiseId }));
        return async dispatch => {
            await UserApiService.editUserAreas(_data)
                .then(data => {
                    if (data.errorcode === 0) {
                        dispatch({
                            type: actions.EDIT_USER_AREAS,
                            payload: data.areas,
                        });
                        message.success('Areas were edited successfully');
                    } else {
                        message.error(data.msg);
                    }
                });
        };
    },

    addUserAreas: (data, franchiseId) => {
        const _data = data.map(el => ({ ...el, franchiseId }));
        return async dispatch => {
            await UserApiService.addUserAreas(_data)
                .then(data => {
                    if (data.errorcode === 0) {
                        dispatch({
                            type: actions.ADD_USER_AREAS,
                            payload: data.areas,
                        });
                        message.success('Areas were added successfully');
                    } else {
                        message.error(data.msg);
                    }
                });
        };
    },

    deleteUserArea: (areaId) => {
        return async dispatch => {
            await UserApiService.deleteUserArea(areaId)
                .then(data => {
                    if (data.errorcode === 0) {
                        dispatch({
                            type: actions.DELETE_USER_AREA,
                            payload: areaId,
                        });
                    } else {
                        message.error(data.msg);
                    }
                });
        };
    },

    relogin: (email, removePrevKeys = false, cb = () => {}) => {
        return async (dispatch, getState) => {
            const { role } = getState().User.user;

            await AdminApiService.relogin({ email })
                .then((data) => {
                    if (data.msg === 'Inactive profile') {
                        message.warning(data.msg);
                    } else {
                        if (!localStorage.getItem('start_id_token')) {
                            localStorage.setItem('start_id_token', localStorage.getItem('id_token'));
                            localStorage.setItem('start_user_id', localStorage.getItem('user_id'));
                        }
                        setUsersTokens(role,  data, removePrevKeys);
                        dispatch(actions.getUser(data.userId ? data.userId : localStorage.getItem('user_id')));
                        cb();
                    }
                });
        };
    },

    backToPrevAccount: () => {
        return async dispatch => {   
            const prevUserId = localStorage.getItem(userRoles.franchise.idKey) || localStorage.getItem(userRoles.manager.idKey);
            await UserApiService.userInfo(prevUserId)
                .then(data => {
                    dispatch(actions.relogin(data.user.email, true));
                });
        };
    }
};
export default actions;
