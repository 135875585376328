import actions from './actions';

const initState = {
    zapierToken: null
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {
        case actions.GET_ZAPIER_TOKEN:
            return {
                ...state,
                zapierToken: payload
            };
        case actions.CHANGE_ZAPIER_TOKEN:
            return {
                ...state,
                zapierToken: payload
            };
        default:
            return state;
    }
}
