import HttpClient from './http.api';

export class LeadApiService {

    static fetchAllLeads(data, type) {
        return HttpClient.post({ url: 'api/v1/leads/'+ type +'/', data });
    }

    static fetchSingleLead(id, type) {
        return HttpClient.get({ url: 'api/v1/leads/'+ type +'/' + id, });
    }

    static addNewSingleLead(data, type) {
        return HttpClient.post({ url: 'api/v1/leads/'+ type +'/add' + type, data });
    }

    static updateSingleLead(data, id, type) {
        return HttpClient.put({ url: 'api/v1/leads/'+ type +'/' + id, data });
    }

}
