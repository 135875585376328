import { TermsApiService } from '../../api/termsApiService';

const actions = {
    GET_TERMS_CONDITION: 'GET_TERMS_CONDITION',
    GET_TERMS_SERVICE: 'GET_TERMS_SERVICE',
    GET_SYSTEM_TEMPLATE_LIST: 'GET_SYSTEM_TEMPLATE_LIST',
    GET_EDITED_TEMPLATE: 'GET_EDITED_TEMPLATE',
    CLEAR_TEMPLATE: 'CLEAR_TEMPLATE',
    IS_LOADING: 'IS_LOADING',
    HANDLE_PAGINATION_CHANGE: 'HANDLE_PAGINATION_CHANGE',

    getTermsConditions: (data = null) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await TermsApiService.getTermsConditions(data)
                .then(data => {
                    dispatch({
                        type: actions.GET_TERMS_CONDITION,
                        payload: data.termscondition,
                    });
                });
            dispatch(actions.isloading(false));
        };
    },

    getTermsService: (data = null) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await TermsApiService.getTermsService(data)
                .then(data => {
                    dispatch({
                        type: actions.GET_TERMS_SERVICE,
                        payload: data.termscondition,
                    });
                });
            dispatch(actions.isloading(false));
        };
    },

    updateTerms: (data = null, type) => {
        return async () => {
            if (type === 'condition') {
                await TermsApiService.updateTermsCondition(data);
            } else {
                await TermsApiService.updateTermsService(data);
            }
        };
    },

    getSystemTemplateList: (data = null) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await TermsApiService.getSystemTemplateList(data)
                .then(data => {
                    dispatch({
                        type: actions.GET_SYSTEM_TEMPLATE_LIST,
                        payload: data.response,
                    });
                });
            dispatch(actions.isloading(false));
        };
    },

    getEditedTemplate: (id = null) => {
        return async dispatch => {
            await TermsApiService.getEditedTemplate(id)
                .then(data => {
                    dispatch({
                        type: actions.GET_EDITED_TEMPLATE,
                        payload: data.response,
                    });
                });
        };
    },

    updateTemplate: (data = null) => {
        return async dispatch => {
            dispatch(actions.isloading(true));
            await TermsApiService.updateTemplate(data)
                .then(() => {
                    dispatch(actions.isloading(false));
                });
			
        };
    },

    clearTemplate: () => {
        return dispatch => {
            dispatch({
                type: actions.CLEAR_TEMPLATE,
                payload: '',
            });
        };
    },

    isloading: (data) => {
        return {
            type: actions.IS_LOADING,
            payload: data
        };
    },

    handlePaginationChange: (page) => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: page
        };
    },
};
export default actions;
