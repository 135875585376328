import actions from './actions';

const initState = {
    current_step: 0,
    company_type: '2',
    agree_to_terms: false,
    is_vat_number: false,
    redirect_to_login: false,
    franchise_data: {
        address2: '',
        companyName: '',
        companyNumber: '',
        county: '',
        email: '',
        legalType: 0,
        mobile: '',
        password: '',
        postalCode: '',
        registrationPlace: 0,
        street: '',
        town: '',
        userFirstName: '',
        userLastName: '',
        secondaryOwnerFirstName: '',
        secondaryOwnerLastName: '',
        vatNumber: 0,
    },
};

export default function signUpReducer(state = initState, action) {
    switch (action.type) {
    case actions.SET_CURRENT_STEP:
        return {
            ...state,
            current_step: action.payload,
        };
    case actions.SET_COMPANY_TYPE:
        return {
            ...state,
            company_type: action.payload,
        };
    case actions.SET_AGREEMENT_STATUS:
        return {
            ...state,
            agree_to_terms: action.payload,
        };
    case actions.SET_VAT_NUMBER_STATUS:
        return {
            ...state,
            is_vat_number: action.payload,
        };
    case actions.SET_FRANCHISE_DATA:
        return {
            ...state,
            franchise_data: {
                ...action.payload
            },
        };
    case actions.SET_REDIRECT_TO_LOGIN:
        return {
            ...state,
            redirect_to_login: action.payload,
        };
    default: return state;
    }
}
