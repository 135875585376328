import { message } from 'antd';
import { AuthApiService } from '../../api/authApiService';

const DOCUMENT = 'FORGOT_PASS_';

const actions = {
    SET_REDIRECT: DOCUMENT + 'SET_REDIRECT',

    sendEmail: (params = {}) => {
        return async dispatch => {
            await AuthApiService.sendEmail(params)
                .then(data => {
                    if (data.msg === 'User with this email not found') {
                        message.error('User with this email not found');
                    }
                    else {
                        dispatch({
                            type: actions.SET_REDIRECT,
                            payload: true,
                        });
                        message.success('Email send successfully!');
                    }
                });
        };
    },

};
export default actions;
