const userRoles = {
	superAdmin: { title : 'ROLE_SUPER_ADMIN', tokenKey: 'sa_token', idKey: 'sa_id' },
	manager: { title : 'ROLE_MANAGER', tokenKey: 'manager_token', idKey: 'manager_id' },
	franchise: { title : 'ROLE_FRANCHISE', tokenKey: 'franchise_token', idKey: 'franchise_id' },
	signatory: { title : 'ROLE_SIGNATORY', tokenKey: 'signatory_token', idKey: 'signatory_id' }
};

const setUsersTokens = (prevRole, data, removePrevKeys) => {
    if (localStorage.getItem('id_token') && !removePrevKeys) {
        switch (data.userType) {
            case userRoles.superAdmin.title:
                localStorage.setItem(userRoles.superAdmin.tokenKey, data.token);
                localStorage.setItem(userRoles.superAdmin.idKey, data.userId);
                break;
            case userRoles.manager.title:
                localStorage.setItem(userRoles.manager.tokenKey, data.token);
                localStorage.setItem(userRoles.manager.idKey, data.userId);
                break;
            case userRoles.franchise.title:
                localStorage.setItem(userRoles.franchise.tokenKey, data.token);
                localStorage.setItem(userRoles.franchise.idKey, data.userId);
                break;
            case userRoles.signatory.title:
                localStorage.setItem(userRoles.signatory.tokenKey, data.token);
                localStorage.setItem(userRoles.signatory.idKey, data.userId);
                break;
            default:
                break;
        }
        localStorage['id_token'] = data.token;
        localStorage['user_id'] = data.userId;
    } else {
        let prevUser = null;
        Object.keys(userRoles).forEach(el => {
            if (userRoles[el].title === prevRole) {
                prevUser = userRoles[el];
            }
        });

        if (prevUser.title) {
            localStorage.removeItem(prevUser.tokenKey);
            localStorage.removeItem(prevUser.idKey);
            localStorage['id_token'] = (
                localStorage.getItem(userRoles.signatory.tokenKey) || 
                localStorage.getItem(userRoles.franchise.tokenKey) ||  
                localStorage.getItem(userRoles.manager.tokenKey) ||  
                localStorage.getItem(userRoles.superAdmin.tokenKey) ||
                localStorage.getItem('start_id_token'));
            localStorage['user_id'] = (
                localStorage.getItem(userRoles.signatory.idKey) || 
                localStorage.getItem(userRoles.franchise.idKey) ||  
                localStorage.getItem(userRoles.manager.idKey) ||  
                localStorage.getItem(userRoles.superAdmin.idKey) ||
                localStorage.getItem('start_user_id'));
        }

        if (localStorage.getItem('start_id_token') === localStorage.getItem('id_token')) {
            localStorage.removeItem('start_id_token');
            localStorage.removeItem('start_user_id');
            localStorage.removeItem('prev_user_id');
        }
    }
};


export {
    setUsersTokens,
    userRoles
};
