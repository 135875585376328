import { message } from 'antd';
import { CleanerTaskService } from '../../api/cleanerTaskService';
import { CleanersApiService } from '../../api/cleanersApiService';
import { searchData } from '../statistics/actions';

const DOCUMENT = 'CLEANER_';

const actions = {
    SELECT_ROW: DOCUMENT + 'SELECT_ROW',
    ON_SELECT_ID_CHANGE: DOCUMENT + 'ON_SELECT_ID_CHANGE',
    JOBS_CURRENT_PAGE: DOCUMENT + 'JOBS_CURRENT_PAGE',
    TASKS_CURRENT_PAGE: DOCUMENT + 'TASKS_CURRENT_PAGE',
    FILTER_JOBS: DOCUMENT + 'FILTER_JOBS',
    SET_FILTER: DOCUMENT + 'SET_FILTER',
    TERMINATE_ACCOUNT: DOCUMENT + 'TERMINATE_ACCOUNT',
    FETCH_ALL_CLEANERS: DOCUMENT + 'FETCH_ALL_CLEANERS',
    FETCH_ALL_CLEANERS_FOR_MAP: DOCUMENT + 'FETCH_ALL_CLEANERS_FOR_MAP',
    GET_CLEANER: DOCUMENT + 'GET_CLEANER',
    LOADING_CLEANER: DOCUMENT + 'LOADING_CLEANER',
    LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
    LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
    HANDLE_PAGINATION_CHANGE: DOCUMENT + 'HANDLE_PAGINATION_CHANGE',
    HANDLE_PAGE_SIZE_CHANGE: DOCUMENT + 'HANDLE_PAGE_SIZE_CHANGE',
    ON_CHANGE_CONTACT: DOCUMENT + 'ON_CHANGE_CONTACT',
    ON_CHANGE_STATUS: DOCUMENT + 'ON_CHANGE_STATUS',
    EDIT_CLEANER_TASK: DOCUMENT + 'EDIT_CLEANER_TASK',
    DELETE_CLEANER_TASK: DOCUMENT + 'DELETE_CLEANER_TASK',
    ADD_CLEANER_TASK: DOCUMENT + 'ADD_CLEANER_TASK',
    FETCH_CLEANER_TASKS: DOCUMENT + 'FETCH_CLEANER_TASKS',
    RESET_CLEANERS_LIST: DOCUMENT + 'RESET_CLEANERS_LIST',
    CLEANERS_SEARCH_VALUE: DOCUMENT + 'SEARCH_VALUE',

    searchCleaners: (data) => ({
        type: actions.CLEANERS_SEARCH_VALUE,
        payload: data ,
    }),

    selectCleanerRow: (data) => ({
        type: actions.SELECT_ROW,
        payload: { data },
    }),

    onSelectCleanersChange:(selectedRowKeys) => {
        return {
            type: actions.ON_SELECT_ID_CHANGE,
            payload: selectedRowKeys
        };
    },

    setJobsCurrentPage: (data) => ({
        type: actions.JOBS_CURRENT_PAGE,
        payload: { data },
    }),

    setTasksCurrentPage: (data, changing_field) => ({
        type: actions.TASKS_CURRENT_PAGE,
        payload: { data, changing_field }
    }),

    setFilter: (data, changing_field) => ({
        type: actions.SET_FILTER,
        payload: { data, changing_field }
    }),

    terminateAccount: (data) => ({
        type: actions.TERMINATE_ACCOUNT,
        payload: { data }
    }),

    loadingTrue:() => {
        return {
            type: actions.LOADING_TRUE,
            payload: true
        };
    },

    loadingFalse:() => {
        return {
            type: actions.LOADING_FALSE,
            payload: false
        };
    },

    handlePaginationChange: (page, pageSize) => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: { page, pageSize }
        };
    },

    resetPagination: () => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: { page: 1, pageSize: 10 }
        };
    },

    handlePageSizeChange: (value) => {
        return {
            type: actions.HANDLE_PAGE_SIZE_CHANGE,
            payload: value
        };
    },

    onChangeContactFilterButton:(e) => {
        return {
            type: actions.ON_CHANGE_CONTACT,
            payload: e.target.value
        };
    },

    onChangeStatusFilterButton:(value) => {
        return {
            type: actions.ON_CHANGE_STATUS,
            payload: value
        };
    },

    getCleanerById: (id, cb = () => {}) => {
        return async dispatch => {

            dispatch({
                type: actions.LOADING_CLEANER,
                payload: true
            });

            CleanersApiService.fetchCleaner(id)
                .then(data => {
                    cb(data);
                    dispatch({
                        type: actions.GET_CLEANER,
                        payload: data
                    });
                });
        };
    },

    fetchAllCleaners: (params = null, fecthMapData = false) => {
        return async (dispatch, getState) => {

            dispatch(actions.loadingTrue());

            const cleanersState = getState()?.Cleaners;

            const fetchParams = searchData({
                start: cleanersState.pagination.current,
                limit: cleanersState.pagination.pageSize,
                getState,
                searchByActiveStatus: cleanersState.contactValue,
            });

            if (params) {
                Object.keys(params).forEach(key => {
                    fetchParams[key] = params[key];
                });
            }

            if (params && Object.prototype.hasOwnProperty.call(params, 'searchByStatus')) {
                fetchParams['searchByStatus'] = params.searchByStatus === 'null' ? null : params.searchByStatus;
            } else {
                fetchParams['searchByStatus'] = cleanersState.statusValue === 'null' ? null : cleanersState.statusValue;
            }

            fetchParams['search'] = cleanersState.searchCleanersValue || null;

            const cleaners = await CleanersApiService.fetchAllCleaners(fetchParams);

            if (fecthMapData) {
                const mapFetchParams = {
                    ...fetchParams,
                    start: 0,
                    limit: Number(cleaners.total)
                };

                const cleanersForMap = await CleanersApiService.fetchAllCleaners(mapFetchParams);

                dispatch({
                    type: actions.FETCH_ALL_CLEANERS_FOR_MAP,
                    payload: cleanersForMap
                });
            }


            dispatch({
                type: actions.FETCH_ALL_CLEANERS,
                payload: cleaners
            });

            dispatch(actions.loadingFalse());

        };
    },

    fetchAllCleanersInJobs: (params = null, fecthMapData = false) => {
        return async (dispatch, getState) => {

            dispatch(actions.loadingTrue());

            const cleanersState = getState()?.Cleaners;

            const fetchParams = searchData({
                start: cleanersState.pagination.current,
                limit: cleanersState.pagination.pageSize,
                getState,
                searchByActiveStatus: cleanersState.contactValue,
            });

            if (params) {
                Object.keys(params).forEach(key => {
                    fetchParams[key] = params[key];
                });
            }

            if (params && Object.prototype.hasOwnProperty.call(params, 'searchByStatus')) {
                fetchParams['searchByStatus'] = params.searchByStatus === 'null' ? null : params.searchByStatus;
            } else {
                fetchParams['searchByStatus'] = cleanersState.statusValue === 'null' ? null : cleanersState.statusValue;
            }

            fetchParams['search'] = cleanersState.searchCleanersValue || null;

            const cleaners = await CleanersApiService.fetchAllCleanersInJobs(fetchParams);

            if (fecthMapData) {
                const mapFetchParams = {
                    ...fetchParams,
                    start: 0,
                    limit: Number(cleaners.total)
                };

                const cleanersForMap = await CleanersApiService.fetchAllCleanersInJobs(mapFetchParams);

                dispatch({
                    type: actions.FETCH_ALL_CLEANERS_FOR_MAP,
                    payload: cleanersForMap
                });
            }


            dispatch({
                type: actions.FETCH_ALL_CLEANERS,
                payload: cleaners
            });

            dispatch(actions.loadingFalse());

        };
    },

    deleteCleaners: (ids) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            CleanersApiService.deleteCleaners(ids)
                .then(data => {
                    dispatch(actions.fetchAllCleaners());
                    message.success('Cleaner(s) deleted successfully!');
                });
            dispatch(actions.loadingFalse());
        };
    },

    addCleaner: (data, cb = () => {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            CleanersApiService.addCleaner(data)
                .then(data => {
                    if (data.cleaner_id !== '-1') {
                        message.success('Cleaner created successfully!');
                        cb(data.cleaner_id);
                    } else {
                        message.error(data.msg);
                    }
                    dispatch(actions.loadingFalse());
                })
                .catch(_ => {
                    dispatch(actions.loadingFalse());
                });
        };
    },

    updateCleaner: (cleanerId, data, cb, updJobs) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            CleanersApiService.updateCleaner(cleanerId, data)
                .then(data => {

                    if (data.cleaner_id !== '-1' && data.cleaner_id !== 'null' && data.cleaner_id !== null) {
                        message.success('Cleaner updated successfully!');
                        cb(data.cleaner_id);
                        updJobs && updJobs();
                    } else {
                        message.error(data.msg);
                    }
                    dispatch(actions.loadingFalse());
                })
                .catch(_ => {
                    dispatch(actions.loadingFalse());
                });
        };
    },

    fetchCleanerTasks:(params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await CleanerTaskService.fetchCleanerTasks(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_CLEANER_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    addCleanerTask: (data) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await CleanerTaskService.addCleanerTask(data)
                .then(data => {
                    message.success('Task created successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    editCleanerTask: (params = {}, id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await CleanerTaskService.editCleanerTask(params, id)
                .then(data => {
                    message.success('Task updated successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    deleteCleanerTask: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await CleanerTaskService.deleteCleanerTask(params)
                .then(data => {
                    message.success('Task deleted successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    changeCleanerTaskStatus: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await CleanerTaskService.changeCleanerTaskStatus(params)
                .then(data => {
                    message.success('Status changed successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    resetCleanersList: (data) => ({
        type: actions.RESET_CLEANERS_LIST,
        payload: data,
    }),
};
export default actions;
