import HttpClient from './http.api';

export class AuthApiService {

    static login(data) {
        return HttpClient.post({ url: 'api/v1/web/authorization/login', data, token: false });
    }

    static resetPassword(data) {
        return HttpClient.put({ url: 'api/v1/web/authorization/changePassForManager', data, token: false });
    }

    static changePassword(data) {
        return HttpClient.put({ url: '/api/v1/web/authorization/changePass', data });
    }

    static sendEmail(data) {
        return HttpClient.post({ url: 'api/v1/authorization/forgotpassword', data });
    }

}
