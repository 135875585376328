import actions from './actions';

const initState = {
    termsCondition: '',
    termsService: '',
    isLoading: false,
    pagination: {
        current: 1,
        pageSize: 10, //default
        showSizeChanger: false,
        hideOnSinglePage: true
    },
    editedTemplate: '',
    systemTemplateList: []
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
    case actions.GET_TERMS_CONDITION:
        return {
            ...state,
            termsCondition: action.payload
        };
    case actions.GET_TERMS_SERVICE:
        return {
            ...state,
            termsService: action.payload
        };
    case actions.GET_SYSTEM_TEMPLATE_LIST:
        // eslint-disable-next-line no-case-declarations
        const systemTemplateList = action.payload.list.map(el => {
            return { id: el.id , status: el.status, title: el.title };
        });
		
        return {
            ...state,
            systemTemplateList,
            pagination: {
                ...state.pagination,
                total: action.payload.total
            },
        };	
    case actions.GET_EDITED_TEMPLATE:	
        return {
            ...state,
            editedTemplate: {
                id: action.payload.id,
                status: action.payload.status,
                title: action.payload.title,
                templateContent: action.payload.systemTemplate
            } ,
        };	
    case actions.CLEAR_TEMPLATE: 
        return {
            ...state,
            editedTemplate: ''
        };
    case actions.IS_LOADING:
        return {
            ...state,
            isLoading: action.payload,
        };
    case actions.HANDLE_PAGINATION_CHANGE:
        return {
            ...state,
            pagination: {
                ...state.pagination,
                current: action.payload,
            }
        };
    default:
        return state;
    }
}
