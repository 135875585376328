import HttpClient from './http.api';

export class FileAttachmentService {
    static fetchAllUserFiles(data) {
        return HttpClient.get({ url: 'api/v1/web/attachment/list', data, type: 'string' });
    }

    static uploadFile(params, data) {
        return HttpClient.post({ url: 'api/v1/web/attachment/add?' + params, data, type: 'formData' });
    }

    static deleteFile(data) {
        return HttpClient.delete({ url: 'api/v1/web/attachment/delete?'+ data });
    }
}
