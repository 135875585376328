import actions from './actions';

const initState = {
    redirect_to_login: false,
};

export default function ResetPassReducer(state = initState, action) {
    switch (action.type) {
    case actions.SET_REDIRECT_TO_LOGIN:
        return {
            ...state,
            redirect_to_login: action.payload,
        };
    default:
        return state;
    }
}
