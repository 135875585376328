const DOCUMENT = 'SA_';

const actions = {
    SELECT_LOCALE: DOCUMENT + 'LOAD_FROM_FIRESTORE',

    setLocale: (data) => ({
        type: actions.SELECT_LOCALE,
        payload: { data }
    })
};

export default actions;
