import actions from './actions';

const initialState = {
    allManagers: [],
    loading: false,
    isVisible: false,
    pagination: {
        current: 1,
        pageSize: 10, // default 10
        showSizeChanger: false,
        hideOnSinglePage: true,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case actions.LOADING_TRUE:
        return {
            ...state,
            loading: action.payload,
        };
    case actions.LOADING_FALSE:
        return {
            ...state,
            loading: action.payload,
        };
    case actions.TOGGLE_MODAL:
        return {
            ...state,
            isVisible: action.payload,
        };
    case actions.FETCH_ALL_MANAGERS:
        return {
            ...state,
            allManagers: action.payload.response.list,
            pagination: {
                ...state.pagination,
                total: Number(action.payload.total)
            },
        };
    default: return state;
    }
}
