import { message } from 'antd';
import { ClientTaskService } from '../../api/clientTaskService';
import { ClientApiService } from '../../api/clientApiService';
import { searchData } from '../statistics/actions';
const DOCUMENT = 'CLIENT_';

const actions = {
    SELECT_ROW: DOCUMENT + 'SELECT_ROW',
    ON_SELECT_ID_CHANGE: DOCUMENT + 'ON_SELECT_ID_CHANGE',
    JOBS_CURRENT_PAGE: DOCUMENT + 'JOBS_CURRENT_PAGE',
    TASKS_CURRENT_PAGE: DOCUMENT + 'TASKS_CURRENT_PAGE',
    FILTER_JOBS: DOCUMENT + 'FILTER_JOBS',
    EDIT_JOB_DETAILS: DOCUMENT + 'EDIT_JOB_DETAILS',
    SET_FILTER: DOCUMENT + 'SET_FILTER',
    FETCH_ALL_CLIENTS: DOCUMENT + 'FETCH_ALL_CLIENTS',
    FETCH_ALL_CLIENTS_FOR_MAP: DOCUMENT + 'FETCH_ALL_CLIENTS_FOR_MAP',
    GET_CLIENT: DOCUMENT + 'GET_CLIENT',
    LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
    LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
    HANDLE_PAGINATION_CHANGE: DOCUMENT + 'HANDLE_PAGINATION_CHANGE',
    HANDLE_PAGE_SIZE_CHANGE: DOCUMENT + 'HANDLE_PAGE_SIZE_CHANGE',
    ON_CHANGE_CONTACT: DOCUMENT + 'ON_CHANGE_CONTACT',
    ON_CHANGE_STATUS: DOCUMENT + 'ON_CHANGE_STATUS',
    EDIT_CLIENT_TASK: DOCUMENT + 'EDIT_CLIENT_TASK',
    DELETE_CLIENT_TASK: DOCUMENT + 'DELETE_CLIENT_TASK',
    ADD_CLIENT_TASK: DOCUMENT + 'ADD_CLIENT_TASK',
    FETCH_CLIENT_TASKS: DOCUMENT + 'FETCH_CLIENT_TASKS',
    SET_CLIENT_DATA: DOCUMENT + 'SET_CLIENT_DATA',
    SET_CLIENT_ON_ADD_DATA: DOCUMENT + 'SET_CLIENT_ON_ADD_DATA',
    SET_CLIENT_ON_UPDATE_DATA: DOCUMENT + 'SET_CLIENT_ON_UPDATE_DATA',
    TOGGLE_MODAL: DOCUMENT + 'TOGGLE_MODAL',
    CHANGE_CURRENT_CLIENT_STEP: DOCUMENT + 'CHANGE_CURRENT_CLIENT_STEP',
    RESET_CLIENTS_LIST: DOCUMENT + 'RESET_CLIENTS_LIST',
    RESET_CLIENT_UPDATE_DATA: DOCUMENT + 'RESET_CLIENT_UPDATE_DATA',
    ON_CHANGE_DAY_TIME: DOCUMENT + 'ON_CHANGE_DAY_TIME',
    SHOULD_RESET_FORM_FIELDS: DOCUMENT + 'SHOULD_RESET_FORM_FIELDS',
    SET_CLIENT_ACCESS_TOKEN: DOCUMENT + 'SET_CLIENT_ACCESS_TOKEN',
    CLIENTS_SEARCH_VALUE: DOCUMENT + 'SEARCH_VALUE',


    searchClients: (data) => ({
        type: actions.CLIENTS_SEARCH_VALUE,
        payload: data ,
    }),

    selectClientRow: (data) => ({
        type: actions.SELECT_ROW,
        payload: { data },
    }),

    onSelectClientsChange: (selectedRowKeys) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        return {
            type: actions.ON_SELECT_ID_CHANGE,
            payload: selectedRowKeys
        };
    },

    setJobsCurrentPage: (data) => ({
        type: actions.JOBS_CURRENT_PAGE,
        payload: { data },
    }),

    setTasksCurrentPage: (data, changing_field) => ({
        type: actions.TASKS_CURRENT_PAGE,
        payload: { data, changing_field }
    }),

    setFilter: (data, changing_field) => ({
        type: actions.SET_FILTER,
        payload: { data, changing_field }
    }),

    editJobDetails: (data, changing_field) => ({
        type: actions.EDIT_JOB_DETAILS,
        payload: { data, changing_field }
    }),

    fetchClientTasks: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientTaskService.fetchClientTasks(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_CLIENT_TASKS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    addClientTask: (data) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientTaskService.addClientTask(data)
                .then(data => {
                    message.success('Task created successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    editClientTask: (params = {}, id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientTaskService.editClientTask(params, id)
                .then(data => {
                    message.success('Task updated successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    deleteClientTask: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientTaskService.deleteClientTask(params)
                .then(data => {
                    message.success('Task deleted successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    changeClientTaskStatus: (params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientTaskService.changeClientTaskStatus(params)
                .then(data => {
                    message.success('Status changed successfully');
                });
            dispatch(actions.loadingFalse());
        };
    },

    loadingTrue: () => {
        return {
            type: actions.LOADING_TRUE,
            payload: true
        };
    },

    loadingFalse: () => {
        return {
            type: actions.LOADING_FALSE,
            payload: false
        };
    },

    toggleModal: (val) => {
        return {
            type: actions.TOGGLE_MODAL,
            payload: val
        };
    },

    handlePaginationChange: (page, pageSize) => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: { page, pageSize }
        };
    },

    resetPagination: () => {
        return {
            type: actions.HANDLE_PAGINATION_CHANGE,
            payload: { page: 1, pageSize: 10 }
        };
    },

    handlePageSizeChange: (value) => {
        return {
            type: actions.HANDLE_PAGE_SIZE_CHANGE,
            payload: value
        };
    },

    onChangeContactFilterButton: (e) => {
        return {
            type: actions.ON_CHANGE_CONTACT,
            payload: e.target.value
        };
    },

    onChangeStatusFilterButton: (value) => {
        return {
            type: actions.ON_CHANGE_STATUS,
            payload: value
        };
    },

    onChangeDayTime: (value = {}) => {
        return {
            type: actions.ON_CHANGE_DAY_TIME,
            payload: value
        };
    },

    setClientData: (value) => {
        return {
            type: actions.SET_CLIENT_ON_UPDATE_DATA,
            payload: value
        };
    },

    setClientNewData: (value) => {
        return {
            type: actions.SET_CLIENT_ON_ADD_DATA,
            payload: value
        };
    },

    setClientCurrentStep: (current_add_client_step) => {
        return {
            type: actions.CHANGE_CURRENT_CLIENT_STEP,
            payload: current_add_client_step
        };
    },

    resetClientUpdateData: () => {
        return {
            type: actions.RESET_CLIENT_UPDATE_DATA,
            payload: null
        };
    },

    fetchAllClients: (params = 0, fetchMapData = false, filterOneOff) => {
        return async (dispatch, getState) => {
            dispatch(actions.loadingTrue());

            const clientsState = getState()?.Clients;

            const fetchParams = searchData({
                start: clientsState.pagination.current,
                limit: params.limit || clientsState.pagination.pageSize,
                getState,
                searchByActiveStatus: clientsState.contactValue,
                searchByStatus: clientsState.statusValue,
            });

            const targetParams = params || fetchParams;

            targetParams['search'] = params.search || clientsState.searchClientsValue || null;

            const data = await ClientApiService.fetchAllClients(targetParams);

            if (filterOneOff) {
                dispatch({
                    type: actions.FETCH_ALL_CLIENTS,
                    payload: { ...data,
                        clients : data.clients.filter(el => el.frequency !== 'One off')
                    }
                });
            } else {
                dispatch({
                    type: actions.FETCH_ALL_CLIENTS,
                    payload: data
                });
            }

            if (fetchMapData) {
                const mapFetchParams = {
                    ...fetchParams,
                    start: 0,
                    limit: Number(data.total)
                };

                const clientsForMap = await ClientApiService.fetchAllClients(mapFetchParams);

                dispatch({
                    type: actions.FETCH_ALL_CLIENTS_FOR_MAP,
                    payload: clientsForMap
                });
            }

            dispatch(actions.loadingFalse());
        };
    },

    fetchAllClientsInJobs: (params = 0, fetchMapData = false, filterOneOff) => {
        return async (dispatch, getState) => {
            dispatch(actions.loadingTrue());

            const clientsState = getState()?.Clients;

            const fetchParams = searchData({
                start: clientsState.pagination.current,
                limit: params.limit || clientsState.pagination.pageSize,
                getState,
                searchByActiveStatus: clientsState.contactValue,
                searchByStatus: clientsState.statusValue,
            });

            const targetParams = params || fetchParams;

            targetParams['search'] = params.search || clientsState.searchClientsValue || null;

            const data = await ClientApiService.fetchAllClientsInJobs(targetParams);

            if (filterOneOff) {
                dispatch({
                    type: actions.FETCH_ALL_CLIENTS,
                    payload: { ...data,
                        clients : data.clients.filter(el => el.frequency !== 'One off')
                    }
                });
            } else {
                dispatch({
                    type: actions.FETCH_ALL_CLIENTS,
                    payload: data
                });
            }

            if (fetchMapData) {
                const mapFetchParams = {
                    ...fetchParams,
                    start: 0,
                    limit: Number(data.total)
                };

                const clientsForMap = await ClientApiService.fetchAllClientsInJobs(mapFetchParams);

                dispatch({
                    type: actions.FETCH_ALL_CLIENTS_FOR_MAP,
                    payload: clientsForMap
                });
            }

            dispatch(actions.loadingFalse());
        };
    },

    fetchAllClientsForMap: (params = null) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientApiService.fetchAllClients(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_ALL_CLIENTS_FOR_MAP,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    fetchAllClientsInJobsForMap: (params = null) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientApiService.fetchAllClientsInJobs(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_ALL_CLIENTS_FOR_MAP,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    createClient: (params = {}, cb = () => {}, resetFormFields = () => {}) => {
        return async (dispatch, getState) => {
            dispatch(actions.loadingTrue());
            await ClientApiService.createClient(params)
                .then(data => {
                    if (data.errorcode === 0) {
                        cb(data);
                        dispatch(actions.fetchAllClients());
                        dispatch(actions.setClientCurrentStep(0));
                        dispatch(actions.shouldResetFormFields(true));
                        resetFormFields();
                        message.success('Client created successfully!');
                    }

                    if (data.errorcode === 1) {
                        message.error('Could not save client to database!');
                    }
                });
            dispatch(actions.loadingFalse());
        };
    },

    getClientById: (id) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientApiService.getClientById(id)
                .then(data => {
                    dispatch({
                        type: actions.GET_CLIENT,
                        payload: data
                    });
                    dispatch({
                        type: actions.SET_CLIENT_DATA,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    updateClientById: (id, params = {}, updJobs) => {
        return async (dispatch, getState) => {
            dispatch(actions.loadingTrue());

            if (!params.access_token) {

                const userState = getState()?.User;

                params.access_token = userState?.user.access_token;
                dispatch(actions.setClientAccessToken(params.access_token));
            }

            await ClientApiService.updateClientById(id, params)
                .then(data => {
                    if (data.errorcode === 0) {
                        dispatch(actions.toggleModal(false));
                        message.success('Client update successfully!');
                        dispatch(actions.getClientById(id));
                        updJobs && updJobs();
                    }
                    if (data.errorcode === 1) {
                        message.error('Client wasn\'t updated');
                        if (!params.client.preferences.days_preferred.length) {
                            message.error('Please, specify preferred Day/Time');
                        }
                    }
                });
            dispatch(actions.loadingFalse());
        };
    },

    resetClientsList: (data) => ({
        type: actions.RESET_CLIENTS_LIST,
        payload: data,
    }),

    deleteClientById: (ids_array, params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ClientApiService.deleteClientById(ids_array)
                .then(data => {
                    dispatch(actions.fetchAllClients(params));
                    message.success('Client(s) delete successfully!');
                });
            dispatch(actions.loadingFalse());
        };
    },

    shouldResetFormFields: (data) => {
        return {
            type: actions.SHOULD_RESET_FORM_FIELDS,
            payload: data
        };
    },

    setClientAccessToken: (token) => {
        return {
            type: actions.SET_CLIENT_ACCESS_TOKEN,
            payload: token
        };
    }
};
export default actions;
