import actions from './actions';

const initState = {
    chartData: []
};

export default function reducer(
    state = initState,
    { type, payload }
) {
    switch (type) {   
    case actions.GET_CHART_DATA:
        return {
            ...state,
            chartData: payload
        };  
    default:
        return state;
    }
}
