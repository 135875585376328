import { createGlobalStyle } from 'styled-components';
import { palette, font } from 'styled-theme';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`

  .required-label:before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
  }
  .ant-btn{
    border-radius: 4px;
  }

  .mb-10 {
      margin-bottom: 10px !important;
  }
  
  .ml-10 {
      margin-left: 10px !important;
  }
  
  .ant-form-item-explain {
    font-size: 11px !important;
  }

  .cut-text { 
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .select-job--dropdown {
    .ant-select-item {
      padding: 5px !important;
      font-size: 9px !important;
    }
  }

  .select-timeslot--dropdown {
    .ant-select-item {
        font-size: 12px !important;
      }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Montserrat';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/
  // style for spinner
  /*-----------------------------------------------*/

  .ant-spin-dot-item {
    background-color: ${palette('secondary', 11)} !important;
  }

  /*-----------------------------------------------*/
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;

      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
  .mobile_bug-report {
    width: 100% !important;
  }
   .mobile_bug-wrapper {
    flex-direction: column !important;
   }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.report-an-issue form label {
  width: 200px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 11px;
}

.notification-title {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.close-notification {
  position: absolute;
  right: 0;
  top: 0;
  width: 15px;
  height: 15px;
  opacity: 0.3;
  z-index: 1040;
}
.close-notification:hover {
  opacity: 1;
}
.close-notification:before, .close-notification:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #333;
}
.close-notification:before {
  transform: rotate(45deg);
}
.close-notification:after {
  transform: rotate(-45deg);
}
.notification-drawer .ant-drawer-body {
  padding: 30px 20px 0px;
}
.notification-drawer .ant-drawer-close {
  padding: 20px 15px;
}

.notification-search {
  padding-bottom: 25px;
  margin-top: -20px;
}

@media only screen and (min-width: 991px) {
   .client-view-content .no-desktop {
    display: none !important;
   }
  .todo_tasks-modal .ant-modal-header {
    padding: 20px 40px !important;
  }
  .todo_tasks-modal .ant-modal-body, .tasks-modal .ant-modal-body, .lead_tasks-modal .ant-modal-body {
    padding: 0 40px !important;
  }
  .todo_tasks-modal .task-notes .contact, .tasks-modal .task-notes .contact, .lead_tasks-modal .task-notes .contact {
    width: 69px !important;
  }
}

.search_type-info {
      font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #000000;
    padding: 9px 18px;
    background: rgba(94,129,244,0.2);
    border-radius: 5px;
    cursor: pointer;
    text-transform: capitalize;
}

.search_list-element {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
}

.task_modal-content .ant-form-item-control {
  flex: 1 1 auto !important;
}

.additional .ant-form-item-control {
  flex: 1 1 auto !important;
}

.additional .day-of-birth {
  align-items: baseline;
}

.task_modal-content .comments {
  margin-top: 0 !important;
}

.task_modal-content .ant-btn {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 20px;
  display: block;
}

.task_modal-content .ant-form-item-explain {
  display: none !important;
}

.report-an-issue form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  font-size: 9px;
  font-weight: 600;
}

.report-an-issue form input, .report-an-issue form textarea {
  background-color: inherit;
  font-size: 9px;
  font-weight: 600;
  padding: 10px;
}
.report-an-issue form .ant-picker {
  background-color: inherit;
  font-size: 9px;
  font-weight: 600;
  padding: 0 5px;
  width: 100%;
}

.report-an-issue form .anticon-info-circle {
  margin-right: 5px;
  background: rgba(94, 129, 244, 0.43);
  border-radius: 20px;
  color: white;
}

.report-an-issue form .upload_buttons {
  display: flex;
}

.report-an-issue form .screen-upload, .report-an-issue form .files-upload {
  width: 100%;
}

.report-an-issue form .screen-upload .ant-upload, .report-an-issue form .files-upload .ant-upload {
  width: 100%;
}

.report-an-issue form .upload_buttons span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  color: #63677E;
}

.report-an-issue form .ant-form-item {
  margin-bottom: 15px;
}

.report-an-issue form .select-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.report-an-issue form .upload-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 240px;
}

.terminate_reason-field {
  min-width: 100% !important;
  word-break: break-all !important;
}

.status_clear-btn {
  font-size: 12px;
  color: #5E81F4;
}

.days-required {
  display: block;
  color: red;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
}

@media only screen and (max-width:767px) {
  .signatory-wrapper {
    padding: 0 !important;
  }
  .signatory-modal-btns {
    padding: 15px !important;
  }
  .signatory-table, .signatory-table .ant-table-content {
    overflow: visible !important;
  }
  .signatory-table-wrapper {
    padding: 0 !important;
  }
  .edit-cleaner-form .ant-form-item .ant-form-item-label, .edit-cleaner-form .ant-form-item .ant-form-item-control {
    width: 100%;
  }
  .mobile_jobs-calendar {
    display: none;
  }
}

@media only screen and (max-width:1023px) {
  .client-view-content .account-info-wrapper {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 14px 14px 0 !important;
  }
  .client-view-content .account-info-wrapper .lead-info {
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .client-view-content .account-info-wrapper .status-icon {
    left: 25px !important;
    top: 30px !important;
   }
   .client-view-content .account-info-wrapper .cross_icon {
    right: 30px !important;
    top: 25px !important;
   }
   .client-view-content .mobile_info-cards {
    min-width: 100%;
    max-width: 100%;
   }
   .mobile-account-wrapper .account-info-wrapper {
    max-width: 100%;
   }
   .mobile-account-wrapper .quick, .client-view-content .quick {
    padding: 8px 14px !important;
   }
   .mobile-account-wrapper .account-bottom-btns, .client-view-content .account-bottom-btns {
    padding: 0 14px !important;
   }
   .tasks-to-do-wrapper .ant-tabs-tabpane .ant-row {
    margin-left: auto;
    margin-right: auto;
    margin-top: 14px;
   }
  .map_mobile {
    display: flex !important;
    visibility: hidden;
    height: 60px;
    min-height: 0 !important;
  }
  .map_mobile .mobile_buttons {
    visibility: visible;
  }
  .mobile_expand {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #000000;
    padding: 9px 18px;
    background: rgba(94,129,244,0.2);
    border-radius: 5px;
    cursor: pointer;
  }
  .mobile_details_info {
    display: flex;
    padding: 14px 0px;
    align-items: center;
    justify-content: space-between;
  }
  .mobile_details_info p {
    font-size: 12px;
    color: #63677E;
  }
  .mobile_info-cards .mobile {
    display: flex !important;
  }
}

@media only screen and (min-width:1023px) {
    .map_mobile {
      display: none;
    }
    .mobile_expand {
      display: none;
    }
    .mobile_details_info {
      display: none;
    }
    .mobile_details_info p {
      display: none;
    }
    .tasks-row {
      flex-flow: row nowrap;
    }
}

.signin_back {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 50px;
  background: #ffffff !important;
}
.signin_back .ant-btn {
  border: none !important;
  color: #5E81F4 !important;
  font-weight: 600 !important;
}
.signin_back .anticon {
  background: #e0e7f7 !important;
  padding: 10px !important;
  border-radius: 80px !important;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
  display: none;
}
`;

export default GlobalStyles;
