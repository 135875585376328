import { message } from 'antd';
import { ManagerApiService } from '../../api/managerApiService';
import { AdminApiService } from '../../api/adminApiService';

const DOCUMENT = 'MANAGER_';

const actions = {
    LOADING_TRUE: DOCUMENT + 'LOADING_TRUE',
    LOADING_FALSE: DOCUMENT + 'LOADING_FALSE',
    TOGGLE_MODAL: DOCUMENT + 'TOGGLE_MODAL',
    FETCH_ALL_MANAGERS: DOCUMENT + 'FETCH_ALL_MANAGERS',
    ADD_MANAGER: DOCUMENT + 'ADD_MANAGER',
    EDIT_MANAGER: DOCUMENT + 'EDIT_MANAGER',

    loadingTrue:() => {
        return {
            type: actions.LOADING_TRUE,
            payload: true
        };
    },

    loadingFalse:() => {
        return {
            type: actions.LOADING_FALSE,
            payload: false
        };
    },

    toggleModal:(val) => {
        return {
            type: actions.TOGGLE_MODAL,
            payload: val
        };
    },

    fetchAllManagers:(params = {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ManagerApiService.getManagerList(params)
                .then(data => {
                    dispatch({
                        type: actions.FETCH_ALL_MANAGERS,
                        payload: data
                    });
                });
            dispatch(actions.loadingFalse());
        };
    },

    addManager:(params = {}, cb = () => {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ManagerApiService.addManager(params)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        message.success('Manager added successfully!');
                        cb();
                    } else {
                        message.error(data.errorMessage || 'Oops, something went wrong');
                    }

                });
            dispatch(actions.loadingFalse());
        };
    },

    editManager:(params = {}, id, cb = () => {}) => {
        return async dispatch => {
            dispatch(actions.loadingTrue());
            await ManagerApiService.updateManager(params, id)
                .then(data => {
                    if (data.status === 'SUCCESS') {
                        message.success('Manager updated successfully!');
                        cb();
                    } else {
                        message.error(data.errorMessage || 'Oops, something went wrong');
                    }

                });
            dispatch(actions.loadingFalse());
        };
    },

    checkIfEmailAvailable: (email = '', cb = () => {}) => {

        return async () => {
            try {
                const data = await AdminApiService.checkIsNewEmail(email);
                cb(data.status === 'SUCCESS');
            } catch (e) {
                cb(false);
            }
        };

    },
};

export default actions;
