import { message } from "antd";
import { FranchiseeApiService } from "../../api/franchiseeApiService";

const DOCUMENT = "SIGNUP_";

const actions = {
  SET_CURRENT_STEP: DOCUMENT + "SET_CURRENT_STEP",
  SET_COMPANY_TYPE: DOCUMENT + "SET_COMPANY_TYPE",
  SET_AGREEMENT_STATUS: DOCUMENT + "SET_AGREEMENT_STATUS",
  SET_VAT_NUMBER_STATUS: DOCUMENT + "SET_VAT_NUMBER_STATUS",
  SET_FRANCHISE_DATA: DOCUMENT + "SET_FRANCHISE_DATA",
  SET_REDIRECT_TO_LOGIN: "SET_REDIRECT_TO_LOGIN",

  setRedirectToLogin: (val) => {
    return {
      type: actions.SET_REDIRECT_TO_LOGIN,
      payload: val,
    };
  },

  setSignUpCurrentStep: (current_step) => {
    return {
      type: actions.SET_CURRENT_STEP,
      payload: current_step,
    };
  },

  setCompanyType: (value) => {
    return {
      type: actions.SET_COMPANY_TYPE,
      payload: value,
    };
  },

  setAgreementStatus: (value) => {
    return {
      type: actions.SET_AGREEMENT_STATUS,
      payload: value,
    };
  },

  setVatNumberStatus: (value) => {
    return {
      type: actions.SET_VAT_NUMBER_STATUS,
      payload: value,
    };
  },

  setFranchiseData: (values = {}) => {
    return {
      type: actions.SET_FRANCHISE_DATA,
      payload: values,
    };
  },

  addNewFranchiseRecord: (params = {}) => {
    return async (dispatch) => {
      try {
        await FranchiseeApiService.addNewFranchise(params).then((data) => {
          if (data.status === "SUCCESS") {
            dispatch(actions.setRedirectToLogin(true));
            message.success("Account created successfully!");
          } else if (data.errorMessage === "Email already exists") {
            message.error("The username already exists!");
          } else {
            message.error("An error occurred while creating an account!");
          }
        });
      } catch (e) {
        message.error("An error occurred while creating an account!");
      }
    };
  },
};

export default actions;
